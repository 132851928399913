

<template>
        <div class="option" v-for="(option, index) in options" :key="index">
            <svg class="remove" v-if="options.length > 1" @click="removeOption(index)" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_194_476)"><path d="M7.00088 0C3.1404 0 0 3.1404 0 7.00088C0 10.8614 3.1404 14.0018 7.00088 14.0018C10.8614 14.0018 14.0018 10.8614 14.0018 7.00088C14.0018 3.1404 10.8596 0 7.00088 0ZM7.00088 13.2693C3.54379 13.2693 0.732466 10.458 0.732466 7.00088C0.732466 3.54379 3.54379 0.730696 7.00088 0.730696C10.458 0.730696 13.2693 3.54379 13.2693 6.99911C13.2693 10.4544 10.458 13.2675 7.00088 13.2675V13.2693Z" fill="#C74D4D"/><path d="M11.4234 6.63477H2.57717C2.37548 6.63477 2.21094 6.7993 2.21094 7.001C2.21094 7.20269 2.37548 7.36723 2.57717 7.36723H11.4234C11.6251 7.36723 11.7896 7.20269 11.7896 7.001C11.7896 6.7993 11.6251 6.63477 11.4234 6.63477Z" fill="#C74D4D"/></g><defs><clipPath id="clip0_194_476"><rect width="14" height="14" fill="white"/></clipPath></defs></svg>
            <svg class="add" @click="addOption" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_332_1283)"><path d="M11.9982 0.00390625C5.38207 0.00390625 0 5.38598 0 12.0021C0 18.6183 5.38207 24.0004 11.9982 24.0004C18.6144 24.0004 23.9965 18.6183 23.9965 12.0021C23.9965 5.38598 18.6114 0.00390625 11.9982 0.00390625ZM11.9982 22.7451C6.0734 22.7451 1.25531 17.927 1.25531 12.0021C1.25531 6.07731 6.0734 1.25619 11.9982 1.25619C17.9231 1.25619 22.7412 6.07731 22.7412 11.9991C22.7412 17.9209 17.9231 22.742 11.9982 22.742V22.7451Z" fill="#F9FBFC"/><path d="M19.5814 11.375H4.41875C4.07304 11.375 3.79102 11.657 3.79102 12.0027C3.79102 12.3484 4.07304 12.6305 4.41875 12.6305H19.5814C19.9271 12.6305 20.2092 12.3484 20.2092 12.0027C20.2092 11.657 19.9271 11.375 19.5814 11.375Z" fill="#F9FBFC"/><path d="M12.627 19.5853L12.627 4.42266C12.627 4.07695 12.3449 3.79492 11.9992 3.79492C11.6535 3.79492 11.3715 4.07695 11.3715 4.42266L11.3715 19.5853C11.3715 19.9311 11.6535 20.2131 11.9992 20.2131C12.3449 20.2131 12.627 19.9311 12.627 19.5853Z" fill="#F9FBFC"/></g><defs><clipPath id="clip0_332_1283"><rect width="23.9965" height="23.9965" fill="white" transform="translate(0 0.00390625)"/></clipPath></defs></svg>
            <input :title="option.value" class="select-input" type="text" v-model.trim="option.value" @input="handleInput(index)" :placeholder="this.$t('complainForm.create-edit.addElements.select.options.placehodler')">
        </div>
</template>


<script>
export default {
    emits: ['optionsChanged'],
    mounted() {
        if(this.isEdit) {
            this.options = this.optionArray
        }
    },

    data() {
        return {
            options: [
                { value: '' }
            ],
        }
    },
    props:{
        isEdit: {
            type: Boolean,
            default: false,
            required: false,
        },
        optionArray: Array
    },
    methods: {
        addOption() {
            this.options.push({ value: '' });
            this.$emit('optionsChanged', this.options);
        },
        removeOption(index) {
            this.options.splice(index, 1);
            this.$emit('optionsChanged', this.options);
        },
        handleInput(index) {
            if (index === this.options.length - 1) {
                this.$emit('optionsChanged', this.options);
            }
        },
        cleanOption(){
            this.options =  [
                { value: '' }
            ]
            this.$emit('optionsChanged', this.options);
        }
    }
}

</script>
 
<style scoped lang="scss">

input {
    margin-top: 8px;
    padding-left: 14px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    height: 42px;
    border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
    width: calc(100% - 36px);
    border-radius: 8px;
}

.option {
    position: relative;
    
    .select-input {
        width: calc(100% - 70px);
        margin-left: 35px;
    }

    .remove g path{
        fill: #C74D4D
    }

    .remove, .add {
        position: absolute;
        width: 18px;
        height: 18px;
        top: 20px;
        left: 5px;
        cursor: pointer;
        path {
            fill: #365961;
        }
    }
    .add { 
        left: auto;
        right: 5px;
    }
}

@media (min-width: 1200px) {
    .option {
        .select-input {
            height: 48px;
        }
    }
}
</style>