<template>

    <h2>{{ isEdit ? 'Editar traduccion' : 'Subir traducción' }}</h2>
    <form @submit.prevent="sendJson">
        <div class="inputs">
            <GeneralSelect :disabled="isEdit" name="language" id="language" :options="this.languageOptions"
                :error="this.language.error" :optionSelected="this.language.value"
                @optionChanged="this.language.value = $event;" :errorMessage="this.language.errorMessage"
                :placeholder="isEdit ? languagePlaceholder : this.$t('general.inputElements.language.placeholder')"
                :label="this.$t('general.inputElements.language.title')" class="body-select" />

            <div class="file">
                <input class="fileTextHiden  pointer" id="fileInput" type="file" accept="application/json"
                    @change="handleFileUpload">
                <label class="pointer" for="fileInput">
                    <img src="@/assets/icons/clip.svg">
                    <span>{{
                        this.$t('general.textareaButtons.addFile') }}</span> </label>
                <p v-if="fileName.error" class="json-name errorMessage">{{ this.fileName.errorMessage }}</p>
            </div>
        </div>
        <p class="json-name" v-if="fileName.value">{{ fileName.value }}</p>

        <div class="submit-btn-div">
            <SubmitButton :buttonText="isEdit ? 'Editar traduccion' : 'Subir traducción'"
                :buttonStyle="{ height: 'auto', paddingBlock: '10px' }" />
        </div>
    </form>



</template>


<script>
import GeneralSelect from '@/components/forms/Select.vue';
import axios from 'axios';
import SubmitButton from '../forms/SubmitButton.vue';

export default {
    name: 'TraslationsModal',
    components: { GeneralSelect, SubmitButton },
    props: {
        actualLanguageData: Object,
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            languageOptions: [],
            language: {
                value: '',
                error: false,
                errorMessage: ''
            },
            fileName: {
                value: '',
                error: false,
                errorMessage: ''
            },
            jsonFileData: {},
            languagePlaceholder: ''
        }
    },
    async created() {
        if (!this.isEdit) {
            await this.getLanguages();
        } else {
            this.languagePlaceholder = this.actualLanguageData.title
        }
    },
    emits: ['toggleModal'],
    watch: {
        'language.value'(newValue) {
            if (this.language.error && newValue !== '') {
                this.language.error = false
            }
        },
        'fileName.value'(newValue) {
            if (this.fileName.error && newValue !== '') {
                this.fileName.error = false
            }
        }
    },
    methods: {
        async getLanguages() {
            axios.get('/languages?itemsPerPage=100')
                .then((result) => {
                    this.languageOptions = result.data['hydra:member'].map((language) => {
                        return {
                            value: language['@id'],
                            label: language.name
                        }
                    });
                }).catch((err) => {
                    console.log(err);
                    //TO DO_ HACER ERRORES
                });
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                // Asegúrate de que el archivo es de tipo JSON
                if (file.type === "application/json") {
                    this.fileName.value = file.name;
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const jsonData = e.target.result;
                        // Puedes realizar más acciones con el JSON aquí
                        this.jsonFileData = jsonData
                    };
                    reader.readAsText(file);
                } else {
                    alert("Por favor, sube un archivo JSON válido.");
                    this.fileName.value = ''
                }
            }
        },
        sendJson() {
            let isError = false
            if (this.language.value === '' && !this.isEdit) {
                this.language.errorMessage = 'Este campo es obligatorio'
                this.language.error = true
                isError = true
            }
            if (this.fileName.value === '') {
                this.fileName.errorMessage = 'Debe adjuntar un archivo'
                this.fileName.error = true
                isError = true
            }

            if (isError) return

            console.log('json enviado')
            if (this.isEdit) {
                //editar
            } else {
                //CHECKEAR QUE ESE IDIOMA NO ESTA EN LA BASE DE DATOS
                //si esta en la base de datos activar modal de edición diciendo que se sobreescribiran los datos si es así que acepte
            }
        }
    }
}

</script>

<style scoped lang="scss">
.inputs {
    display: flex;
    gap: 20px;
    align-items: end;
}

.file {
    border: none;


    color: #163F49;

    font-size: 16px;
    gap: 8px;
    position: relative;


    label {
        padding: 14px;
    }

    span {
        min-width: 150px;
    }

    .fileTextHiden {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        border: 0;
    }

    .fileTextHiden:focus+label {
        border: 2px solid #0E0E0F !important;
        /* Add an orange line on focus */
        border-radius: 8px;
    }


}

.pointer {
    cursor: pointer;
}

.file>label {
    display: flex;
    align-items: center;
    background-color: #CBDEE2;
    border-radius: 8px;
    height: 43px;
    gap: 8px;
    color: #163F49;
    font-size: 16px;
    font-family: 'InterMedium';
    font-weight: 500;
}




.json-name {
    text-align: right;
    margin-block: 10px;
}

.errorMessage {
    color: #C74D4D;
    font-size: 12px;
    margin-block: 5px 0;

}

.submit-btn-div {
    margin-top: 30px;
}



@media (max-width: 450px) {
    .file {
        label {
            margin-inline: auto;
        }
    }

    .pointer {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
</style>