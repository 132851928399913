<template>
  <CaseFileCreateEditLayout :header="header" :submitButtonTitle="submitButtonTitle"   />
</template>


<script>

import CaseFileCreateEditLayout from '../general/CaseFileCreateEditLayout.vue';

export default {
    name: 'NewProceedingComponent',
    components: {
        CaseFileCreateEditLayout 
    },
    data() {
        return {
            header: {
                title: this.$t('caseFile.createEditCaseFile.title.createCaseFile'),
                breadcrumbs: [{ label: this.$t('caseFile.createEditCaseFile.breadcrumbs.first'), to: '/expedientes' }, { label: this.$t('caseFile.createEditCaseFile.breadcrumbs.second-caseFileCreate'), to: '/expedientes/create-proceeding' }],
            },
            submitButtonTitle: this.$t('caseFile.createEditCaseFile.buttons.createCaseFile'),
        }
    }
}
    
</script>


<style scoped lang="scss">


</style>