<template>

    <LayaoutTableDinamic :headerActive="false" :tableInfoMessage="tableInfoMessage" path="/case_files"
        keyPath="caseFileAll" :tableColumnsData="tableColumnsData" :mobileColSpanValue="2" :checkBoxAvailable="false"
        :optionMenuAvailable="false" :stateBoldValue="true" :paginationActive="false" :changePriority="true"
        :noLoader="true" :search="false" editPath="">

        <template #secondTitle>
            <h2 class="secondTitle">{{ this.$t('dashboard.table.title') }}</h2>
        </template>

        <template #footerTable>
            <router-link class="router-link" to="/expedientes">{{ this.$t('dashboard.table.link') }} ></router-link>
        </template>
    </LayaoutTableDinamic>


</template>

<script>
import LayaoutTableDinamic from '../users/LayaoutTableDinamic.vue';
export default {
    name: 'FileTableComponent',
    components: {
        LayaoutTableDinamic
    },
    props: {
        tableColumnsData: Object
    },
    data() {
        return {

            tableInfoMessage: {
                notCreate: this.$t('caseFile.noCreateCaseFile'),
                notSearch: this.$t('general.search.noCaseFile')
            },
            
        }
    },

}
</script>

<style scoped lang="scss">
.secondTitle {
    font-family: 'InterSemiBold', sans-serif;
    font-size: 16px;
    color: #B3BBBC;
    text-align: left;
    margin-bottom: 0;
}

.router-link {
    color: #55737A;
    font-family: 'InterMedium', sans-serif;
    font-size: 12px;
    margin-top: 18px;
    display: block;
    text-align: left;

    @media(max-width: 768px) {
        text-align: center;
    }

}
</style>