<template>
  <div class="filters">

    <select v-model="this.limit">
      <option value=25>25</option>
      <option value=50>50</option>
      <option value=100>100</option>
      <option value=200>200</option>
    </select>

    <div class="pagination-component" v-if="this.nextPage>0">
      <img
        class="vector1"
        src="../../assets/icons/vector.svg"
        alt="vector"
        @click="this.previousPageMethod()"
      />

      <div class="pagination-numbers">
        <button
          class="back"
          v-if="this.previousPage !== 0"
          @click="this.changePage(this.previousPage)"
        >
          {{ this.previousPage }}
        </button>
        <span class="line" v-if="this.previousPage !== 0">-</span>
        <button class="actual" @click="this.changePage(this.currentPage)">
          {{ this.currentPage }}
        </button>
        <span v-if="this.lastPage !== this.currentPage" class="line">-</span>
        <button
          v-if="this.lastPage !== this.currentPage"
          class="next"
          @click="this.changePage(this.nextPage)"
        >
          {{ this.nextPage }}
        </button>
      </div>
      <img
        class="vector2"
        src="../../assets/icons/vector.svg"
        alt="vector"
        @click="this.nextPageMethod()"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: "PaginationComponent",
  props: {
    currentPage: {
      type: Number,
      required: false,
      default: 0,
    },
    previousPage: {
      type: Number,
      required: false,
      default: 0,
    },
    nextPage: {
      type: Number,
      required: false,
      default: 0,
    },
    lastPage: {
      type: Number,
      required: false,
      default: 0,
    },

    usersLimit: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      limit: 0,
    }
  },
  created() {
    this.limit = this.usersLimit;
  },
  methods: {
    changePage(page) {
      this.$emit("change-page", page);
    },
    previousPageMethod() {
      if (this.currentPage > 1) {
        this.$emit("change-page", this.currentPage - 1);
      }
    },
    nextPageMethod() {
      if (this.currentPage < this.lastPage) {
        this.$emit("change-page", this.currentPage + 1);
      }
    },
  },
  watch: {
    limit: function (newLimit) {
      this.$emit("change-limit", newLimit);
    },
  },
};
</script>

<style scoped lang="scss">

.filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;


    select {
        width: 73px;
        height: 38px;
        border-radius: 8px;
        padding: 0px 0px 0px 14px;
        font-family: "Inter", sans-serif;
        border: 1px solid #CBDEE2;
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        background-image: url("../../assets/icons/angle-down.svg");
        background-repeat: no-repeat;
        background-position-x: 90%;
        background-position-y: 50%;
        background-size: 10px;
        background-color: #fff;
      }
    
}

.pagination-component {
  width: 118px;
  height: 17px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 0;
  justify-content: space-around;
  .vector1 {
    margin-left: 0;
    margin-right: 4px;
    cursor: pointer;
  }
  .pagination-numbers {
    width: 74px;
    display: flex;
    justify-content: space-around;
    button {
      border: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      color: #b3bbbc;
      font-weight: 400;
      font-size: 14px;
      line-height: 16.94px;
      margin: 0;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .actual {
      color: #163f49;
    }
    .line {
      color: #b3bbbc;
      font-family: "interRegular";
    }
  }
  .vector2 {
    transform: rotate(180deg);
    margin-left: 4px;
    cursor: pointer;
  }


  .pagination {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
