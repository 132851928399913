<template>
    <div class="si-no-leidas">
        <button :disabled="isLoading" class="no-leidas" :class="[{active: isFirstActive}, {disabled: isLoading}]" @click="selectedFirst">{{
            titleFirst }}</button>
        <button :disabled="isLoading"   class="leidas" :class="[{active: !isFirstActive}, {disabled: isLoading}]" @click="selectedSecond">{{ titleSecond
            }}</button>
    </div>

</template>

<script>
export default {
    name: 'TableTap',
    data(){
        return {isFirstActive: true}
    },
    emits: ['actionsButton'],
    props: {
        titleFirst: String,
        titleSecond: String,
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        selectedFirst(){
            this.isFirstActive = true
            this.$emit('actionsButton', true)
        },
        selectedSecond(){
            this.isFirstActive = false
            this.$emit('actionsButton', false)
        }
    },
}

</script>

<style scoped lang="scss">

.disabled{
    cursor: wait !important;
}

.si-no-leidas {
    display: flex;
    justify-content: center;

    button {
        width: 100%;
        height: 32px;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        text-align: center;
        width: 170px;
        color: #94A7AA;
        background-color: #E7F0F2;
    }

    .no-leidas {
        border-radius: 4px 0 0 0;
        margin-left: 24px;

    }

    .leidas {
        border-radius: 0 4px 0 0;
        margin-right: 36px;
    }

    .active {
        background-color: #CBDEE2;
        color: #365961;
    }
}

@media (min-width: 769px) {

    .si-no-leidas {
        justify-content: start;

        button {
            font-size: 18px;
            font-weight: 600;
            line-height: 21.78px;
            text-align: center;
            width: 239px;
            height: 48px;
        }

        .leidas {
            margin: 0;
            width: 239px;
        }

        .no-leidas {
            margin: 0;
        }

        .active {
            border-bottom: 4px solid #365961;
        }
    }
}

@media (min-width: 1200px) {
    .si-no-leidas {
        justify-content: start;

        button {
            font-size: 18px;
            font-weight: 600;
            line-height: 21.78px;
            text-align: center;
            width: 239px;
            height: 48px;
        }

        .leidas {
            margin: 0;
            width: 239px;
        }

        .no-leidas {
            margin: 0;
        }

        .active {
            border-bottom: 4px solid #365961;
        }
    }

}
</style>