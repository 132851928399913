<template>
  <div  class="input-file">
    <label for="file-input" class="file-label">
      <span :style="estilos" class="file-button">{{ text }}</span>
      <input
        id="file-input"
        type="file"
        class="file-input"
        multiple
        @change="handleFileChange"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: "InputFile",
  props: {
    accept: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "Subir archivos",
    },
    estilos: {
      type: Object
    }
  },
  data() {
    return {
      fileNames: [],
    };
  },
  emits: ["files-selected"],
  methods: {
    handleFileChange(event) {
      const files = Array.from(event.target.files);
      if (files.length) {
        this.fileNames = files.map((file) => file.name);
        this.$emit("files-selected", files);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.input-file {
  display: flex;
  align-items: center;
  width: 100%;
}

.file-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.file-button {
  width: 100%;
  font-family: "InterMedium", sans-serif;
  background-color: #365961;
  color: #f9fbfc;
  height: 32px;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-name {
  font-size: 0.9em;
  color: #555;
}

.file-input {
  display: none;
}
</style>
