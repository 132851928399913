<template>
    <div class="notification" :class="isDisapear && 'disapear'">
        <div class="check">
            <Checkbox v-model="value" :checkStyle="{ width: '20px', height: '20px' }" />
        </div>

        <div class="date-box">
            <p class="date">{{ date }}</p>
            <p class="time">{{ time }}</p>
        </div>

        <div class="body">

            <p>{{ text }}</p>
            <div class="buttons" v-if="acceptance">
                <button v-if="isAccepted === null || isAccepted === false"
                    @click="updateAceptOrDeny(this.id, true, type)" class="aceptar">{{
                        this.$t('notification.acceptButton') }}</button>
                <button v-if="isAccepted === true" class="aceptada">{{ this.$t('notification.acceptedButton')
                    }}</button>
                <button v-if="isAccepted === null || isAccepted === true"
                    @click="updateAceptOrDeny(this.id, false, type)" class="denegar">{{
                        this.$t('notification.denyButton') }}</button>
                <button v-if="isAccepted === false" class="denegada">{{ this.$t('notification.deniedButton') }}</button>
            </div>
        </div>



    </div>
</template>


<script>
import Checkbox from '../formulario_denuncia/Checkbox.vue';
import axios from 'axios';
export default {
    name: 'NotificationBoxComponent',
    props: {
        acceptance: Boolean,
        date: String,
        time: String,
        text: String,
        acceptedbtn: [Boolean, null],
        id: String,
        isRead: Boolean,
        type: String
    },
    components: { Checkbox },
    data() {
        return {
            isAccepted: this.acceptedbtn,
            value: false,
            isDisapear: false,
        };
    },
    watch: {
        acceptedbtn(newValue) {
            this.isAccepted = newValue
        },
        value(newValue) {
            this.$emit('updateNotificationSelected', { isRead: this.isRead, notificationId: this.id, isSelected: newValue })
        }
    },
    emits: ['scrollToErrorMessage', 'setNewNotifications', 'updateNotificationSelected', 'filterSelectedNotification', 'checkIsSelectNotification'],
    methods: {

        async updateAceptOrDeny(id, newValue, type) {
            if (this.isAccepted !== null) return
            try {
                if (type === 'InstructorCaseFileAssigned') {
                    await this.instructorCaseFileIsAccepted(id, newValue);
                }
                await axios.patch(`/notifications/${id}`, { accepted: newValue, readed: true })
                this.isAccepted = newValue
                this.disapear = true
                if (!this.isRead) {
                    //porque se pasa de un lugar a otro, sino no hacemos filte porque queremos que siga dentro del selected
                    this.$emit('filterSelectedNotification', this.id)
                    this.$emit('checkIsSelectNotification')

                }

                setTimeout(() => {
                    if (!this.isRead) {
                        //como se pasa un elemento de una tabla a otra si ponemos el elemento que se pasa
                        this.$emit('setNewNotifications', '1')
                    }
                }, 200)
            }
            catch (error) {
                console.log(error)
                this.$emit('scrollToErrorMessage')
                this.disapear = false
            }
        },
        async instructorCaseFileIsAccepted(id, newValue) {
            if (newValue) {
                // Se acepta el casefile como instructor
                await axios.post(`user_instructors/accept-case?notificationId=${id}`);
            } else {
                // Se rechaza
                await axios.post(`/user_instructors/reject-case?notificationId=${id}`);
            }
        }
    }

}
</script>

<style scoped lang="scss">
* {
    font-family: "InterRegular";
}

.disapear {
    opacity: 0;
}

.notification {
    display: flex;
    margin: 12px 16px 12px 16px;
    position: relative;
    border-bottom: 1px solid var(--Verde-Menta-0, #E7F0F2);
    opacity: 1;
    transition: opacity .2s;

    .check {
        margin-top: 3px;
    }

    .date-box {
        margin: 4px 10px 12px 26px;

        .date,
        .time {
            font-size: 10px;
            font-weight: 500;
            line-height: 13px;
            text-align: left;
            color: #A2ABAD;
            width: 47px;
            height: 12px;
        }
    }

    .body {
        margin-bottom: 12px;
        width: 100%;
        ;

        p {
            height: auto;
            font-size: 12px;
            font-weight: 400;
            line-height: 15.6px;
            text-align: left;
            color: #0E0E0F;
        }

        .buttons {
            display: flex;
            justify-content: space-around;
            margin-top: 12px;

            button {
                width: 100%;
                height: 32px;
                border-radius: 4px;
                border: 1px solid var(--Verde-Ultra-Oscuro-Dedavara, #0D2930);
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                text-align: center;
                color: #163F49;
                background: none;
                cursor: pointer;
            }

            .aceptar,
            .aceptada {
                margin-right: 12px;
            }

            .aceptada {
                background-color: #163F49;
                color: #F9FBFC;
                border: none;
            }

            .denegada {
                background-color: #55737A;
                color: #F9FBFC;
                border: none;
            }
        }
    }
}

@media (min-width: 600px) {
    .notification {
        min-height: 72px;
        border-radius: 4px;

        img {
            top: 8px;
        }

        .date-box {
            display: flex;
            width: 119px;
            margin-top: 2px;
            margin-right: 32px;

            .date,
            .time {
                width: auto;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                text-align: left;

            }

            .date::after {
                content: '-';
                color: #A2ABAD;
                margin: 0 2px 0 2px;
            }
        }

        .body {
            display: flex;
            width: 100%;

            p {
                margin-right: 12px;
                font-size: 16px;
                font-weight: 400;
                line-height: 20.8px;
                text-align: left;

                strong {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20.8px;
                    text-align: left;
                }
            }

            .buttons {
                margin-left: auto;
                width: auto;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                text-align: center;
                margin-top: 0;

                button {
                    width: 99px;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.36px;
                    text-align: center;
                }
            }
        }

    }
}

@media (max-width: 350px) {
    .notification {
        margin-left: 0;

        .date-box {
            margin-left: 10px;
        }
    }

}
</style>