<template>
    <!-- TO DO: Añadir mensaje si es error blank-->
    <div  :class="['textarea-container', { 'error': isBlank }]" >
        <textarea :style="estilosTextArea" :class="notFile && 'not-file'" :value="modelValue" @input="updateValue" name="nota-textArea" id="nota-textArea"
            :placeholder="placeholderText"></textarea>
        <div class="files" v-if="files.length > 0 && !notFile">
            <FileComponent v-for="file in files" :key="file.name" :file="file" @eliminar-archivo="eliminarArcivo" />
        </div>
    </div>
    <p v-if="isBlank" class="blank-message">{{ this.$t('general.infoMessage.fillElement') }}</p>

</template>

<script>
import FileComponent from '../complaint-process/FileComponent.vue';
export default {
    name: 'TextAreaWithFiles',
    components: {FileComponent},
    inheritAttrs: false,
  
    props: {
        files: {
            type: Array,
            required: true
        },
        eliminarArcivo: {
            type: Function,
            required: true
        },
        modelValue: {
            type: String
        },
        isBlank: {
            type: Boolean,
        },
        placeholderText: {
            type: String
        },
        notFile: {
            type: Boolean,
            default: false
        },
        estilosTextArea: {
            type: Object 
        }
       
        
    },
    methods: {
    updateValue(event){
      this.$emit('update:modelValue', event.target.value)
    }
    }
}


</script>

<style scoped lang="scss">

.blank-message{
    color: #C74D4D;
    text-align: left;
    margin-top: 4px;
}

.textarea-container {
    height: 373px;
    width: 100%;
    border: 1.25px solid #B3BBBC;
    border-radius: 8px;
    padding: 14px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.textarea-container:focus-within {
    border: 2px solid #0E0E0F;
}

textarea {
    width: 100%;
    padding: 4px 15px;
    color: #0E0E0F;
    font-size: 16px;
    font-family: 'InterRegular';
    line-height: 20.8px;
    border: none;
    border-radius: 8px;
    resize: none;
    outline: none;
    height: 80%;
}

.not-file{
    height: 95%;
}

.files {
    position: relative;
    width: calc(100% - 24px);
    margin: 0 10px 0 16px;
    border-top: none;
    border-radius: 0 0 7.03px 7.03px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    overflow-y: scroll;
    max-height: 75px;

}

.error {
    border-color: #C74D4D !important;
}

@media (max-width: 580px) {

    textarea {
    font-size: 14px;
    line-height: 18.2px;
}

}
</style>