<template>

    <CreateAndEditLayaout :name="name" :path="path" :isEdit="true" :breadcrumbs="breadcrumbs" :isState="true" />

</template>


<script>
import CreateAndEditLayaout from '@/components/general/CreateAndEditLayaout.vue'
import { useRoute } from 'vue-router';
import { mapMutations } from 'vuex';

export default {
    name: 'EditState',
    components: { CreateAndEditLayaout },
    data() {
        return {
            name: {
                button: this.$t('caseFile.state.create-edit.buttonEdit'),
                title: this.$t('caseFile.state.create-edit.titleEdit')
            },
            path: '/states',
            breadcrumbs: [
                { label: this.$t('caseFile.state.create-edit.breadcrumbs.first'), to: '/expedientes' },
                { label:  this.$t('caseFile.state.create-edit.breadcrumbs.second'), to: '/expedientes/estados' },
                { label: this.$t('caseFile.state.create-edit.breadcrumbs.third-edit'), to: `` }],
        }
    },
    methods: {
        ...mapMutations(['toggleUserCompanyDisable']),
    },
    mounted() {
        this.toggleUserCompanyDisable()
        const router = useRoute();
        const { id } = router.params;
        this.identifier = id
        this.breadcrumbs[2].to = `/expedientes/estados/editar-estados/${id}`
    },
    beforeUnmount() {
        this.toggleUserCompanyDisable()
    },

}
</script>
