<template>

    <div class="template">

        <div class="title">
            <div class="h-img">
                <generalTitle title="Modelos documentales" :breadcrumbs="breadcrumbs" :root="true" />

            </div>
            <div class="new-model">
                <router-link class="new-model-button" to="/doc-models/new-docmodel">Crear nuevo Modelo</router-link>
            </div>
        </div>

        <div class="docmodels">
            <DocBox v-for="doc in docs" :key="doc.id" :title="doc.title" :description="doc.description" class="doc" />
        </div>

    </div>

</template>


<script>
import DocBox from '@/components/doc-models/DocBox.vue'
import generalTitle from '../general/generalTitle.vue';
export default {
    name: 'DocModelComponent',
    components: {
        DocBox,
        generalTitle
    },
    data() {
        return {
            breadcrumbs: [{ label: 'Modelos documentales', to: '/doc-models' }],
            docs: [
                {
                    id: 1,
                    title: "Modelo de protección de datos",
                    description: "Este documento detalla la recopilación, almacenamiento y procesamiento de datos personales, así como las medidas de seguridad, la retención de datos y los derechos de los individuos. Incluye políticas de consentimiento, notificación de brechas de seguridad y la designación de un oficial de protección de datos."
                },
                {
                    id: 2,
                    title: "Modelo de protección de datos",
                    description: "Este documento detalla la recopilación, almacenamiento y procesamiento de datos personales, así como las medidas de seguridad, la retención de datos y los derechos de los individuos. Incluye políticas de consentimiento, notificación de brechas de seguridad y la designación de un oficial de protección de datos."
                },
                {
                    id: 3,
                    title: "Modelo de protección de datos",
                    description: "Este documento detalla la recopilación, almacenamiento y procesamiento de datos personales, así como las medidas de seguridad, la retención de datos y los derechos de los individuos. Incluye políticas de consentimiento, notificación de brechas de seguridad y la designación de un oficial de protección de datos."
                },
                {
                    id: 4,
                    title: "Modelo de protección de datos",
                    description: "Este documento detalla la recopilación, almacenamiento y procesamiento de datos personales, así como las medidas de seguridad, la retención de datos y los derechos de los individuos. Incluye políticas de consentimiento, notificación de brechas de seguridad y la designación de un oficial de protección de datos."
                },
                {
                    id: 5,
                    title: "Modelo de protección de datos",
                    description: "Este documento detalla la recopilación, almacenamiento y procesamiento de datos personales, así como las medidas de seguridad, la retención de datos y los derechos de los individuos. Incluye políticas de consentimiento, notificación de brechas de seguridad y la designación de un oficial de protección de datos."
                },
                {
                    id: 6,
                    title: "Modelo de protección de datos",
                    description: "Este documento detalla la recopilación, almacenamiento y procesamiento de datos personales, así como las medidas de seguridad, la retención de datos y los derechos de los individuos. Incluye políticas de consentimiento, notificación de brechas de seguridad y la designación de un oficial de protección de datos."
                }
            ]
        }
    },
    methods: {
        lastUrl() {
            window.history.back();
        }
    },

}
</script>

<style scoped lang="scss">
.template {

    .title {

        .h-img {
            display: flex;


            h3 {
                font-family: "Inter", sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.52px;
                text-align: left;
                display: none;
                margin: 0;
                color: #A2ABAD;
            }

            img {
                cursor: pointer;
                margin: 0 26px;
            }

            h1 {
                margin: 0;
                font-size: 20px;
                font-weight: 600;
                line-height: 24.2px;
                text-align: left;
                color: #163F49;
                font-family: "Inter", sans-serif;
            }
        }

        .new-model {
            margin-top: 32px;
            width: 100%;
            margin-left: 0;
            height: 32px;
            border-radius: 4px;
            background-color: #163F49;
            font-family: "Inter", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            text-align: center;
            color: #F9FBFC;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            .new-model-button {
                width: 100%;
            }
        }
    }

    .docmodels {
        margin: 32px 0px;

        .doc {
            margin-bottom: 12px;
        }
    }
}



@media (min-width: 1200px) {
    .template {
        .title {
            display: flex;

            .h-img {
                flex-direction: column;

                h3 {
                    display: block;
                }

                img {
                    display: none;
                }

                h1 {
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 36.31px;
                }
            }

            .new-model {
                max-width: 238px;
                margin-left: auto;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                margin-top: auto;
            }


        }

        .docmodels {
            margin: 60px 0 24px 0;

            .doc {
                margin-bottom: 16px;
            }
        }
    }
}
</style>