<template>

    <CreateAndEditLayaout :name="name" :path="path" :breadcrumbs="breadcrumbs" :isState="true"/>

</template>


<script>
import CreateAndEditLayaout from '@/components/general/CreateAndEditLayaout.vue'

export default {
    name: 'NewState',
    components: { CreateAndEditLayaout },
    data() {
        return {
            name: {
                button: this.$t('caseFile.state.create-edit.buttonCreate'),
                title: this.$t('caseFile.state.create-edit.titleCreate')
            },
            path: '/states',
            breadcrumbs: [
                { label: this.$t('caseFile.state.create-edit.breadcrumbs.first'), to: '/expedientes' },
                { label: this.$t('caseFile.state.create-edit.breadcrumbs.second'), to: '/expedientes/estados' },
                { label: this.$t('caseFile.state.create-edit.breadcrumbs.third-create'), to: '/expedientes/estados/crear-estado' }],
        }
    }

}
</script>
