<template>

    <LayaoutTableDinamic :tableInfoMessage="tableInfoMessage" title="Logs" :breadcrumbs="this.breadcrumbsList"
        path="/logs" :tableColumnsData="tableColumnsData" :notCreateButton="true" :checkBoxAvailable="false"
        :optionMenuAvailable="false" :date="true" :changeRoleName="true" :changeDate="true" editPath="" />

</template>


<script>

import LayaoutTableDinamic from '../users/LayaoutTableDinamic.vue';

export default {
    name: 'LogsComponent',
    components: {
        LayaoutTableDinamic
    },
    data() {
        return {
            breadcrumbsList: [
                { label: this.$t('Logs.breadcrumbs'), to: '/logs' },
            ],
            tableInfoMessage: {
                notCreate: this.$t('Logs.noLogs'),
                notSearch: this.$t('general.search.noLogs')
            },
            tableColumnsData: {
                desk: {
                    columnTitle: [
                        { title: this.$t('general.tableColumTitles.dateAndHour'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.dateAndHour'), isUp: false },
                        { title: this.$t('general.tableColumTitles.action'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.action'), isUp: false },
                        { title: this.$t('general.tableColumTitles.user'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.user'), isUp: false },
                        { title: this.$t('general.tableColumTitles.rol'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.rol'), isUp: false }],
                    //Tiene que hacer match con los datos
                    mainRowName: ['createdAt', 'action', 'userName', 'userRole'],
                    titleDetailsRowName: [],
                    //Tiene que hacer match con los datos
                    detailsRowName: [],
                },

                mobile: {
                    columnTitle: [{ title: this.$t('general.tableColumTitles.dateAndHour'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.dateAndHour'), isUp: false }, { title: this.$t('general.tableColumTitles.action'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.action'), isUp: false }],
                    //Tiene que hacer match con los datos
                    mainRowName: ['createdAt', 'action',],
                    titleDetailsRowName: [this.$t('general.tableColumTitles.user'), this.$t('general.tableColumTitles.rol')],
                    //Tiene que hacer match con los datos
                    detailsRowName: ['userName', 'userRole'],
                }

            }
        }
    },

}
</script>
