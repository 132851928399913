<template>
    <div class="dashboard">

        <h1>{{ this.$t('caseFile.showCaseFile.title.caseFile') }}</h1>

        <!--Data Boxes-->
        <div class="data-boxes">

            <DataBox title="Expedientes en curso" sign="-" :periodNumber="12" period="Esta semana" :total="18"
                status="inProgress" />

            <DataBox title="Expedientes Cerrados" sign="+" :periodNumber="32" period="Esta semana" :total="120"
                status="Closed" />
        </div>

        <div class="wrapper">
            <!--File table-->
            <div class="table-data">
                <div class="file-table">
                    <h2 class="left">{{ this.$t('dashboard.informant.tableTitle') }}</h2>
                    <div v-if="isLoading" class="loader">
                        <LoaderComponent />
                    </div>
                    <InfoMessage v-if="!isLoading && this.isGetCaseFileError" :styleDiv="{ marginBottom: '0' }"
                        :isActive="true" color="#C74D4D" :text="this.$t('general.infoMessage.GetError')" icon="error" />
                    <FileTable v-if="!isLoading && !this.isGetCaseFileError" :isInformant="true"
                        :columnConfig="tableColumns" :caseFiles="caseFiles" :search="false" :reviewButton="true"
                        :tableColumnsMovile="tableColumnsMovile" :isMoreThanOneDifferentCompany="true"
                        :noElementsMessage="this.$t('caseFile.noCreateCaseFile')" :searchValue="''" />
                </div>

            </div>
        </div>
    </div>
</template>




<script>
import DataBox from '@/components/dashboard/DataBox.vue';
import FileTable from '@/components/dashboard/FileTableProgress.vue';
import { nanoid } from 'nanoid';
import axios from 'axios';
import { translationStateDefaultMixing, translationCategoryDefaultMixing } from "@/components/translationDefaultMixing.js"
import InfoMessage from '../general/InfoMessage.vue';
import LoaderComponent from '../general/LoaderComponent.vue';



export default {
    name: 'InformantDashboardComponent',
    components: {
        DataBox,
        FileTable,
        InfoMessage,
        LoaderComponent
    },

    watch: {
        $route(to) {
            this.currentRoute = to.name;
        }
    },
    mixins: [translationStateDefaultMixing, translationCategoryDefaultMixing],
    created() {
        this.isLoading = true
        this.getInformantCaseFile()
    },

    data() {
        return {
            currentRoute: '',
            tableColumns: [
                { title: this.$t('general.tableColumTitles.company'), isUp: false, elementKey: 'company' },
                { title: this.$t('general.tableColumTitles.code'), isUp: false, elementKey: 'caseCode' },
                { title: this.$t('general.tableColumTitles.subject'), isUp: false, elementKey: 'subject' },
                { title: this.$t('general.tableColumTitles.category'), isUp: false, elementKey: 'category' },
                { title: this.$t('general.tableColumTitles.dateCreated'), isUp: false, elementKey: 'createdAt' },
                { title: this.$t('general.tableColumTitles.subject'), isUp: false, elementKey: 'subject' },

            ],

            tableColumnsMovile: [
                //cada array representa una fila dentro del grid
                [{ title: this.$t('general.tableColumTitles.company'), element: 'company', id: nanoid() }, { title: this.$t('general.tableColumTitles.code'), element: 'caseCode', id: nanoid() }],
                [{ title: this.$t('general.tableColumTitles.date'), element: 'createdAt', id: nanoid() }, { title: this.$t('general.tableColumTitles.state'), element: 'stateName', id: nanoid() }],
                [{ class: 'category', title: this.$t('general.tableColumTitles.category'), element: 'category', id: nanoid() }],
            ],
            caseFiles: [],
            isLoading: false,
            isGetCaseFileError: false
        }
    },
    methods: {
        async getInformantCaseFile() {
            try {
                const res = await axios.get('/case_files?itemsPerPage=200&order[id]=DESC')
                this.caseFiles = res.data['hydra:member'].map(element => {
                    return { ...element, createdAt: this.formatedDate(element.createdAt), company: element.company.fullName, category: this.changeTraslationDefaultCategories([element.category], false)[0].name, stateName: this.changeTraslationDefaultState([element.state])[0].name, totalNumber: element.company.states.length }
                })
            }
            catch (error) {
                console.log(error)
                this.isGetCaseFileError = true
            }
            finally {
                this.isLoading = false
            }
        },
        formatedDate(fechaISO) {
            // Crear un objeto Date a partir de la cadena de fecha ISO 8601
            const fecha = new Date(fechaISO);

            // Extraer componentes de la fecha en la zona horaria local
            const dia = fecha.getDate().toString().padStart(2, '0'); // Día
            const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Mes (se suma 1 porque los meses en JavaScript son 0-indexados)
            const año = fecha.getFullYear().toString().slice(-2); // Últimos dos dígitos del año

            // Formatear la fecha y la hora en el formato deseado
            const fechaFormateada = `${dia}/${mes}/${año}`;

            return fechaFormateada;

        }
    },

}
</script>



<style scoped lang="scss">
.left {
    text-align: left;
    margin-top: 24px !important;
    margin-bottom: 14px !important;
}

.loader {
    justify-content: center;
}

.dashboard {
    width: 100%;

    padding: 0 44px 0 78px;



    .breadcrumb {
        margin-top: 36px;
    }

    h1 {
        text-align: left;
        margin: 25px 0 24px 0;
        font-size: 30px;
    }

    .data-boxes {
        width: 100%;
        display: inline-flex;
        margin-bottom: 24px;

        .data-box {
            width: 342px;
            margin-right: 48px;
        }

        .data-box:last-child {
            margin-right: 0;
        }

    }

    .table-data {
        width: 100%;

        display: inline-flex;




        .file-table {
            width: 100%;
            margin-right: 32px;
            border-radius: 10px;



            h2 {
                text-align: left;
                font-size: 16px;
                font-family: 'interSemiBold', sans-serif;
                color: #B3BBBC;
            }



        }


    }


    .router-link {
        color: #55737A;
        font-family: 'InterMedium', sans-serif;
        font-size: 12px;
        margin-top: 18px;
        display: block;
        text-align: left;

    }
}


/*Media queries*/
@media(max-width: 1720px) {
    .dashboard {
        padding: 0 44px 0 44px;

        .data-boxes .data-box {
            width: 33.33%;
        }

        .table-data {
            flex-wrap: wrap;
            max-height: calc(100% - 150px);
            overflow-y: auto;
            /* Agrega desplazamiento vertical solo si es necesario */


            .file-table {
                width: 100%;
                margin: 0;

                table {
                    max-height: 250px;
                    width: 100%;
                }

            }

            .widgets {
                width: 100%;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;
                margin-top: 25px;

                .widget {
                    width: 33.33%;
                    margin: 0;
                    margin-right: 32px;
                }


                .widget:last-child {
                    margin-right: 0;
                }
            }

        }

        .charts .chart {
            width: 50%;

        }

        .charts .chart:last-child {
            margin-right: 0;
        }
    }

}



@media(max-width: 1400px) {
    .dashboard {
        padding: 0 0 0 25px;
    }
}


@media(max-width: 1200px) {
    .dashboard {
        padding: 0;
    }
}



@media(max-width: 768px) {
    .dashboard {


        .breadcrumb {
            display: none;
        }

        h1 {
            padding: 0 24px;
            font-size: 24px;
            margin: 0 0 43px 0;
        }

        .data-boxes {
            flex-wrap: wrap;
            padding: 0 24px;
            margin-bottom: 0;

            .data-box {
                width: 47%;
                margin-right: 12px;
                margin-bottom: 24px;


            }

            .widget.assigned {
                padding: 0;
            }

            .widget {
                background: #FFFFFF;
                width: 47%;
                border-radius: 8px;
                flex-direction: inline-flex;
                align-items: center;
                flex-wrap: wrap;
                padding: 16px;

                h2 {
                    font-size: 14px;
                    text-align: left;
                    font-family: 'interSemiBold', sans-serif;
                    color: #B3BBBC;

                    width: 100%;

                }

                p {
                    font-size: 14px;
                    display: inline;
                    font-family: 'interSemiBold', sans-serif;
                    text-align: left;
                }

                span.number {
                    display: inline;
                    font-size: 24px;
                    font-family: 'interSemiBold', sans-serif;
                    margin-left: 5px;


                }

            }

            .widget {
                display: flex;
                width: 47%;
                margin-right: 12px;
                margin-bottom: 12px;

                .data-box {
                    width: 100%;
                }
            }

        }

        .wrapper {
            display: flex;
            flex-direction: column-reverse;
        }

        .table-data {
            padding: 0 24px;


            .widgets {
                display: none;
            }

            .table {
                min-height: 600px;
            }
        }


        .charts {
            width: 100%;
            padding: 0 16px;
            margin-top: 24px;
            flex-wrap: wrap;

            .chart {
                max-width: 100%;
                width: 100%;
                margin-right: 0;
            }

            .chart:first-child {
                margin-bottom: 13px;
            }

        }

        .router-link {
            text-align: center;
        }
    }
}


@media(max-width:576px) {

    .dashboard {
        .data-boxes {
            justify-content: space-between;

            .data-box,
            .widget {
                width: 45%;
            }
        }


    }
}

@media(max-width:400px) {

    .dashboard {
        .data-boxes {


            .data-box,
            .widget {
                width: 100%;
                margin-right: 0;
            }
        }
    }
}
</style>