<template>
    <div class="widgett notes">
        <div ref="widgetNotes" class="second-container">
            <div ref="notesScroll" class="notes-flex">
                <div class="note-title">
                    <h2>{{ this.$t('caseFile.detailsCaseFile.notes.title') }}</h2>
                    <button @click="this.$emit('toggleModalActive', 'isModalNotes')"><img
                            src="@/assets/icons/add-border.svg" /></button>
                </div>
                <div v-for="(note, index) in notesData" :key="note.id" class="note">
                    <h3 :title="note.name">{{ note.name }}</h3>
                    <div @click="(e) => calculateDistance(e)">
                        <Dropdown
                            @toggleModalEdit="this.$emit('toggleModalEdit', { specifyModal: 'isModalNotes', path: '/notes/', id: note.id, key: '' })"
                            @toggleModalVisualice="this.$emit('toggleModalEdit', { specifyModal: 'isModalNoteVisualice', path: '/notes/', id: note.id, key: 'visualice' })"
                            @toggleModalDelete="this.toggleModalDelete({ id: note.id, name: note.name })"
                            @activeDropdownOpen="this.$emit('activeIsNoteDropdownOpen')" :isEditPosition="true"
                            :estilos="{ top: `${noteDropdownPositionY}px`, left: `${noteDropdownPositionX}px`, width: 'min-content' }"
                            :ref="'dropdownNote' + index" :links="noteDropdown" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <DeleteModalTable v-if="isDeleteActive" option="simple" :lastSingularId="deleteNoteIndetify"
        @toggleModal="toggleModalDelete" @trigger-fetch="this.$emit('getNotes', identify)" path="/notes"
        :deleteModalText="deleteModalText" :lastSingularName="selectedFullNameForDelete" />

</template>


<script>
import Dropdown from '../general/Dropdown.vue';
import DeleteModalTable from '../categories/DeleteModalTable.vue';

export default {
    name: "NotesListWidget",
    data() {
        return {
            selectedFullNameForDelete: '',
            deleteNoteIndetify: '',
            isDeleteActive: false,
            noteDropdownPositionX: 0,
            noteDropdownPositionY: 0,
            noteDropdown: [
                { text: this.$t('general.dropdownOptions.visualice'), data: { action: 'toggleModalVisualice' } },
                { text: this.$t('general.dropdownOptions.edit'), data: { action: 'toggleModalEdit' } },
                { text: this.$t('general.dropdownOptions.delete'), data: { action: 'toggleModalDelete' }, estilos: { color: '#C74D4D' } },
            ],
            deleteModalText: {
                title: this.$t('caseFile.detailsCaseFile.notes.deleteModal.title'),
                textSingular: this.$t('caseFile.detailsCaseFile.notes.deleteModal.textSingular'),
                errorSingular: this.$t('caseFile.detailsCaseFile.notes.deleteModal.errorSingular'),
            },
        }
    },
    components: { Dropdown, DeleteModalTable },
    emits: ['toggleModalActive', 'toggleModalEdit', 'activeIsNoteDropdownOpen', 'isNoreDropeonOpenToFalse', 'getNotes'],
    props: {
        notesData: Array,
        isNoteDropdownOpen: Boolean,
        isInformant: Boolean,
        isModalActive: Boolean,
        identify: String
    },
    mounted() {
        this.$refs.notesScroll.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        this.$refs.notesScroll.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        toggleModalDelete(data) {
            this.isDeleteActive = !this.isDeleteActive
            this.deleteNoteIndetify = data?.id
            this.selectedFullNameForDelete = data?.name
        },
        calculateDistance(e) {

            if (this.isModalActive || e.target.closest('.dropdown-menu') !== null) return

            //cogemos la posicion x e y del padre de las notas y del boton clicado
            const widgetNotes = this.$refs.widgetNotes;
            const dropdown = e.target

            const widgetRect = widgetNotes.getBoundingClientRect();
            const dropdownRect = dropdown.getBoundingClientRect();

            // buscamos la diferencia de ambos valores para saber cuanto top y left darle al elemento position absolute
            this.noteDropdownPositionX = dropdownRect.left - widgetRect.left + 20
            this.noteDropdownPositionY = dropdownRect.top - widgetRect.top + 20

            // si es movil, necesitamos quitarle px en left porque se sale de la pantalla al ser una sola columna
            if (window.innerWidth <= 768) {
                this.noteDropdownPositionX -= 50
            }

        },
        handleScroll() {
            //ocultar los dropdwon de notas cuando se haga scroll ya que debido al overflow
            //Para que solo se ejecute quitar los dropdown cuando este abierto uno y se haga scroll 
            if (!this.isNoteDropdownOpen) return
            this.notesData.forEach((_, index) => {
                const refName = 'dropdownNote' + index
                this.$refs[refName][0].closeDropdown()
            });
            this.$emit('isNoreDropeonOpenToFalse')
        },
    },
}


</script>



<style scoped lang="scss">
.widgett {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 24px;

    h2 {
        margin-top: 0;
    }
}



.second-container {
    width: 100%;
}

.notes-flex {
    height: 100%;
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 12px;
    display: flex;
    overflow-x: auto;
    gap: 9px 13px;
}

.widgett.notes {
    width: 100%;
    padding: 16px 18px 12px 24px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    position: relative;

    .note-title {
        display: flex;
        align-items: center;


        .show-deleted {
            padding-left: 6px;
        }

        .active-icon {
            svg path {
                fill: #C74D4D;
            }
        }

        button {
            display: flex;
            align-items: center;
        }
    }

    h2 {
        font-size: 16px;
        color: #0e0e0f;
        margin-right: 12px;
        font-family: "InterSemiBold", sans-serif;
        margin: 0 12px 0 0;
    }

    button {
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;

        img {
            width: 28px;
        }
    }

    .notes-flex {
        margin-left: 0;
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 12px;
        display: flex;
        overflow-x: auto;
        gap: 9px 13px;
    }

    .note {

        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 32px;
        min-width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #e0ebee;
        padding: 4px 8px;
        margin-right: 0px;
        border-radius: 2px;

        h3 {
            font-size: 14px;
            color: #163f49;
            font-family: "InterMedium", sans-serif;
            margin: 0;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

@media (max-width: 768px) {
    .widgett.notes {
        display: block;

        .notes-flex {
            display: grid;
            align-items: center;
            grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
            margin-left: 0;
            overflow-x: hidden;
            flex-wrap: nowrap;
            padding-bottom: 12px;

            overflow-y: auto;
            max-height: 150px;
            padding-right: 10px;
        }
    }
}
</style>