<template>
  <router-link v-if="!isButton"
    class="button"
    :to="href"
    :class="{ 'invert-color': invertColor }"
    :style="{ fontSize: fontSize, width: width, paddingInline: paddingInline}"
  >
    {{ text }}
  </router-link>
  <button @click="this.$emit('action')" v-else  class="button"  :class="{ 'invert-color': invertColor }"
  :style="{ fontSize: fontSize, width: width, paddingInline: paddingInline}">{{ text }}</button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    text: {
      type: String,
      required: true,
    },

    href: {
      type: String,
    },

    isButton: {
      type: Boolean,
      default: false
    },

    invertColor: {
      type: Boolean,
      default: false,
    },

    fontSize: {
      type: String,  
      default: "16px", 
    },
    paddingInline: {
      type: String
    },

    width: {
      type: String,
      default: "238px",
    },
  
  },
};
</script>

<style scoped lang="scss">
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 238px;
  background: #163f49;
  border-radius: 4px;
  min-height: 32px;
  font-family: "InterMedium", sans-serif;
  cursor: pointer;
  color: #F9FBFC;
  border: none
}

.button:hover {
  background: transparent;
  color: #163f49;
  border: 1px solid #163f49;
}

.button.invert-color {
  background: transparent;
  color: #163f49;
  border: 1px solid #163f49;
}

.button.invert-color:hover {
  background: #163f49;
  color: white;
  border: none;
}


</style>
