<template>
    <!-- Rounded switch -->
    <label  @click="!this.isDisable && this.$emit('openModal2FA')" class="switch">

        <button :class="isEnable ? 'checked': null"></button>
        <span class="slider round" :class="isEnable ? 'checked-slider' : null" ></span>
    </label>

</template>

<script>
export default {
    name: 'ToggleSwitch ',
    data() {
        return {
            isChecked: this.$store.getters.getUser.mfaEnabled
        };
    },
    computed: {
        isEnable() {
            return this.$store.getters.getUser.mfaEnabled
        }
    },
    emits: ['openModal2FA'],
    props: {
        value: Boolean,
        isDisable: Boolean
    },
    watch: {
        isEnable(newValue){
            this.isChecked = newValue
        }
    },

}
</script>


<style scoped lang="scss">
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch:focus{
    box-shadow: 0 0 1px #163F49;
}

/* Hide default HTML checkbox */
.switch button {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.checked-slider{
    background-color: #163F49;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}


button:focus-visible+.slider {
    box-shadow: 0 0 0 2px #E7885F;

}

.checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


/*Media queries*/

@media (max-width: 768px) {
    .switch {
        width: 50px;
        height: 28px;
    }

    .slider {
        width: 48px;
        height: 24px;
    }

    .slider:before {
        width: 15px;
        height: 15px;
    }
}
</style>