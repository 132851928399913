<template>
    <div class="submit-button">
        <button class="confirmRegisterForm-btn-global" type="submit" :style="buttonStyle"  v-if="!this.loading">{{ buttonText }}</button>
        <button class="loading confirmRegisterForm-btn-global" type="button" :style="buttonStyle" v-else ><VueSpinner size="30" color="#FFFFFF" ></VueSpinner></button>
    </div>
</template>
    
<script>
import { VueSpinner } from 'vue3-spinners';
export default {
    name: 'GeneralInput',
    components: {
        VueSpinner
    },
    props: {
        buttonText: String,
        inputType: String,
        loading:{
            type: Boolean,
            default: false
        },
        buttonStyle: Object
    },
}
</script >
    
   
<style scoped lang="scss">
button {
    width: 100%;
    height: 64px;
    background: #163F49;
    border-radius: 8px;
    border: none;
    color: #D2E3E6;
    font-size: 24px;
    cursor: pointer;
    margin-top: 18px;
}

.loading{
    cursor: wait !important;
}

@media(max-width:500px) {

    button {
        height: 48px;
        font-size: 18px;
        max-width: 280px;
    }

    .submit-button {
        text-align: center;
    }

}
</style>
    