<script>
import QRCodeVue3 from "qrcode-vue3";
import axios from 'axios';
import InfoMessage from "../general/InfoMessage.vue";
import VueSpinnerToBtn from "../general/VueSpinnerToBtn.vue";

export default {
    name: 'GeneralModal',
    data() {
        return {
            QrValue: '033333333',
            verificationCode: null,
            error: {
                text: '',
                isError: false,
            },
            isSetupError: false,
            qrWidth: 250,
            qrHeight: 250,
            isSpinnerBtn: false
        }
    },
    components: {
        QRCodeVue3,InfoMessage, VueSpinnerToBtn
    },
    emits: ['setErrorMessage', 'activeMessage'],
    props: {
        modalTitle: String,
        submitTitle: String,
        isVisible: {
            type: Boolean,
            required: true
        }
    },
    watch: {
        verificationCode(newValue) {
            if (newValue === '') {
                this.verificationCode = null
            }
            if (this.error.isError) {
                this.error.isError = false
            }
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        existModal(e) {

            if (e.target.closest('.modal')) return

            this.$emit('close');

        },
        async checkVerify() {


            try {
                this.activeSpinnerbtn()
                this.isSpinnerBtn = true

                this.error.isError = false
                console.log(this.verificationCode)


                if (this.verificationCode === null) {
                    this.error.text = this.$t('general.infoMessage.fillElement')
                    this.error.isError = true
                    this.desactiveSpinnerbtn()
                    this.isSpinnerBtn = false
                    return
                }

                await axios.post(`/mfa/verify?user_code=${this.verificationCode}`)
                this.$store.commit('setMfaConfigured')

                //activarlo
                await axios.post(`/mfa/enable`)
                this.$store.commit('setEnableDisable', true)
                this.close()
            }
            catch (err) {
                console.log(err.config.url)
                if (err.config.url.includes('/mfa/verify')) {
                    // Si falla la verificación del código
                    this.error.text = this.$t('configuration.modalDoubleVerification.codeError')

                } else if (err.config.url.includes('/mfa/enable')) {
                    // Si falla la habilitación de MFA
                    this.close()
                    this.$emit('setErrorMessage', this.$t('configuration.modalDoubleVerification.enable-error'))
                    this.$emit('activeMessage')

                }
                this.error.isError = true
            }
            finally {
                this.desactiveSpinnerbtn()
                this.isSpinnerBtn = false
            }
        },
        cleanError() {
            this.isSetupError = false
            this.error.isError = false
        },
        activeSpinnerbtn() {
            this.$refs.spinnerBtnAction.activeSpinner()
        },
        desactiveSpinnerbtn() {
            this.$refs.spinnerBtnAction.desactiveSpinner()
        },

    },
    mounted() {
        if (window.innerWidth < 301) {
            this.qrHeight = 200
            this.qrWidth = 200
        }
        axios.post(`/mfa/setup`)
            .then(res => {
                this.QrValue = res.data['qr_code']
            })
            .catch(err => {
                console.log(err)
                this.isSetupError = true
            }
            )

    },
}
</script>

<template>
    <div class="modal-bg" v-if="isVisible" @mousedown="(e) => existModal(e)">
        <div class="modal">
            <div class="close-button">
                <button @click="close"><img src="../../assets/icons/close.svg"
                        :alt="this.$t('general.alt.close')"></button>
            </div>

            <div class="content">
                <div v-if="isSetupError" class="info-message-error">
                    <InfoMessage :text="this.$t('general.infoMessage.generalError')" :isActive="true" icon="error"
                        color="#C74D4D" />
                </div>
                <div v-else>
                    <h2>{{ modalTitle }}</h2>

                    <QRCodeVue3 :width="qrWidth" :height="qrHeight" :value="QrValue"
                        :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                        :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }" :dotsOptions="{
                            type: 'dots',
                            color: '#163F49',
                            gradient: {
                                type: 'linear',
                                rotation: 0,
                                colorStops: [
                                    { offset: 0, color: '#000' },
                                    { offset: 1, color: '#000' },
                                ],
                            },
                        }" :backgroundOptions="{ color: 'transparent' }"
                        :cornersSquareOptions="{ type: 'extra-rounded', color: '#163F49' }"
                        :cornersDotOptions="{ type: undefined, color: '#000000' }" fileExt="png" :download="false"
                        myclass="my-qur" imgclass="img-qr" />

                    <slot></slot>



                    <div class="input-div">
                        <label for="codeVerification">{{ this.$t('configuration.modalDoubleVerification.codeLabel')
                            }}</label>
                        <input v-model.number="verificationCode" class="numberElement"
                            :style="this.error.isError && { border: '1px solid #C74D4D' }" type="number"
                            name="codeVerification" id="codeVerification">
                        <span class="error-message" v-if="this.error.isError">
                            {{ this.error.text }}
                        </span>
                    </div>




                    <div class="submit-button">
                        <button @click="checkVerify" class="submit" :disabled="isSpinnerBtn"
                            :class="isSpinnerBtn && 'disable'">
                            <VueSpinnerToBtn ref="spinnerBtnAction">
                                {{ submitTitle }}
                            </VueSpinnerToBtn>
                        </button>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>


<style scoped lang="scss">
.modal-bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(22, 63, 73, 0.4);
    z-index: 20;
    overflow: auto;
    padding-bottom: 20px;

}

.info-message-error {
    margin-top: 30px;
}

.disable {
    cursor: wait !important;
}

.numberElement {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

.numberElement::-webkit-outer-spin-button,
.numberElement::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.modal {
    overflow: hidden;
    max-height: fit-content;
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 854px;
    height: 80vh;
    background: #F9FBFC;
    border-radius: 16px;
    margin-bottom: 40px;

    .close-button {
        text-align: start;
        padding: 12px 0 0 12px;


        button {
            border: none;
            background: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
            height: 28px;

            img {
                width: 28px;
                height: 28px;
            }
        }
    }

    .content {
        padding: 0 64px 64px 64px;
        max-height: 80vh;
        overflow-y: auto;
    }


    h2 {
        font-size: 36px;
        font-family: 'interSemiBold', sans-serif;
        font-weight: 600;
        color: #0D2930;
        text-align: start;
        margin: 0 0 32px 0;

        @media (max-width: 350px) {
            font-size: 20px !important;
        }

    }

    :slotted(h3),
    :slotted(h4),
    :slotted(h5),
    :slotted(h6),
    :slotted(p) {
        text-align: start;
    }

    :slotted(h3),
    :slotted(h4),
    :slotted(h5),
    :slotted(h6) {
        color: #0D2930;
        font-family: 'interSemiBold', sans-serif;
        font-weight: 600;
    }

    :slotted(h3) {
        font-size: 24px;
        margin: 0 0 16px 0;
    }

    :slotted(h4) {
        font-size: 20px;
        margin: 32px 0 16px;
    }

    :slotted(h5) {
        font-size: 18px;
        margin: 32px 0 16px;
    }

    :slotted(p) {
        font-size: 16px;
        color: #0E0E0F;
        font-family: interRegular;
        line-height: 20.8px;
        margin: 5px 0;
    }



    :slotted(p a) {
        text-decoration: underline;
        color: #0E0E0F;

    }

    .input-div {
        margin-top: 30px;
        width: 100%;
        // margin: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        label {
            font-size: 14px;
            color: #707C7E;
            font-family: 'interMedium', sans-serif;
            font-weight: 500;
            line-height: normal;
            display: inline-block;
            margin-bottom: 8px;
            width: fit-content;
            text-align: left;
        }

        input {
            border-radius: 8px;
            width: 100%;
            height: 54px;
            border: 1px solid #CBDEE2;
            outline: none;
            font-size: 16px;
            color: #0E0E0F;
            font-family: 'interRegular', sans-serif;
            font-weight: 400;
            padding: 0 24px;


        }

        input:focus {
            border: 1px solid #B3BBBC;
        }

        .error-message {
            color: #C74D4D;
            font-size: 12px;
            margin-top: 8px;
            font-family: 'InterRegular', sans-serif;
            text-align: left;
        }
    }


    .submit-button {

        text-align: center;

        button.submit {
            margin-top: 62px;
            border-radius: 8px;
            background: #163F49;
            border: none;
            max-width: 606px;
            width: 100%;
            min-height: 64px;
            color: #D2E3E6;
            font-size: 24px;
            font-family: 'interMedium', sans-serif;
            font-weight: 500;
            cursor: pointer;

            @media (max-width: 350px) {
                font-size: 16px !important;
            }


        }
    }

}

@media (max-width: 1024px) {
    .modal {
        max-width: 95vw;
    }
}

@media(max-width:700px) {
    .modal {
        max-width: none;

        .content {
            padding: 0 32px 60px;
        }

        h2 {
            font-size: 28px;
            margin: 0 0 16px 0;
        }

        :slotted(h3) {
            font-size: 18px;
            margin: 0 0 12px 0;
        }

        :slotted(h4) {
            font-size: 16px;
            margin: 12px 0;
        }

        :slotted(h5) {
            font-size: 16px;
            margin: 12px 0;
        }

        :slotted(p) {
            font-size: 12px;
            line-height: 15.6px;
        }

        .submit-button {
            button.submit {
                font-size: 18px;
                padding: 14px 25px;
                margin: 36px 0 0 0;
            }

        }
    }
}
</style>