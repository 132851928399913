<template>
    <div>
        <div class="title">
            <generalTitle :divStyle="{ width: 'fit-content' }" :breadcrumbs="breadcrumbs" :title="title" :root="true"
                @goBack="this.goBack()" />
            <generalButton :isButton="true" text="Subir traducción" @action="addJsonModal" />
        </div>


        <div class="language-container">
            <div v-for="language in languagesValue" :key="language.id" class="language-box">
                <h2>{{ language.title }}</h2>
                <Dropdown :links="optionsLanguage" @editLanguage="editLanguage(language)"
                    @deleteLanguage="deleteLanguage(language.code)"
                    @downloadLanguage="downloadLanguage(language.code)" />
            </div>
        </div>
    </div>
    <TraslantionModalLayaout v-if="isModalActive" @toggleModal="toggleModal">
        <TraslationModal v-if="!isEdit" />
        <TraslationModal v-else :actualLanguageData="actualLanguageData" :isEdit="true" />
    </TraslantionModalLayaout>

</template>


<script>
import generalTitle from '../general/generalTitle.vue';
import Dropdown from '@/components/general/Dropdown.vue';
import jsonSpanish from '@/locales/traducciones.json';
import jsonEnglish from '@/locales/translationEnglish.json';
import generalButton from '@/components/general/Button.vue'
import TraslantionModalLayaout from './TraslantionModalLayaout.vue';
import TraslationModal from './TraslationModal.vue';

export default {
    name: 'TraslationsComponent',
    components: { generalTitle, Dropdown, generalButton, TraslantionModalLayaout,  TraslationModal },
    data() {
        return {
            breadcrumbs: [
                { label: 'Traducciones', to: '/translations' },
            ],
            title: "Traducciones",
            optionsLanguage: [
                { text: this.$t('general.dropdownOptions.download'), data: { action: 'downloadLanguage' } },
                { text: this.$t('general.dropdownOptions.edit'), data: { action: 'editLanguage' } },
                { text: this.$t('general.dropdownOptions.delete'), data: { action: 'deleteLanguage' }, estilos: { color: '#C74D4D' } }
            ],
            languagesValue: [
                { id: 'uno', title: 'Español', code: 'es' }, { id: 'dos', title: 'Inglés', code: 'en' },
            ],
            isModalActive: false,
            isEdit: false,
            actualLanguageData: {}
        }
    },
    methods: {
        addJsonModal(){
            this.isEdit = false
            this.toggleModal()
        },
        toggleModal() {
            this.isModalActive = !this.isModalActive
        },
        deleteLanguage(code) {
            console.log('deleteee ' + code)
        },
        downloadLanguage(code) {
            if (code === 'es') {
                this.downloadJson(jsonSpanish)
            } else if (code === 'en') {
                this.downloadJson(jsonEnglish)
            }

        },
        editLanguage(languageData) {
            this.isEdit = true
            this.actualLanguageData = languageData

            this.toggleModal()
        },
        downloadJson(jsonData) {

            // Convertir el objeto JSON a una cadena JSON legible
            const jsonString = JSON.stringify(jsonData, null, 2); // 2 es la indentación opcional

            // Crear un Blob con el contenido JSON
            const blob = new Blob([jsonString], { type: 'application/json' });

            // Crear una URL temporal para el Blob
            const url = URL.createObjectURL(blob);

            // Crear un enlace temporal para descargar el archivo
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.json'); // Nombre del archivo

            // Simular el clic para descargar el archivo
            link.click();

            // Revocar la URL temporal creada para evitar fugas de memoria
            URL.revokeObjectURL(url);

        }
    },

}


</script>

<style scoped lang="scss">
.title {
    display: flex;
    justify-content: space-between;

    button {
        align-self: end;
    }
}

.language-container {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.language-box {
    background: #ffffff;
    border-radius: 10px;
    padding: 24px 32px 24px 24px;
    width: fit-content;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
