import { createStore } from 'vuex'
import translationsModule from './modules/translations';
import authModule from './modules/auth';
import userModule from './modules/user';
import formsInfo from './modules/formsInfo';


export default createStore({
    modules:{
        translations: translationsModule,
        auth: authModule,
        user: userModule,
        formsInfo: formsInfo,
    }
})  