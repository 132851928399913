<template>
    <Dropdown ref="dropdown" @activeDropdownOpen="this.$emit('activeDropdownOpen')" :isEditPosition="true"
        @impersonal="console.log(this.identifier)"
        :estilos="{ top: `${DropdownPositionY}px`, left: `${DropdownPositionX}px` }" :links="this.optionsCategory"
        @toggleModalSimple="toggleModalSimple({id: this.identifier, name})" :table="true" />
</template>


<script>
import Dropdown from '../general/Dropdown.vue';

export default {
    name: 'TdDropdown',
    data() {
        return {
            optionsCategory: [],
        }
    },
    components: {
        Dropdown
    },
    mounted() {
        if (this.isImpersonal) {
            this.optionsCategory = [{ text: this.$t('general.dropdownOptions.impersonate'), data: { action: 'impersonal', id: this.identifier } }, ...this.optionsCategory]
        }
        if (this.isDelete) {

            this.optionsCategory = [...this.optionsCategory,  { text: this.$t('general.dropdownOptions.edit'), href: `${this.editPath}/${this.identifier}`}, { text: this.$t('general.dropdownOptions.delete'), data: { action: 'toggleModalSimple', id: this.identifier }, estilos: { color: '#C74D4D' } }]
          
        }
    },

    props: {
        identifier: {
            type: String,
            required: true
        },
        toggleModalSimple: {
            type: Function,
        },
        name: String,
        editPath: {
            type: String,
            required: true
        },
        isImpersonal: {
            type: Boolean,
            required: false
        },
        isEdit: {
            type: Boolean,
            default: true
        },
        isDelete: {
            type: Boolean,
            default: true
        },
        isVisualice: {
            type: Boolean,
            default: false
        },
        DropdownPositionX: Number,
        DropdownPositionY: Number

    },
    emits: ['activeDropdownOpen'],
    methods: {
        closeDropdown() {
            this.$refs.dropdown.closeDropdown()
        }
    },
}


</script>

<style scoped>
.ellipses-td {
    width: 60px;
}

@media (max-width: 350px) {
    .ellipses-td {
        width: 0 !important;
    }
}
</style>