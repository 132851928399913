<template>

    <LayaoutTableDinamic :isEdit="isEdit" :isDelete="isDelete" :tableInfoMessage="tableInfoMessage"
        :deleteModalText="deleteModalText" :buttonTitle="buttonTitle" :search="false" :secondaryButton="false"
        :title="this.$t('users.showInstructors.instructors')" :pendingRegistration="true" :isImpersonal="isImpersonal"
        :breadcrumbs="this.breadcrumbsList" path="/user_instructors" :tableColumnsData="tableColumnsData"
        editPath="/users/edit-user" :notCreateButton="isNotCreateButton" :isRoot="false" />
</template>


<script>

import LayaoutTableDinamic from '../users/LayaoutTableDinamic.vue';
export default {
    name: 'InstructorsComponent',
    components: {
        LayaoutTableDinamic
    },
    created() {
        if (this.$store.getters.getRoles.includes('ROLE_SUPERVISOR')) {
            this.breadcrumbsList = [{ label: this.$t('users.showInstructors.breadcrumbs.second'), to: '/users/manage-instructors' }]
            //quitamos el boton de crear
            this.isNotCreateButton = true
            this.isImpersonal = true
            this.isEdit = false
            this.isDelete = false
        }
    },
    data() {
        return {
            isEdit: true,
            isDelete: true,
            isImpersonal: false,
            isNotCreateButton: false,
            breadcrumbsList: [
                { label: this.$t('users.showInstructors.breadcrumbs.first'), to: '/users' },
                { label: this.$t('users.showInstructors.breadcrumbs.second'), to: '/users/manage-instructors' },
            ],
            buttonTitle: {
                create: this.$t('users.showInstructors.button'),
                linkToCreate: '/users/create-user/instructor',
                delete: this.$t('general.buttons.delete')
            },
            deleteModalText: {
                title: this.$t('users.showInstructors.deleteModal.title'),
                namePluralBeforeNumber: this.$t('general.deleteModal.beforeTheNumber.instructors'),
                namePlural: this.$t('general.deleteModal.afterTheNumber.instructors'),
                textSingular: this.$t('users.showInstructors.deleteModal.textSingular'),
                errorSingular: this.$t('users.showInstructors.deleteModal.errorSingular'),
                errorPlural: this.$t('users.showInstructors.deleteModal.errorPlural')
            },
            tableInfoMessage: {
                notCreate: this.$t('users.showInstructors.noInstructors'),
                notSearch: this.$t('general.search.noInstructor'),
                notDeleted: this.$t('users.showInstructors.noDeletedInstructors'),
            },
            tableColumnsData: {
                desk: {
                    columnTitle: [{ elementCode: 'name', title: this.$t('general.tableColumTitles.name.title'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.name.title'), isUp: false },
                    { elementCode: 'email', title: this.$t('general.tableColumTitles.email'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.email'), isUp: false },
                    { elementCode: 'department', title: this.$t('general.tableColumTitles.department'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.department'), isUp: false },
                    { elementCode: 'category', title: this.$t('general.tableColumTitles.category'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.category'), isUp: false }],
                    mainRowName: ['name', 'email', 'department', 'category'],
                    titleDetailsRowName: [],
                    //Tiene que hacer match con los datos
                    detailsRowName: [],
                },
                mobile: {
                    columnTitle: [{ title: this.$t('general.tableColumTitles.name.title'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.name.title') },],
                    mainRowName: ['name'],
                    titleDetailsRowName: [this.$t('general.tableColumTitles.email'), this.$t('general.tableColumTitles.department'), this.$t('general.tableColumTitles.category')],
                    //Tiene que hacer match con los datos
                    detailsRowName: ['email', 'department', 'category'],
                }
            }
        }
    },

}
</script>