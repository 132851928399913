<template>
    <div class="loader" v-if="isLoader">
        <LoaderComponent />
    </div>
    <div v-show="notifications.length > 0 && !isLoader" ref="table" class="notificaciones">
        <NotificationBox v-for="notification in notifications" :key="notification.id"
            :acceptance="notification.acceptNotificationType" :id="notification.id"
            :date="formatedDate(notification.createdAt)" :time="formatedHour(notification.createdAt)"
            :type="notification.type" :text="notification.text" :acceptedbtn="notification.accepted" :isRead="isRead"
            @scrollToErrorMessage="this.$emit('scrollToErrorMessage')" @setNewNotifications="setNewNotifications"
            @updateNotificationSelected="updateNotificationSelected"
            @filterSelectedNotification="filterSelectedNotification"
            @checkIsSelectNotification="checkIsSelectNotification" />
    </div>
    <div v-show="notifications.length === 0 && !isLoader && !isErrorToGetNotifications" class="no-notification">
        <InfoMessage :text="this.$t('notification.noNotifications')" :isActive="true" icon="info" />
    </div>

</template>

<script>
import NotificationBox from './NotificationBox.vue';
import axios from 'axios';
import InfoMessage from '../general/InfoMessage.vue';
import LoaderComponent from '../general/LoaderComponent.vue';

export default {
    name: 'TableNotification',
    props: {
        isRead: Boolean
    },
    components: { NotificationBox, InfoMessage, LoaderComponent },
    emits: ['scrollToErrorMessage', 'showError', 'setSelectedNotifications'],
    data() {
        return {
            notifications: [],
            stopNotification: false,
            totalNumber: 0,
            page: 1,
            loadingMore: false,
            selectedNotifications: [],
            isLoader: false,
            isErrorToGetNotifications: false,
            isAnonymous: false,
            anonymousID: ''
        }
    },
    async mounted() {
        try {
            if (this.$store.getters.getRoles.includes('ROLE_ANONYMOUS')) {
                this.isAnonymous = true
                this.anonymousID = this.$store.getters.getAnonymusCaseFileId

            }
            this.isLoader = true
            this.$emit('setSelectedNotifications', false)
            await this.getNotification()
            this.$nextTick(() => {
                this.$refs.table.addEventListener("scroll", () => this.scrollListener());
            })
        }
        catch (err) {
            console.log(err)
        }
        finally {
            this.isLoader = false
        }


    },
    beforeUnmount() {
        this.$refs.table.removeEventListener("scroll", () => this.scrollListener());
    },
    methods: {
        async getNotification(isScroll, numberOfItems = '20') {
            const tableValue = this.isRead ? '1' : '0'
            try {

                let urlDefault = `/notifications?page=${this.page}&itemsPerPage=${numberOfItems}&readed=${tableValue}&order[createdAt]=desc`
                if (this.isAnonymous) {
                    urlDefault = `/notifications?casefile=${this.anonymousID}&page=${this.page}&itemsPerPage=${numberOfItems}&readed=${tableValue}&order[createdAt]=desc`
                    console.log(urlDefault)
                }
                if (this.stopNotification) return
                const res = await axios.get(urlDefault)

                const notifications = this.addTranslationToSystemNotification(res.data["hydra:member"])

                this.totalNumber += notifications.length
                if (res.data['hydra:totalItems'] === this.totalNumber) {

                    this.stopNotification = true
                }
                if (!isScroll) {
                    this.notifications = notifications
                } else {
                    //añadimos las antiguas más las nuevas
                    this.notifications = [...this.notifications, ...notifications]
                }


                this.page++
                this.loadingMore = false

            }
            catch (err) {
                console.log(err)
                this.isErrorToGetNotifications = true
                this.$emit('showError')
            }
        },
        async setNewNotifications(numberChanged) {
            const number = Number(numberChanged)
            const newItemNumbers = this.totalNumber - number

            try {
                //para que no haya el bug del la llamada de paginas infinita, tenemos que recalcular el total numero
                if (this.stopNotification) {
                    this.totalNumber = 0
                } else {
                    this.totalNumber = number
                }
                this.stopNotification = false

                this.page = 1
                await this.getNotification(false, `${newItemNumbers}`)


            }
            catch (err) {
                console.log(err)
            }
            finally {
                this.page = Math.floor(newItemNumbers / 20) + 2
            }

        },
        updateNotificationSelected(valor) {
            //para poner si se va a marcar o desmarcar como leida o no leida
            const { isRead, notificationId, isSelected } = valor


            if (isSelected) {
                //añadimos
                this.selectedNotifications = [...this.selectedNotifications, { isRead, id: notificationId }]
                this.checkIsSelectNotification()
            }
            else {
                //quitamos
                this.selectedNotifications = this.selectedNotifications.filter(notifications => notifications.id !== notificationId)
                this.checkIsSelectNotification()
            }

        },
        checkIsSelectNotification() {
            if (this.selectedNotifications.length === 0) {
                this.$emit('setSelectedNotifications', false)
            } else {
                this.$emit('setSelectedNotifications', true)
            }
        },
        filterSelectedNotification(id) {
            this.selectedNotifications = this.selectedNotifications.filter(notifications => notifications.id !== id)
        },
        async changeReadNotRead() {
            let number = 0;
            const updateNotifications = this.selectedNotifications.map(element => {
                // Devuelve la promesa de axios.patch
                return axios.patch(`/notifications/${element.id}`, { readed: !this.isRead })
                    .then(() => {
                        number += 1;
                        // Filtra las notificaciones en la respuesta exitosa
                        this.selectedNotifications = this.selectedNotifications.filter(notifications => notifications.id !== element.id);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$emit('scrollToErrorMessage')
                    });
            });

            // Espera a que todas las promesas se resuelvan
            await Promise.all(updateNotifications)
                .then(() => {
                    this.checkIsSelectNotification()
                })

            this.setNewNotifications(`${number}`);
        },

        scrollListener() {
            const table = this.$refs.table
            const scrollPosition = table.scrollTop; // posición actual de scroll en la tabla
            const visibleHeight = table.clientHeight; // altura visible de la tabla (viewport)
            const tableHeight = table.scrollHeight;

            if (scrollPosition + visibleHeight >= tableHeight - 200 && !this.loadingMore) {
                this.loadingMore = true;
                this.getNotification(true);
            }
        },
        addTranslationToSystemNotification(notification) {

            return notification.map(element => {
                function setCasefileOrSubCasefile(element) {
                    return element === 'SubCaseFile' ? 'Subexpediente' : 'Expediente'
                }
                console.log(element)

                let newText = ""
                switch (element.type) {
                    case 'InstructorCaseFileAssigned':
                        newText = `Desea que le asignen el siguiente ${setCasefileOrSubCasefile(element?.casefile?.['@type'])}: ${element?.casefile?.caseCode}`
                        break;
                    case 'CaseFileStateChanged':
                        newText = `El estado del ${setCasefileOrSubCasefile(element?.casefile?.['@type'])} ${element?.casefile?.caseCode} ha cambiado. Su nuevo estado es ${element?.metadata?.state} `;
                        break;
                    case 'CaseFileFinished':
                        newText = 'El expediente se ha cerrado';
                        break;
                    case 'InstructorRejectedCaseFile':
                        newText = `El instructor ${element?.metadata?.instructor?.name} ha rechazado el ${setCasefileOrSubCasefile(element?.casefile?.['@type'])}: ${element?.casefile?.caseCode}`
                        break;
                    case 'InstructorAcceptedCaseFile':
                        newText = `El instructor ${element?.metadata?.instructor?.name} ha aceptado el ${setCasefileOrSubCasefile(element?.casefile?.['@type'])}: ${element?.casefile?.caseCode}`
                        break;
                    case 'NewDocumentation':
                        newText = 'Documentación nueva aportada';
                        break;
                    case 'NewMessageInformant':
                        newText = 'Nuevo mensaje del informante';
                        break;
                    case 'NewMessageInstructor':
                        newText = 'Nuevo mensaje al instructor';
                        break;
                    case 'NewMessageInstructorWithFileRequest':
                        newText = 'Se requieren archivos';
                        break;
                    case 'CaseFileReminder':
                        newText = 'Recordatorio del expediente';
                        break;
                    case 'TaskReminder':
                        newText = 'Recordatorio de la tarea';
                        break;
                    case 'CaseFileCreated':
                        newText = `${setCasefileOrSubCasefile(element?.casefile?.['@type'])} creado correctamente. Número del expediente: ${element?.casefile?.caseCode}`;
                        break;

                    case 'InstructorCaseAssignedInformative':
                        newText = `Se le ha sido asignado el ${setCasefileOrSubCasefile(element?.casefile?.['@type'])}: ${element?.casefile?.caseCode} `;
                        break;
                    case 'CaseFileUnassigned':
                        newText = `Ya no está asignado al ${setCasefileOrSubCasefile(element?.casefile?.['@type'])}: ${element?.casefile?.caseCode} `;
                        break;
                    case 'InstructorAssignedToCaseFile':
                        newText = `El ${setCasefileOrSubCasefile(element?.casefile?.['@type'])} ${element?.casefile?.caseCode} se ha asignado al isntructor: ${element?.metadata?.instructor?.name}. Ya puede enviar mensajes al instructor desde los detalles del  ${setCasefileOrSubCasefile(element?.casefile?.['@type'])}.`;
                        break;
                    case 'CaseFileOutOfInstructors':
                        newText = `El ${setCasefileOrSubCasefile(element?.casefile?.['@type'])} ${element?.casefile?.caseCode} ha sido  por todos los isntructores`;
                        break;
                }
                if (newText !== '') {
                    return { ...element, text: newText }
                } else {
                    return element
                }

            })


        },

        formatedDate(fechaISO) {
            // Crear un objeto Date a partir de la cadena de fecha ISO 8601
            const fecha = new Date(fechaISO);

            // Extraer componentes de la fecha en la zona horaria local
            const dia = fecha.getDate().toString().padStart(2, '0'); // Día
            const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Mes (se suma 1 porque los meses en JavaScript son 0-indexados)
            const año = fecha.getFullYear().toString().slice(-2); // Últimos dos dígitos del año

            // Formatear la fecha y la hora en el formato deseado
            const fechaFormateada = `${dia}/${mes}/${año}`;

            return fechaFormateada;
        },
        formatedHour(fechaISO) {
            const fecha = new Date(fechaISO);

            // Extraer componentes de la hora en la zona horaria local
            const horas = fecha.getHours().toString().padStart(2, '0'); // Hora en formato 24 horas
            const minutos = fecha.getMinutes().toString().padStart(2, '0'); // Minutos

            const hourFormateada = `${horas}:${minutos}`;

            return hourFormateada;

        },

    },

}


</script>


<style scoped lang="scss">
.loader {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.notificaciones {
    border-top: 1px solid var(--Gris--1-Dedavara, #B3BBBC);
    max-height: 700px;
    overflow-y: auto;
}

.no-notification {
    margin-top: 20px;
}



@media (min-width: 769px) {
    .notificaciones {
        border-top: 1px solid var(--Gris--1-Dedavara, #B3BBBC);
    }
}
</style>
