<template>
    <div class="box">
        <div class="dropdwon">
            <img class="cursor-hand" src="@/assets/icons/3ellipses.svg"
                :alt="this.$t('general.alt.movePositionElement')">
        </div>
        <h3>{{ type !== 'Verficación' ? type : this.$t('complainForm.create-edit.previewElement.type.verification') }} {{ type === 'Selector' ? '- ' + name : '' }}</h3>
        <div class="data">
            <input disabled :title="name" v-if="type !== 'Selector' && type !== 'Verficación'" type="text"
                :value="name">
           <div class="container-p"  v-if="type === 'Verficación'"> <p class="input-appearance" v-html="name"></p></div>
            <select v-if="type === 'Selector'" @change="noSelectOption" :value="options[0]">
                <option disabled v-for="option in options" :key="option">{{ option }}</option>
            </select>
        </div>
        <div class="btns">
            <img class="remove" src="@/assets/icons/remove.svg" :alt="this.$t('general.alt.remove')" @click="this.$emit('removeInput')">
            <img class="edit" src="@/assets/icons/EditIcon.svg" :alt="this.$t('general.alt.edit')"
                @click="this.$emit('openModalEdit', { name, type, options, isRequired, id })">
        </div>
        <p class="required" v-if="isRequired">{{ this.$t('complainForm.create-edit.previewElement.required') }}</p>
    </div>

</template>


<script>



export default {
    name: 'InputsPreviewForm',
    props: {
        name: String,
        type: String,
        options: Array,
        isRequired: Boolean,
        id: String
    },
    emits: ['removeInput', 'openModalEdit'],
   

}
</script>

<style scoped lang="scss">
.required {
    text-align: left;
    padding-left: 24px;
    color: #C74D4D;
    padding-top: 10px;
    font-size: 12px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('@/assets/icons/angle-down.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center;
}

.cursor-hand {
    cursor: grab;
}

.box {
    margin-bottom: 20px;
    display: grid;
    align-items: center;
    grid-template-rows: auto auto max-content;
    grid-template-columns: .1fr 1fr .1fr;
    gap: 0 17px;

    @media (max-width: 300px) {
        gap: 0 5px;
    }

    .required {
        grid-row: 3 / 4;
        grid-column: 1 / -1;
    }

    .dropdwon {
        grid-row: 2 / 3;
        justify-self: end;
        transform: rotate(90deg);

    }

    h3 {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
    }

    .data {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
    }

    .btns {
        grid-row: 2 / 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%
    }

    h3 {
        width: 100%;
        height: 12px;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        text-align: left;
        color: #707C7E;
        margin-bottom: 6.62px;
        margin-top: 0;
        font-family: "Inter", sans-serif;
    }

    .container-p{
        display: grid;
    }

    .input-appearance {
     
        /* Establece un color de fondo similar a los inputs */
        vertical-align: middle;
        box-sizing: border-box;
        overflow: auto;
        white-space: nowrap;
        display: inline-block;
        padding-inline: 14px;

    }


    input,
    .input-appearance {
        width: 100%;
        height: 42px;
        border-radius: 7.03px;
        border: 1.1px solid var(--Gris--1-Dedavara, #B3BBBC);
        padding-left: 14px;
        font-family: "Inter", sans-serif;

    }

    .input-appearance {
        text-align: left;
        display: flex;
        align-items: center;
        background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
    }

    select {
        width: 100%;
        height: 42px;
        border-radius: 7.03px;
        border: 1.1px solid var(--Gris--1-Dedavara, #B3BBBC);
        padding-left: 14px;
        font-family: "Inter", sans-serif;
    }




    .edit {
        width: 20px;
        height: 20px;
    }

    .remove {
        width: 18px;
        height: 18px;
    }

    .remove,
    .edit {
        cursor: pointer;

    }


}

@media (min-width: 1200px) {
    .box {
        h3 {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            text-align: left;
            margin-bottom: 8px;

        }




    }
}
</style>