<template>
    <div class="company-box">
        <div class="company-box__logo">
            <img :src="logo" alt="Logo" class="logo">
        </div>
        <div class="company-box__data">
            <span :title="fullName" class="name">{{ fullName }}</span>
            <span :title="companyCode" class="company-code">{{ companyCode }}</span>
            <span class="departments">{{ departments }} {{ departments === 1 ?
                this.$t('general.tableColumTitles.department') : this.$t('general.tableColumTitles.departmentPlural')
                }}</span>
            <span class="language">{{ language }}</span>
        </div>
        <Dropdown :estilos="{ top: '20px', left: '-80px' }" :isEditPosition="true" class="company-box__options"
            :links="optionsCompany"
            @clickedAction="this.$emit('deletedCompany', { id: this.identifier, fullName: this.fullName })" />
    </div>

</template>

<script>
import Dropdown from '@/components/general/Dropdown.vue';

export default {
    name: 'CompanyBoxComponent',
    data() {
        return {
            optionsCompany: []
        }
    },
    components: {
        Dropdown
    },

    props: {
        identifier: String,
        companyCode: String,
        fullName: String,
        departments: Number,
        language: String,
        logo: String,
    },
    mounted() {
        this.setOptions()
    },
    methods: {
        setOptions() {

            this.optionsCompany = [
                { text: this.$t('general.dropdownOptions.edit'), href: `/companies/edit-company/${this.identifier}` },
                { text: this.$t('general.dropdownOptions.delete'), data: { action: 'deleteCompany', id: this.identifier }, estilos: { color: '#C74D4D' } }
            ]

        }
    },
}
</script>

<style scoped lang="scss">
.company-box {
    width: 100%;
    height: 144px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 24px 32px 24px 24px;
    border-radius: 10px;
    gap: 24px;
    position: relative;

    .company-box__logo {
        min-width: 96px;
        height: 96px;
        width: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: .5px solid #B3BBBC;

        .logo {
            width: 50%;
            object-fit: contain;
        }
    }

    .company-box__data {
        display: grid;
        gap: 13px 0;
        width: 100%;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto auto;
        min-width: 0;

        span {
            font-family: 'InterRegular';
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            max-width: 100%;
        }

        .name {
            grid-column: 1 / -1;
            grid-row: 1 / 2;
            color: #0E0E0F;
            text-align: left;
            font-size: 16px;
        }

        .identifier {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            color: #818C8E;
            text-align: left;
            font-size: 14px;
        }

        .company-code {
            justify-self: flex-end;
            grid-column: 2 / 3;
            grid-row: 2 / 3;
            color: #0E0E0F;
            text-align: right;
            font-size: 14px;
        }

        .language {
            justify-self: flex-end;
            grid-column: 2 / 3;
            grid-row: 3 / 4;
            color: #0E0E0F;
            text-align: right;
            font-size: 14px;
        }

        .departments {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            color: #818C8E;
            text-align: left;
            font-size: 14px;
        }

    }

    .company-box__options {
        position: absolute;
        top: 12px;
        right: 18px;
    }
}

@media (max-width: 768px) {
    .company-box {
        height: 90px;
        padding: 12px;

        .company-box__logo {
            min-width: 64px;
            height: 64px;
            width: 64px;
        }

        .company-box__data {
            gap: 6px 0;

            span {
                width: 95%;
                max-width: 95%;
            }

            .name {
                font-size: 14px;
            }

            .identifier {
                font-size: 12px;
            }

            .company-code {
                font-size: 12px;
            }

            .language {
                font-size: 12px;
            }

            .departments {
                font-size: 12px;
            }
        }

        .company-box__options {
            top: 0px;
            right: 12px;
        }
    }
}
</style>