import axios from 'axios';
import router from '@/router/index';

const auth = {
    state: {
        authorized: (localStorage.getItem('authorized') !== null && localStorage.getItem('authorized') !== undefined) ? true : false,
        roles: (localStorage.getItem('roles') !== null && localStorage.getItem('roles') !== undefined) ? JSON.parse(localStorage.getItem('roles')) : [],
    },
    mutations: {
        setAuthorized(state, payload) {
            state.authorized = payload;
        },
        setRoles(state, payload) {
            state.roles = payload;
        }
    },
    actions: {
        checkToken({ commit }) {
            return axios.post('/auth/check/token')
                .then(() => {
                    commit('setAuthorized', true);
                    localStorage.setItem('authorized', true);
                    return this.dispatch('getUserRoles');
                })
                .catch(() => {
                    if (localStorage.getItem('authorized') !== null) {
                        commit('setAuthorized', false);
                        localStorage.removeItem('authorized');
                        router.push('/login');
                    }
                })
        },
        getUserRoles({ commit }) {
            return axios.get('/user/roles')
                .then((response) => {
                    commit('setRoles', response.data);
                    localStorage.setItem('roles', JSON.stringify(response.data));
                })
                .catch((err) => {
                    commit('setAuthorized', false);
                    localStorage.removeItem('roles');
                    console.error(err)
                })
        },
        logout({ commit }) {
            axios.post('/logout')
                .then(() => {
                    commit('setAuthorized', false);
                    commit('setUserCompany', '')
                    commit('setRoles', []);
                    localStorage.removeItem('setAnonymusCaseFileId');
                    localStorage.removeItem('authorized');
                    localStorage.removeItem('roles');
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    router.push('/login').catch(err => {
                        console.error('Error during navigation to login:', err);
                    })
                })
                .catch((err) => {
                    console.error(err)

                })
        },
        setAuthorizedAction({ commit }, payload) {
            commit('setAuthorized', payload);
        }
    },
    getters: {
        isAuthorized: state => {
            return state.authorized;
        },
        getRoles: state => {
            return state.roles;
        }
    }
}
export default auth;