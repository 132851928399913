<template>
    <div class="template">
        <div class="title">
            <h3>Estadísticas</h3>
            <img src="../../assets/icons/vector.svg" alt="Vector" @click="goBack">
            <h1>Estadísticas</h1>
        </div>

        <div class="total-files">
            <div class="filter-total-files">
                <select name="option">
                    <option value="">Expedientes Totales</option>
                </select>

                <div class="dates">
                    <img src="../../assets/icons/calendar.svg" alt="calendar">
                    <input type="date" id="fecha_inicio" name="fecha_inicio">
                    <span>-</span>
                    <input type="date" id="fecha_fin" name="fecha_fin"><br>
                </div>
            </div>

            <div class="chart">
                <Line :style="myStyles" id="asdas" :options="chartOptionsLine" :data="chartDataLine" />
            </div>
        </div>

        <div class="set-goal">
            <h2>Establecer objetivo</h2>
            <input type="text" placeholder="23 Expedientes">
        </div>

        <div class="category-files">
            <div class="chart">
                <h2>Expedientes por categoría</h2>
                <Bar :style="styleForLab1200px" id="my-chart-id" :options="chartOptions" :data="chartData" />
                <p>Lucha Contra El Blanqueo De Capitales Y La Financiación Del Terrorismo</p>
            </div>
        </div>

    </div>
</template>

<script>
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, LineElement, PointElement, BarElement } from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, LineElement, PointElement, BarElement);
import { Bar, Line } from 'vue-chartjs'

export default {
    name: 'StatisticsComponent',
    components: {
        Bar,
        Line
    },
    data() {
        return {
            currentRoute: '',
            chartData: {
                labels: [
                    'Fraude Fiscal',
                    'Corrupción Política',
                    'Tráfico de Drogas',
                    'Fraude Financiero',
                    'Acoso Laboral',
                    'Violencia Doméstica',
                    'Robo a Mano Armada',
                    'Fraude en Línea',
                    'Secuestro',
                    'Chantaje Político',
                    'Fraude Electoral',
                    'Trata de Personas',
                    'Incendio Intencional',
                    'Corrupción Gubernamental',
                    'Extorsión'
                ],
                datasets: [{
                    data: [32, 18, 25, 40, 15, 22, 28, 35, 20, 30, 28, 12, 17, 29, 24],
                    backgroundColor: '#D9E7EA',
                    hoverBackgroundColor: '#163F49',
                    barThickness: window.innerWidth >= 457 ? window.innerWidth <= 1200 ? 22 : 14 : 12,
                }]
            },

            chartDataLine: {
                labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                datasets: [
                    {
                        label: "Número de expedientes",
                        data: [32, 18, 25, 40, 15, 22, 28, 35, 20, 30, 28, 12], // Datos de enero a diciembre
                        pointHitRadius: 25,
                        fill: false,
                        backgroundColor: '#163F49',
                        borderColor: '#163F49',

                    }
                ]
            },

            chartOptions: {
                indexAxis: window.innerWidth >= 1200 ? 'y' : 'x', // eje x como el eje principal si en menor a 1730px
                responsive: true,
                plugins: {
                    legend: {
                        display: false // Oculta la leyenda
                    },
                    tooltip: {
                        enabled: true, // activa el tooltip predeterminado

                        //para personalizar el tooltip

                        // backgroundColor: 'transparent',
                        // displayColors: false,
                        // callbacks: {
                        //     title: function () {
                        //         //elimino el titulo
                        //         return '';
                        //     },

                        //     labelTextColor: function () {
                        //         // Retorna el color deseado en formato hexadecimal
                        //         return '#163F49';
                        //     },

                        // }
                    }
                },
                scales: {
                    x: {
                        display: window.innerWidth >= 1200 ? true : false,

                        grid: {
                            display: true // Muestra la cuadrícula en el eje X
                        },
                        //max: 200, //el tamaño maximo para que lleguen los tick
                        ticks: {

                            stepSize: 10, //espacio entre cada tick
                        },
                        position: 'top', //para que salga los numeros arriba
                        border: {
                            display: false //para quitar el borde que aparece debajo de los numeros
                        }
                    },
                    y: {
                        display: window.innerWidth >= 1200 ? true : false,
                        beginAtZero: true,
                        grid: {
                            display: false // Desactiva las líneas de la cuadrícula en el eje y
                        },
                        ticks: {
                            font: {
                                size: 9,
                            },
                            align: 'left',

                        },
                        position: 'left',
                    }
                },
                // Ajustes adicionales para las barras
                elements: {
                    bar: {
                        borderWidth: 0,
                        borderRadius: 2, // Establece el radio de borde de las barras
                        borderSkipped: 'start' // Alinea las etiquetas a la izquierda de las barras
                    }
                },

            },


            chartOptionsLine: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false // Oculta la leyenda
                    },
                },

                tooltip: {
                    enabled: false // Desactivar tooltip
                },
                scales: {
                    x: {
                        border: {
                            display: false,
                        },
                        display: true,
                        ticks: {
                            maxRotation: 0, // Establece la rotación máxima a 0 grados (horizontal)
                            minRotation: 0,
                            display: true, // Muestra las etiquetas
                            callback: function (value) {
                                // Devuelve el nombre del mes correspondiente al índice
                                return ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Agos', 'Sep', 'Oct', 'Nov', 'Dic'][value];
                            }
                        },
                        grid: {
                            display: false, // Oculta las líneas de fondo del eje x
                        },
                    },
                    y: {
                        display: window.innerWidth >= 1200 ? true : false,
                        beginAtZero: true, // Comienza el eje y desde cero
                        grid: {
                            display: true, // Muestra las líneas de fondo del eje y
                        },
                        ticks: {
                            stepSize: 10, // Muestra las marcas de 10 en 10
                        },
                    },
                },

                elements: {
                    line: {
                        borderWidth: 2,
                    }
                }
            }
        }
    },
    computed: {
        myStyles() {
            return {
                width: `${100}%`,
                height: `${70}%`,
                position: 'relative',
            }
        },

        styleForLab1200px() {
            return {
                width: `${window.innerWidth >= 1200 ? 40 : 100}%`,
                height: `${70}%`,
                position: 'relative',
            }
        }
    },
    methods: {
        goBack() {
            window.history.back();
        }

    },
}
</script>

<style scoped lang="scss">
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('@/assets/icons/angle-down.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center;
}

.template {
    display: block;

    .title {

        display: flex;

        h3 {
            font-family: "Inter", sans-serif;
            display: none;
        }

        img {
            width: 8px;
            height: 15px;
            margin-right: 26px;
            margin-top: 6px;
            cursor: pointer;
        }

        h1 {
            font-size: 20px;
            font-weight: 600;
            line-height: 24.2px;
            text-align: left;
            color: #163F49;
            font-family: "Inter", sans-serif;
            margin: 0;
        }

    }

    .total-files {
        background-color: #FFFFFF;
        border-radius: 9.96px;
        margin-top: 39px;
        padding: 24px 16px 16px;

        .filter-total-files {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 0 14px 0;
            border-bottom: 1px solid var(--Verde-Menta-0, #E7F0F2);

            select {
                width: 197px;
                height: 32px;
                border-radius: 8px;
                border: 1px solid #CBDEE2;
                color: #0E0E0F;
                padding-left: 12px;
                padding-top: 8px;
                padding-bottom: 7px;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.52px;
                text-align: left;

            }

            .dates {
                display: flex;
                width: 197px;
                height: 32px;
                border-radius: 8px;
                border: 1px solid #CBDEE2;
                align-items: center;
                justify-content: center;
                margin-top: 6px;

                img {
                    width: 16px;
                    height: 14px;
                    margin-right: 2px;
                }

                input[type="date"] {
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    border: none;
                    font-size: 12px;
                    width: 80px;
                }

                input[type="date"]::-webkit-calendar-picker-indicator {
                    display: none;
                }
            }
        }

    }

    .set-goal {
        background-color: #FFFFFF;
        margin-top: 16px;


        padding: 16px;
        border-radius: 7.7px;
        align-self: start;

        h2 {
            font-family: "Inter", sans-serif;
            font-size: 14px;
            font-weight: 600;
            text-align: left;
            color: #B3BBBC;
            margin-top: 0;
        }

        input {
            display: block;
            border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
            font-family: "Inter", sans-serif;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: #163F49;
            height: 38.54px;
            border-radius: 8px;
            width: 100%;
            padding: 0 0 0 20px;
        }

        input::placeholder {
            color: #B3BBBC;
        }
    }

    .category-files {
        background-color: #FFFFFF;
        margin: 16px 0;
        border-radius: 9.96px;

        .chart {
            padding: 16px;

            h2 {
                font-family: "Inter", sans-serif;
                font-size: 14px;
                font-weight: 600;
                line-height: 16.94px;
                text-align: left;
                color: #B3BBBC;
                margin-top: 0;
            }

            p {
                font-family: "Inter", sans-serif;
                font-size: 9px;
                font-weight: 400;
                line-height: 10.89px;
                text-align: center;
                padding: 11px 0 0 0;
            }
        }
    }
}



@media (min-width: 850px) {


    .template {

        .total-files,
        .set-goal,
        .category-files {
            margin: 0;
        }

        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;

        .set-goal {
            width: auto;
            margin-left: 14px;
            margin-top: 32px;
            margin-right: 0;
            padding: 24px;
            min-width: 302px;

            h2 {
                font-size: 16px;
                font-weight: 600;
                line-height: 19.36px;

            }

            input {
                height: 48px;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;

            }
        }

        .total-files {
            margin-top: 32px;
            padding: 0 24px 24px 24px;

            .filter-total-files {
                padding: 0 0 20px 0;
                margin: 16px 0 0;
                flex-direction: row;
                justify-content: space-between;
                border: none;
            }
        }

        .category-files {
            margin-top: 24px;

            .chart {
                padding: 16px 24px 24px;
            }


        }

        .title {
            grid-column: span 2;
            display: block;

            h3 {
                display: block;
                margin: 0;
                text-align: left;
                font-family: "Inter", sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.52px;
                text-align: left;
                color: #A2ABAD;
            }

            img {
                display: none;
            }

            h1 {
                margin: 25px 0 0 0;
                font-size: 30px;
                font-weight: 600;
                line-height: 36.31px;
            }
        }

        .category-files {
            p {
                display: none;
            }

        }
    }
}
</style>