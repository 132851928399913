<template>
  <div class="form-group">
    <div class="checkbox">
      <input
        v-model="dataCheck"
        type="checkbox"
        :id="id"
        @change="updateValue($event)"
        :checked="checkValue"
      />
      <label :for="id">
        <span
          class="checkbox-custom"
          :class="{ error: error }"
          :style="{ minWidth: width, minHeight: width, marginRight: margin }"
        ></span>
        <slot>{{ checkboxText }}</slot>
      </label>
    </div>

    <span class="error-message" v-if="error">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
export default {
  name: "GeneralCheckbox",
  data(){
    return {checkValue: this.checked, dataCheck: false }
  },
  props: {
    checkboxText: {
      type: String,
      default: "",
    },
    id: String,
    errorMessage: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "20px",
    },
    margin: {
      type: String,
      default: '0'
    },
    error: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    checked(){
      this.checkValue = this.checked
    }

  },
  emits: ["update-value"],
  methods: {
    updateValue() {
      this.$emit("update-value", this.dataCheck);
    },
    setCheckBox(value){
      this.dataCheck = value
      this.updateValue()
    }
  },
};
</script>

<style scoped lang="scss">
$form-group-checkbox-border: 1.25px solid #b3bbbc;
$form-group-checkbox-background: #163f49;
$checkbox-margin-right: 12px;

.form-group {
  display: flex;
  flex-direction: column;

  .checkbox {
    display: flex;
    align-items: center;
    ;

    input[type="checkbox"] {
      display: none;
    }

    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #a2abad;
      font-size: 14px;
      gap: 10px;

      :slotted(p) {
        color: #a2abad;
      }

      :slotted(a) {
        color: #55737a;
        font-family: "interMedium", sans-serif;
      }

      .checkbox-custom {
        background-color: transparent;
        border: $form-group-checkbox-border;
        display: inline-block;
        border-radius: 4px;
        margin-right: $checkbox-margin-right;
        font-size: 14px;
        font-family: "interRegular", sans-serif;
      }

      .checkbox-custom.error {
        border-color: #c74d4d;
      }
    }

    input:checked + label .checkbox-custom {
      background-color: $form-group-checkbox-background;
    }
  }

  .error-message {
    color: #c74d4d;
    font-size: 12px;
    margin-top: 8px;
    text-align: left;
  }
}

@media (max-width: 500px) {
  .form-group {
    label,
    :slotted(p) {
      font-size: 12px;
    }
  }
}
</style>
