<template>


    <LayaoutTableDinamic :tableInfoMessage="tableInfoMessage" :deleteModalText="deleteModalText"
        :buttonTitle="buttonTitle" :title="this.$t('complainForm.showComplainForm.title')" :breadcrumbs="this.breadcrumbsList" path="/forms"
        :tableColumnsData="tableColumnsData" :secondaryButton="false" editPath="/complaint-forms/edit-form" :lastActivityChange="true" 
        :nullValue="this.$t('general.inputElements.noAssing')"   />

</template>


<script>

import LayaoutTableDinamic from '../users/LayaoutTableDinamic.vue';

export default {
    name: 'ComplaintFormComponent',
    components: {
        LayaoutTableDinamic
    },
    data() {
        return {
            breadcrumbsList: [
                { label: this.$t('complainForm.showComplainForm.breadcrumbs'), to: '/complaint-forms' },
            ],
            buttonTitle: {
                create: this.$t('complainForm.showComplainForm.buttonCreate'),
                linkToCreate: '/complaint-forms/create-form',
                delete: this.$t('general.buttons.delete')
            },
            deleteModalText: {
                title: this.$t('complainForm.showComplainForm.deleteModal.title'),
                namePluralBeforeNumber: this.$t('general.deleteModal.beforeTheNumber.complainForms'),
                namePlural: this.$t('general.deleteModal.afterTheNumber.complainForms'),
                textSingular: this.$t('complainForm.showComplainForm.deleteModal.textSingular'),
                errorSingular: this.$t('complainForm.showComplainForm.deleteModal.errorSingular'),
                errorPlural: this.$t('complainForm.showComplainForm.deleteModal.errorPlural'),
                aditionalInformation: {
                    active: true,
                    textSingular: this.$t('complainForm.showComplainForm.deleteModal.additionalInformationSingular'),
                    textPlural: this.$t('complainForm.showComplainForm.deleteModal.additionalInformationPlural')
                }
            },
            tableInfoMessage: {
                notCreate: this.$t('complainForm.showComplainForm.noForms'),
                notSearch: this.$t('general.search.noComplainForm'),
                notDeleted: this.$t('complainForm.showComplainForm.noDeletedForms')
            },
            tableColumnsData: {
                desk: {
                    columnTitle: [
                        {elementCode: 'title',  title: this.$t('general.tableColumTitles.form'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.form'), isUp: false },
                        { title: this.$t('general.tableColumTitles.lastActivityText2'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.lastActivityText2'), isUp: false},
                        {elementCode: 'category', telementCode: 'lastActivity', title: this.$t('general.tableColumTitles.formDinamicAssigned'), image: require('@/assets/icons/arrow_filter.svg'), alt:  this.$t('general.tableColumTitles.formDinamicAssigned'), isUp: false}
                ],
                    //Tiene que hacer match con los datos
                    mainRowName: ['title', 'last_activity', 'category'],
                    titleDetailsRowName: [],
                    //Tiene que hacer match con los datos
                    detailsRowName: [],
                },
                mobile: {
                    columnTitle: [{ title: this.$t('general.tableColumTitles.form'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.form') }],
                    //Tiene que hacer match con los datos
                    mainRowName: ['title'],
                    titleDetailsRowName: [this.$t('general.tableColumTitles.lastActivityText2'),  this.$t('general.tableColumTitles.formDinamicAssigned')],
                    //Tiene que hacer match con los datos
                    detailsRowName: ['last_activity', 'category'],
                }
            }
        }
    },

}
</script>
