<!-- Dropdown.vue -->
<template>
  <div :style="!isEditPosition && {position: 'relative'}" class="dropdown" ref="dropdown">
    <button @click="toggleDropdown" class="dropdown-toggle" >
      <img src="@/assets/icons/context-menu.svg" alt="Menu" :style="{width}"  />
    </button>
    <DropdownMenu :estilos="estilos" @clickedAction="handleChildAction" :isOpen="isOpen" :table="table" :links="links" />
    
  </div>
</template>

<script>
import DropdownMenu from './DropdownMenu.vue';


export default {
  name: "DropdownComponent",
  components: {DropdownMenu },
  data() {
    return {
      isOpen: false,
      pageX: 0,
      pageY: 0,
    };
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
    width: {
      type: String,
      default: "auto",
    },
    right: {
      type: String,
      default: "0",
    },
    left: {
      type: String,
      default: "auto",
    },
  
    table: {
      type: Boolean,
      required: false,
      default: false
    },
    estilos: {
      type: Object
    },
    isEditPosition: {
      type: Boolean,
      default: false,
      required: false
    },
   
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      if(this.isOpen) {
        // para decirle al padre que está activo y que si hace scroll que los cierre
        this.$emit('activeDropdownOpen')
        
      } 
    },
    closeDropdown() {
      this.isOpen = false;
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.closeDropdown();
      }
    },
    handleChildAction(action, data) {
      
      this.$emit('clickedAction', action, data);
      this.$emit(`${action.data.action}`)
    }
  },
  watch: {
    $route() {
      this.closeDropdown();
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
 //position relative si quieres la opcion 1 ajustar con props
  display: inline-block;
 // position: relative;
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;

 
}

</style>
