<script>
export default {
    name: 'GeneralModal',
    props: {
        modalTitle: String,
        submitTitle: String,
        isVisible: {
            type: Boolean,
            required: true
        },
        checkValor: String
    },
    methods: {
        close() {
            this.$emit('close');
        },
        existModal(e) {
            if (e.target.closest('.modal')) return
            this.close()
        },
        acept(){
            this.$emit('acept', this.checkValor)
            this.close()
        }
    }
}
</script>

<template>
    <div @mousedown="(e) => existModal(e)" class="modal-bg" v-if="isVisible">
        <div class="modal">
            <div class="close-button">
                <button @click="close"><img src="../../assets/icons/close.svg"></button>
            </div>
            <div class="content">
                <h2>{{ modalTitle }}</h2>
                <slot></slot>
                <div class="submit-button">
                    <button @click="acept" class="submit">{{ submitTitle }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

 
<style scoped lang="scss">
.modal-bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(22, 63, 73, 0.4);
    z-index: 20;
    overflow: auto;
    padding-bottom: 20px;

}

.modal {

    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 854px;
    height: auto;
    background: #F9FBFC;
    border-radius: 16px;

    .close-button {
        text-align: start;
        padding: 12px 0 0 12px;


        button {
            border: none;
            background: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
            height: 28px;

            img {
                width: 28px;
                height: 28px;
            }
        }
    }

    .content {
        padding: 0 64px 64px 64px;
        max-height: 80vh;
        overflow-y: auto;
    }


    h2 {
        font-size: 36px;
        font-family: 'interSemiBold', sans-serif;
        font-weight: 600;
        color: #0D2930;
        text-align: start;
        margin: 0 0 32px 0;

    }

    :slotted(h3),
    :slotted(h4),
    :slotted(h5),
    :slotted(h6),
    :slotted(p) {
        text-align: start;
    }

    :slotted(h3),
    :slotted(h4),
    :slotted(h5),
    :slotted(h6) {
        color: #0D2930;
        font-family: 'interSemiBold', sans-serif;
        font-weight: 600;
    }

    :slotted(h3) {
        font-size: 24px;
        margin: 0 0 16px 0;
    }

    :slotted(h4) {
        font-size: 20px;
        margin: 32px 0 16px;
    }

    :slotted(h5) {
        font-size: 18px;
        margin: 32px 0 16px;
    }

    :slotted(p) {
        font-size: 16px;
        color: #0E0E0F;
        font-family: interRegular;
        line-height: 20.8px;
        margin: 0;
    }



    :slotted(p a) {
        text-decoration: underline;
        color: #0E0E0F;

    }



    .submit-button {

        text-align: center;

        button.submit {
            margin-top: 62px;
            border-radius: 8px;
            background: #163F49;
            border: none;
            max-width: 606px;
            width: 100%;
            min-height: 64px;
            color: #D2E3E6;
            font-size: 24px;
            font-family: 'interMedium', sans-serif;
            font-weight: 500;
            cursor: pointer;

        }
    }

}

@media (max-width: 1024px) {
    .modal{
        max-width: 95vw;
    }
}

@media(max-width:700px) {
    .modal {
        max-width: none;
        .content {
            padding: 0 32px 32px;
        }
        h2{
            font-size: 28px;
            margin: 0 0 16px 0;
        }
        :slotted(h3){
            font-size: 18px;
            margin: 0 0 12px 0;
        }
        :slotted(h4){
            font-size: 16px;
            margin: 12px 0;
        }
        :slotted(h5){
            font-size: 16px;
            margin: 12px 0;
        }
        :slotted(p){
            font-size: 12px;
            line-height: 15.6px;
        }
        .submit-button {
            button.submit {
                font-size: 18px;
                padding: 14px 25px;
                margin: 36px 0 0 0;
            }

        }
    }
}
</style>