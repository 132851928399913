<template>

    <div class="configuration">

        <h1>{{ this.$t('configuration.title') }}</h1>

        <div ref="errorMessage" v-show="isError">
            <InfoMessage :styleDiv="{ marginBottom: '0' }" :text="errorText" :isActive="true" icon="error"
                color="#C74D4D" />
        </div>

        <!--Options-->
        <div class="options">

            <!--Manage subscription
            <div class="option">
                <h2>Suscripción <span class="state active">Activa</span></h2>
                <a class="manage-suscription" href="#">Gestionar <span>
                        suscripción</span></a>
            </div>
            -->

            <!--Close time casefile-->
            <div v-if="!this.isInformant && !this.isAnonymous && !isUnlocked" class="option">
                <h2>{{ this.$t('configuration.automaticCasefileClosure') }}</h2>
                <div class="input">
                    <ConfigurationInput placeholder="45" value="45" :text="this.$t('configuration.days')" />
                </div>

            </div>

            <!--Remove time casefile-->
            <div v-if="!this.isInformant && !this.isAnonymous && !isUnlocked" class="option">
                <h2>{{ this.$t('configuration.timeFinalCasefileDeletion') }}</h2>
                <div class="input">
                    <ConfigurationInput placeholder="20" :text="this.$t('configuration.years')" />
                </div>
            </div>

            <!--Change language-->
            <div class="option">
                <h2>{{ this.$t('configuration.changeLanguage') }}</h2>
                <div class="select">
                    <Select name="languageSelect" id="languageSelect" :options="languageOptions"
                        :optionSelected="this.$store.getters.getSelectedLanguage"
                        @optionChanged="changeLanguage($event)" />
                </div>

            </div>

            <!--Enable/disable MFA-->
            <div class="option" v-if="!this.isAnonymous">
                <h2>{{ this.$t('configuration.DoubleVerification') }}</h2>
                <ToggleSwitch @openModal2FA="openModal2FA" :isDisable="isDisable" />
            </div>

            <!--Remove account-->
            <div v-if="!this.isAnonymous" class="option remove-account">
                <a href="#"><img src="../../assets/icons/remove.svg">{{ this.$t('configuration.DeleteAccount') }}</a>
            </div>

        </div>

        <div class="false-top"></div>

        <div class="copyright">
            <CopyrightText />
        </div>




        <!--Modal of QR code-->
        <ModalQr @setErrorMessage="setErrorMessage" @activeMessage="activeMessage" ref="modalQr"
            :isVisible="modals.modal2" @close="closeModal('modal2')"
            :modalTitle="this.$t('configuration.modalDoubleVerification.title')"
            :submitTitle="this.$t('configuration.modalDoubleVerification.scanCode')">

            <h3>{{ this.$t('configuration.modalDoubleVerification.subTitle') }}</h3>

            <p>{{ this.$t('configuration.modalDoubleVerification.paragraph-one') }}</p>

            <p>{{ this.$t('configuration.modalDoubleVerification.paragraph-two') }}</p>

        </ModalQr>

    </div>
</template>


<script>
import ModalQr from '@/components/modal/ModalQr.vue'
import ConfigurationInput from '@/components/forms/ConfigurationInput.vue'
import Select from '@/components/forms/Select.vue'
import ToggleSwitch from '@/components/forms/ToggleSwitch.vue'
import CopyrightText from '@/components/general/CopyrightText.vue'
import { nextTick } from 'vue';
import axios from 'axios';
import InfoMessage from '../general/InfoMessage.vue'
import {setLanguageCookie} from '@/components/utils.js'



export default {
    name: 'ConfigurationComponent',
    data() {
        return {
            modals: {
                modal1: false,
                modal2: false,
            },
            isInformant: false,
            isAnonymous: false,
            isUnlocked: false,
            switchValue: false,
            languageOptions: [
                { label: this.$t('configuration.languageOptions.spanish'), value: 'es' },
                { label: this.$t('configuration.languageOptions.english'), value: 'en' },
            ],
            isError: false,
            isDisable: false,
            errorText: this.$t('general.infoMessage.generalError')
        }
    },
    mounted() {
        const userTypes = this.$store.getters.getRoles
        if (userTypes.includes('ROLE_ANONYMOUS')) {
            this.isAnonymous = true
        }
        else if (userTypes.includes('ROLE_INFORMANT')) {
            this.isInformant = true
        }
        else if (userTypes.includes('ROLE_UNLOCKED')) {
            this.isUnlocked = true
        }

    },
    components: {
        Select,
        ToggleSwitch,
        ConfigurationInput,
        CopyrightText,
        ModalQr,
        InfoMessage
    },
    methods: {
        async changeLanguage(event) {
            setLanguageCookie(event)
            this.$i18n.locale = event; // Cambia el idioma a español
            this.$store.dispatch('setSelectedLanguage', event) // Guarda el idioma en localStorage
            await nextTick(); // Espera a que el DOM se actualice
            this.languageOptions = [
                { label: this.$t('configuration.languageOptions.spanish'), value: 'es' },
                { label: this.$t('configuration.languageOptions.english'), value: 'en' },
            ]

        },
        openModal(modalId) {
            this.modals[modalId] = true;
        },
        async openModal2FA() {
            this.isDisable = true
            const isSetUp = await this.$store.getters.getUser.mfaConfigured



            if (isSetUp) {
                //toggle de activar o desactivar
                try {
                    const isEnable = await this.$store.getters.getUser.mfaEnabled
                    await axios.post(isEnable ? '/mfa/disable' : '/mfa/enable')
                    this.$store.commit('setEnableDisable', !isEnable)
                }
                catch (err) {
                    console.log(err)
                    this.errorText = this.$t('general.infoMessage.generalError')
                    this.activeMessage()
                }
                finally {
                    this.isDisable = false
                }

            } else {
                //abrir modal
                this.$refs.modalQr.cleanError()
                this.openModal('modal2')
                this.isDisable = false

            }

        },

        closeModal(modalId) {
            this.switchValue = false;
            this.modals[modalId] = false;

        },
        handleToggleSwitchChange(newValue) {
            this.switchValue = newValue;
        },

        activeMessage() {
            this.isError = true
            const element = this.$refs.errorMessage;
            this.$nextTick(() => {
                // Aseguramos que el DOM se haya actualizado
                element.scrollIntoView({ behavior: "smooth" });
            });
            setTimeout(() => {
                this.isError = false
            }, 2000)
        },
        setErrorMessage(value) {
            this.errorText = value
        }
    },

    props: {

    },


}
</script>

<style scoped lang="scss">
.false-top {
    height: 50px;
    width: 50px;
}

.configuration {
    width: 100%;
    height: 100%;

    h1 {
        text-align: left;
        margin: 25px 0 24px 0;
        font-size: 30px;
    }

    .options {
        width: 100%;
        text-align: left;
        margin-top: 113px;

        .option {
            width: 100%;
            min-height: 92px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 40px 24px 24px;
            border-top: 1px solid #B3BBBC;

            .select {
                width: 100%;
                max-width: 148px;
            }

            .input {
                width: 100%;
                max-width: 141px;
            }

            h2 {
                margin: 0;
                font-family: 'InterMedium';
                font-weight: 500;
                font-size: 16px;
                color: #0E0E0F;
                padding-right: 15px;


            }

            .manage-suscription {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #163F49;
                padding: 10px;
                border-radius: 4px;
                font-family: 'interMedium';

                span {
                    margin-left: 4px;
                }
            }


            span.state {
                font-size: 16px;
                font-family: 'interMedium';
                margin-left: 10px;
                border-radius: 17px;
                padding: 5px 14px;
            }

            span.state.active {
                color: #55737A;
                border: 1px solid #55737A;
            }

            span.state.pending {
                color: #E7885F;
                border: 1px solid #E7885F;
            }

            span.state.pending {
                color: #C74D4D;
                border: 1px solid #C74D4D;
            }
        }


        .remove-account a {
            color: #C74D4D;
            display: flex;
            align-items: center;

            img {
                margin-right: 8px;
            }
        }


        .option:last-child {
            border-bottom: 1px solid #B3BBBC;
        }
    }

    .copyright {
        display: none;
    }
}




@media(max-width: 768px) {
    .configuration {

        h1 {
            font-size: 20px;
            margin: 0;

        }

        .options {
            margin-top: 48px;

            .option {
                padding: 10px;
                min-height: 63px;

                .manage-suscription {
                    max-width: 103px;
                    font-size: 12px;

                    span {
                        display: none;
                    }

                }

                span.state {
                    font-size: 12px;
                }

                h2 {
                    font-size: 12px;
                }

                .select {
                    max-width: 103px;
                }


            }



            .remove-account a {
                font-size: 12px;

                img {
                    margin-right: 10px;

                }
            }
        }

        .copyright {
            display: block;
            margin-top: 0;
            padding-bottom: 32px;
        }
    }
}


@media(max-width: 320px) {
    .configuration {



        .options .option {
            padding: 10px 0;

            h2 {
                padding-right: 0;
            }


            span.state {
                display: block;
                margin-top: 10px;
                margin-left: 0;
            }


        }
    }
}
</style>