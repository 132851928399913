<template>
    <div ref="topDiv">
        <CreateEditCategory :title="name.title" :message="isEdit ? editMessage : createdMessage"
            :onClickMethod="isEdit ? updateCategory : addCategory" v-model:firstModelValue.trim="categoryInfo.name"
            v-model:secondModelValue.trim="categoryInfo.description" v-model:threeModelValue="color"
            :buttonName="name.button" :breadcrumbs="breadcrumbs" :getError="getError" :isLoading="isLoading"
            :isState="isState" :focusado="focusado" @setFocusadoToFalse="setFocusadoToFalse"
            :isSpinnerBtn="isSpinnerBtn" ref="createEditElement" :orderState="orderState" :totalState="totalState"
            @updateOrderValue="updateOrderValue" :generalMessage="generalMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import CreateEditCategory from "@/components/categories/CreateEditCategory.vue";
import { mapGetters } from 'vuex';
import { translationStateDefaultMixing, translationCategoryDefaultMixing } from "@/components/translationDefaultMixing.js"


export default {
    name: 'CreateAndEditLayaout',
    components: { CreateEditCategory },
    props: {
        name: {
            type: Object,
            required: true
        },
        path: {
            type: String,
            required: true
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        breadcrumbs: {
            type: Array,
            required: true
        },
        isState: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            apiPrefix: process.env.VUE_APP_API_PREFIX,
            generalMessage: false,
            newCategoryInfo: { name: "", description: "" },
            color: "#163F49",
            editCategoryInfo: { name: "", description: "" },
            createdMessage: { active: false, text: "", icon: "", error: false },
            editMessage: { active: false, text: "", icon: "", error: false },
            createdOpenMessage: false,
            editOpenMessage: false,
            getError: false,
            isLoading: false,
            focusado: false,
            isSpinnerBtn: false,
            orderState: 0,
            totalState: 0
        };
    },
    mixins: [translationStateDefaultMixing, translationCategoryDefaultMixing],
    computed: {
        ...mapGetters(['getUserCompany']),
        userCompany() {
            return this.getUserCompany;
        },
        categoryInfo: {
            get() {
                return this.isEdit ? this.editCategoryInfo : this.newCategoryInfo;
            },
            set(value) {
                if (this.isEdit) {
                    this.editCategoryInfo = value;
                } else {
                    this.newCategoryInfo = value;
                }
            }
        }
    },
    watch: {
        'newCategoryInfo.name'() {
            this.createdMessage.error = false;
            if (this.createdOpenMessage) {
                this.createdMessage.active = true
                this.createdOpenMessage = false
            } else {
                this.createdMessage.active = false
            }
        },
        'newCategoryInfo.description'() {
            this.createdMessage.error = false;
            if (!this.newCategoryInfo.name && !this.newCategoryInfo.description) return
            this.createdMessage.active = false
        },
        //EDITAR
        'editCategoryInfo.name'() {

            if (this.getError) return
            this.editMessage.error = false;
            if (this.editOpenMessage) {
                this.editMessage.active = true
                this.editOpenMessage = false
            } else {
                this.editMessage.active = false
            }
        },
        'editCategoryInfo.description'() {
            if (this.getError) return
            this.editMessage.error = false;

            if (this.editOpenMessage) {
                this.editMessage.active = true
                this.editOpenMessage = false
            } else {
                this.editMessage.active = false
            }
        }
    },
    methods: {
        updateOrderValue(data) {
            this.orderState = Number(data)
        },
        setFocusadoToFalse() {
            this.focusado = false
        },
        async addCategory() {
            this.isSpinnerBtn = true
            this.createdMessage.active = false;
            if (this.newCategoryInfo.name === "") {
                this.createdMessage.error = true;
                this.createdMessage.active = false;
                this.isSpinnerBtn = false
                return;
            }
            this.$refs.createEditElement.activeSpinner()

            try {
                let data = {}
                if (this.isState) {

                    data = {
                        name: this.newCategoryInfo.name,
                        description: this.newCategoryInfo.description,
                        language: `${this.apiPrefix}/languages/lang_d4u6e5d8x9049`,
                        company: `${this.apiPrefix}/companies/${this.userCompany}`,
                        color: this.color,
                        stateOrder: this.orderState - 1
                    }
                } else {
                    data = {
                        name: this.newCategoryInfo.name,
                        description: this.newCategoryInfo.description,
                        language: `${this.apiPrefix}/languages/lang_d4u6e5d8x9049`,
                        company: `${this.apiPrefix}/companies/${this.userCompany}`,
                    }
                }
                await axios.post(`${this.path}`, data);

                this.createdMessage.text = this.$t('general.infoMessage.saveCorrectly');
                this.createdMessage.icon = 'info';
                this.newCategoryInfo.description = "";
                this.newCategoryInfo.name = "";
                this.createdOpenMessage = true

                const element = this.$refs.topDiv;
                element.scrollIntoView({ behavior: "smooth" });
                this.focusado = true
                //al crearse se añade uno nuevo

                this.totalState += 1
                this.orderState = this.totalState


            } catch (error) {
                this.createdMessage.active = true;
                this.createdMessage.text = this.$t('general.infoMessage.generalError');
                this.createdMessage.icon = 'error';

                const element = this.$refs.topDiv;
                element.scrollIntoView({ behavior: "smooth" });
                this.focusado = true
            } finally {
                this.$refs.createEditElement.desactiveSpinner()
                this.isSpinnerBtn = false
            }
        },
        async updateCategory() {
            this.isSpinnerBtn = true
            this.editMessage.active = false;
            if (this.editCategoryInfo.name === "") {
                this.editMessage.error = true;
                this.editMessage.active = false;
                this.isSpinnerBtn = false
                return;
            }
            this.$refs.createEditElement.activeSpinner()

            const url = `${this.path}/${this.identifier}`;
            const data = {
                name: this.editCategoryInfo.name,
                description: this.editCategoryInfo.description
            }
            if (this.isState) {
                data.color = this.color
                data.stateOrder = this.orderState - 1
            }

            try {
                await axios.patch(url, data, {
                    headers: {
                        //  'Content-Type': 'application/merge-patch+json'
                        'Content-Type': 'application/json'
                    }
                });
                this.editMessage.text = this.$t('general.infoMessage.editCorrectly');
                this.editMessage.icon = 'info';
                this.editMessage.active = true;

                const element = this.$refs.topDiv;
                element.scrollIntoView({ behavior: "smooth" });
                this.focusado = true

            } catch (error) {
                this.editMessage.active = true;
                this.editMessage.text = this.$t('general.infoMessage.generalError');
                this.editMessage.icon = 'error';
                console.error('Error:', error.response ? error.response.data : error.message);

                const element = this.$refs.topDiv;
                element.scrollIntoView({ behavior: "smooth" });
                this.focusado = true
            } finally {
                this.$refs.createEditElement.desactiveSpinner()
                this.isSpinnerBtn = false
            }
        },
        async getTotalState() {
            try {
                const res = await axios.get(`/states?company=${this.userCompany}`)
                this.totalState = res.data['hydra:totalItems']
            }
            catch (error) {
                console.log(error)
                this.generalMessage = true

               
            }
        }
    },
    async created() {
        try {
            this.isLoading = true
            
            if(this.isState) {
                await this.getTotalState()
            }
         
            if (!this.isEdit) {
                //porque se añade uno más a los que ya hay al crearse 
                this.totalState = this.totalState + 1
                this.orderState = this.totalState
                this.isLoading = false
                return
            }



            const { id } = this.$route.params;
            this.identifier = id;
            const response = await axios.get(`${this.path}/${id}`);

            if (this.isState) {

                const responseArray = this.changeTraslationDefaultState([response.data])
                this.orderState = response.data.stateOrder + 1
                this.color = response.data.color
                this.editCategoryInfo.name = responseArray[0].name;
                this.editCategoryInfo.description = responseArray[0].description;
            } else {
                const responseArrayCategory = this.changeTraslationDefaultCategories([response.data])
                this.editCategoryInfo.name = responseArrayCategory[0].name;
                this.editCategoryInfo.description = responseArrayCategory[0].description;
            }

            this.isLoading = false
        } catch (error) {
            this.editMessage.active = true;
            this.editMessage.text = this.$t('general.infoMessage.generalError');
            this.editMessage.icon = 'error';

            this.getError = true
            console.error('Error:', error.response ? error.response.data : error.message);
            this.isLoading = false
        }
    }
};
</script>