<template>
  <nav class="breadcrumb">
    <ul>
      <li v-for="(crumb, index) in this.breadcrumbs" :key="index" :class="{'root':this.breadcrumbs.length===1}">
        <span v-if="index!==0" class="separator" >/</span>
        <router-link :to="crumb.to">{{ crumb.label }} </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'BreadcrumbComponent',
  computed: {
    // breadcrumbs() {
    //   const route = this.$route;
    //   const matchedRoutes = route.matched;

    //   return matchedRoutes.map((routeItem) => ({
    //     label: routeItem.meta.breadcrumb || routeItem.name,
    //     to: this.getRoutePath(routeItem),
    //   })).filter((breadcrumb) => breadcrumb.label !== 'Panel'); // Filtrar la ruta raíz
    // },
  },
  props:{
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getRoutePath(routeItem) {
      return routeItem.path; // Simplemente devolver la ruta del objeto de ruta
    },
  },



};
</script>

<style scoped lang="scss">
nav {
  width: 100%;
  text-align: left;
  padding: 0;
  margin: 0;

  ul {
    display: inline-flex;
    list-style: none;
    margin: 0;
    padding: 0;
    color: #A2ABAD;
    gap: 5px;

    li a {
      font-size: 12px;
      font-family: 'interSemibold', sans-serif;
      font-weight: 400;
      color: #A2ABAD;
      padding: 0;
    }
    li span{
      
      margin-right: 5px;
    }
    li:last-child{
      a{
        color: #163F49;
      }
      span{
        color: #163F49;
      }
    }
    li.root{
      a{
        color: #A2ABAD;
      }
      span{
        color: #A2ABAD;
      }
    }
  }
}
</style>