
<template>
<img class="loader" src="@/assets/loader/loader1.svg" alt="loader">

</template>

<script>

export default {
    name: 'LoaderComponent'
}

</script>

<style scoped lang="scss">
.loader {
    animation: spin 2s linear infinite;
    width: 40px;
    display: block;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

</style>