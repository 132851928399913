<template>
  <CaseFileLayaout :titleName="titleName" :createbutton="createbutton" :pathData="pathData" :breadcrumbs="breadcrumbs"
    :dropdownData="dropdownData" :deleteModalInfo="deleteModalInfo" :isSubCase="isSubCase" :root="false"
    :noElementsMessage="this.$t('caseFile.noCreateSubcaseFile')"
    :noSearchMessage="this.$t('general.search.noSubcasefile')" :isPermited="isPermited"
    :noDeletedMessage="this.$t('caseFile.noDeletedCasefile')" :tabTitle="tabTitle" :activeTab="true"
    @setDeleteActiveTable="setDeleteActiveTable" :activeSetDeleteActiveTable="true" />

</template>


<script>
import CaseFileLayaout from '../general/CaseFileLayaout.vue';
import { useRoute } from 'vue-router';
import { mapMutations } from 'vuex';

export default {
  name: "SubCaseFilesComponent",
  components: {
    CaseFileLayaout
  },
  data() {
    return {
      isDeletedTableActive: false,
      titleName: this.$t('caseFile.showCaseFile.title.subcaseFile'),
      createbutton: {
        to: ``,
        text: this.$t('caseFile.showCaseFile.buttons.newSubcaseFile')
      },
      isSubCase: true,
      pathData: {
        getData: ``,
        pathKey: ''
      },
      tabTitle: {
        first: this.$t('caseFile.showCaseFile.title.subcaseFile'),
        second: this.$t('caseFile.deleted'),
      },
      isPermited: true,
      breadcrumbs: [{ label: this.$t('caseFile.showCaseFile.breadcrumb.first'), to: '/expedientes' }, { label: this.$t('caseFile.showCaseFile.breadcrumb.second'), to: `` }],
      deleteModalInfo: {
        deleteModalText: {
          title: this.$t('caseFile.showCaseFile.deleteModal.titleSubcaseFile'),
          textSingular: this.$t('caseFile.showCaseFile.deleteModal.textSingularSubcaseFile'),
          errorSingular: this.$t('caseFile.showCaseFile.deleteModal.errorSingular')
        },
        path: "/sub_case_files"
      }
    }
  },
  methods: {
    ...mapMutations(['toggleUserCompanyDisable']),
    setDeleteActiveTable(value) {
      this.isDeletedTableActive = value
    },
    dropdownData(caseID) {
      if (this.$store.getters.getRoles.includes('ROLE_INSTRUCTOR')) {
        return [
          { text: this.$t('general.dropdownOptions.visualice'), href: '/expedientes/' + caseID },
          { text: this.$t('general.dropdownOptions.edit'), href: '/expedientes/editar-expediente/' + caseID },
          { text: this.$t('general.dropdownOptions.subcaseFile'), href: '/expedientes/sub-expediente/' + caseID }
        ]
      } else {
        if(this.isDeletedTableActive) {
          //ponemos lo que aparecera en el dropdwon de la tabla de eliminadas
          return [
          { text:  this.$t('general.dropdownOptions.definitiveDelete'), data: { action: 'toggleModalSimple', id: caseID }, estilos: { color: '#C74D4D' } },
        ]
        } else{
           //en la tabla normal
           return [
          { text: this.$t('general.dropdownOptions.visualice'), href: '/expedientes/' + caseID },
          { text: this.$t('general.dropdownOptions.edit'), href: '/expedientes/editar-expediente/' + caseID },
          { text: this.$t('general.dropdownOptions.delete'), data: { action: 'toggleModalSimple', id: caseID }, estilos: { color: '#C74D4D' } },
          { text: this.$t('general.dropdownOptions.subcaseFile'), href: '/expedientes/sub-expediente/' + caseID }
        ]
        }
       
      }


    }
  },
  watch: {
    $route() {
      const id = this.$route.params.id
      this.identifier = id
      this.breadcrumbs[1].to = `/expedientes/sub-expediente/${id}`
      this.createbutton.to = `/expedientes/sub-expediente/${id}/crear-subexpediente`
      this.pathData.getData = `sub_case_files?parentCaseFile.id=${id}`
      this.pathData.pathKey = `subCaseFile`
    }
  },

  mounted() {
    this.toggleUserCompanyDisable()
    const router = useRoute();
    const { id } = router.params;
    this.identifier = id
    this.breadcrumbs[1].to = `/expedientes/sub-expediente/${id}`
    this.createbutton.to = `/expedientes/sub-expediente/${id}/crear-subexpediente`
    this.pathData.getData = `sub_case_files?parentCaseFile.id=${id}`
    this.pathData.pathKey = `subCaseFile`
    const rolesToCheck = ["ROLE_COMPANY", "ROLE_COMPANY_ADMIN"];
    if (!rolesToCheck.some(role => this.$store.getters.getRoles.includes(role))) {
      this.isPermited = false
    }
  },
  beforeUnmount() {
    this.toggleUserCompanyDisable()
  },

}


</script>

<style scoped lang="scss"></style>