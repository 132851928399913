<template>
    <div class="dates">
        <img src="../../assets/icons/calendar.svg" :alt="this.$t('general.alt.calendar')">
        <input type="date" id="fecha_inicio" name="fecha_inicio">
        <span>-</span>
        <input type="date" id="fecha_fin" name="fecha_fin"><br>
    </div>
</template>


<script>
export default {
    name: 'InputDatesComponent',
    props: {

    },

}
</script>

<style scoped lang="scss">
.dates {
    margin-top: 0;
    margin-left: auto;
    background-color: #FFFFFF;
    display: flex;
    width: fit-content;
    border-radius: 8px;
    border: 1px solid #CBDEE2;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    img {
        width: 16px;
        height: 14px;
        margin: 12px 5.27px 12px 16px;
    }

    input[type="date"] {

        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        font-size: 14px;
        width: fit-content;
        margin-top: 10px;
        margin-bottom: 11px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: right;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
    }

    #fecha_inicio {
        margin-left: 0;
        margin-right: 1px;
    }

    #fecha_fin {
        margin-right: 18.87px;
    }
}
</style>