<script>
import DataBox from '@/components/dashboard/DataBox.vue';
import FileTable from '@/components/dashboard/FileTable.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import LoaderComponent from '../general/LoaderComponent.vue';
import InfoMessage from '../general/InfoMessage.vue';
import { translationStateDefaultMixing } from "@/components/translationDefaultMixing.js"



import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, LineElement, PointElement, BarElement } from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, LineElement, PointElement, BarElement);



import { Bar, Line } from 'vue-chartjs'

export default {
    name: 'DashboardComponent',
    components: {
        DataBox,
        FileTable,
        Bar,
        Line,
        LoaderComponent,
        InfoMessage
    },
    mixins: [translationStateDefaultMixing],
    watch: {
        $route(to) {
            this.currentRoute = to.name;
        },
        userCompany() {
            this.getState()
        },
    },

    data() {
        return {
            states: [],
            errorState: {
                isError: false,
                text: ''
            },
            tableColumnsData: {

                desk: {
                    columnTitle: [{ elementCode: 'caseCode', title: this.$t('general.tableColumTitles.code'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.code'), isUp: false },
                    { elementCode: 'subject',  title: this.$t('general.tableColumTitles.subject'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.subject'), isUp: false },
                    { elementCode: 'category', title: this.$t('general.tableColumTitles.category'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.category'), isUp: false },
                    { elementCode: 'state',  title: this.$t('general.tableColumTitles.state'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.state'), isUp: false },
                    { elementCode: 'priority',  title: this.$t('general.tableColumTitles.priority'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.priority'), isUp: false }],
                    //Tiene que hacer match con los datos
                    mainRowName: ['caseCode', 'subject', 'category', 'state', 'priority'],
                    titleDetailsRowName: [],
                    //Tiene que hacer match con los datos
                    detailsRowName: [],

                },
                mobile: {
                    columnTitle: [{ title: this.$t('general.tableColumTitles.code'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.code'), isUp: false },
                    { title: this.$t('general.tableColumTitles.subject'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.subject'), isUp: false }],
                    //Tiene que hacer match con los datos
                    mainRowName: ['caseCode', 'subject'],
                    titleDetailsRowName: [this.$t('general.tableColumTitles.category'), this.$t('general.tableColumTitles.state'), this.$t('general.tableColumTitles.priority')],
                    //Tiene que hacer match con los datos
                    detailsRowName: ['category', 'state', 'priority'],
                }
            },

            isLoader: false,
            currentRoute: '',
            chartData: {
                labels: [
                    'Fraude Fiscal',
                    'Corrupción Política',
                    'Tráfico de Drogas',
                    'Fraude Financiero',
                    'Acoso Laboral',
                    'Violencia Doméstica',
                    'Robo a Mano Armada',
                    'Fraude en Línea',
                    'Secuestro',
                    'Chantaje Político',
                    'Fraude Electoral',
                    'Trata de Personas',
                    'Incendio Intencional',
                    'Corrupción Gubernamental',
                    'Extorsión'
                ],
                datasets: [{
                    data: [32, 18, 25, 40, 15, 22, 28, 35, 20, 30, 28, 12, 17, 29, 24],
                    backgroundColor: '#D9E7EA',
                    hoverBackgroundColor: '#163F49',
                    barThickness: 19,
                }]
            },

            chartDataLine: {
                labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                datasets: [
                    {
                        label: "Número de expedientes",
                        data: [32, 18, 25, 40, 15, 22, 28, 35, 20, 30, 28, 12], // Datos de enero a diciembre
                        pointHitRadius: 25,
                        fill: false,
                        backgroundColor: '#163F49',
                        borderColor: '#163F49',

                    }
                ]
            },


            chartOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false // Oculta la leyenda
                    },
                },

                tooltip: {
                    enabled: false // Desactivar tooltip
                },
                scales: {
                    x: {
                        display: false,
                        grid: {
                            display: false // Oculta las líneas de fondo
                        }
                    },
                    y: {
                        display: false,
                        beginAtZero: true, // Comienza el eje y desde cero
                        grid: {
                            display: false // Oculta las líneas de fondo
                        }
                    },


                },

                elements: {
                    bar: {
                        borderWidth: 0,
                        borderRadius: 2 // Establecer el radio de borde de las barras
                    }
                }

            },


            chartOptionsLine: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false // Oculta la leyenda
                    },
                },

                tooltip: {
                    enabled: false // Desactivar tooltip
                },
                scales: {
                    x: {
                        border: {
                            display: false,
                        },
                        display: true,
                        ticks: {

                            maxRotation: 0, // Establece la rotación máxima a 0 grados (horizontal)
                            minRotation: 0,
                            display: true, // Muestra las etiquetas
                            callback: function (value) {
                                // Devuelve el nombre del mes correspondiente al índice
                                return ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Agos', 'Sep', 'Oct', 'Nov', 'Dic'][value];

                            }
                        }, grid: {
                            display: false, // Oculta las líneas de fondo del eje x

                        },

                    },
                    y: {
                        display: false,
                        beginAtZero: true, // Comienza el eje y desde cero
                        grid: {
                            display: false // Oculta las líneas de fondo

                        }
                    },



                },

                elements: {
                    line: {
                        borderWidth: 2,

                    }
                }

            }
        }
    },

    computed: {
        myStyles() {
            return {
                width: `${100}%`,
                height: `${70}%`,
                position: 'relative',
            }
        },
        ...mapGetters(['getUserCompany']),

        userCompany() {
            return this.getUserCompany;
        }
    },

    created() {
        this.isLoader = true
        this.getState()
    },
    methods: {
        getState() {
            if (!this.userCompany) return
            axios.get(`/states?itemsPerPage=200&company=${this.userCompany}`).then(response => {
                this.states = this.changeTraslationDefaultState(response.data["hydra:member"])
            })
                .catch((error) => {
                    console.log(error)
                    this.errorState.isError = true
                    this.errorState.text = this.$t('general.infoMessage.GetError')

                })
                .finally(() => {
                    this.isLoader = false
                })

        }
    },

}
</script>


<template>
    <div class="dashboard">

        <h1>{{ this.$t('dashboard.title') }}</h1>
        <InfoMessage v-if="this.errorState.text && !this.isLoader" :text="errorState.text" icon="error" :isActive="true"
            color="#C74D4D" :styleDiv="{ marginBottom: 0, width: '100%' }" />

        <div class="loader" v-if="isLoader">
            <LoaderComponent />
        </div>
        <!--Data Boxes-->
        <div class="data-boxes">


            <DataBox v-for="state in states" :key="state.id" :title="state.name" sign="+" :periodNumber="3"
                :period="this.$t('dashboard.data-box.week')" :total="24" status="new" :color="state.color" />

            <div class="widget">
                <DataBox :title="this.$t('dashboard.widget.assigned.title')" sign="+" :periodNumber="32"
                    :period="this.$t('dashboard.data-box.week')" :total="5" status="clear" />
            </div>

            <div class="widget ">
                <h2>{{ this.$t('dashboard.widget.target.title') }}</h2>
                <p><span class="number">23</span>{{ this.$t('dashboard.widget.target.caseFile') }}</p>

            </div>

            <div class="widget">
                <h2>{{ this.$t('dashboard.widget.response-time.title') }}</h2>
                <p><span class="number">5</span>{{ this.$t('dashboard.widget.response-time.days') }}</p>

            </div>
        </div>

        <div class="wrapper">
            <!--File table-->
            <div class="table-data">
                <div class="file-table">
                    <FileTable v-if="this.userCompany" :tableColumnsData="tableColumnsData" />
                </div>

                <div class="widgets">

                    <div class="widget assigned">
                        <DataBox :title="this.$t('dashboard.widget.assigned.title')" sign="+" :periodNumber="32"
                            :period="this.$t('dashboard.data-box.week')" :total="5" status="clear" />
                    </div>

                    <div class="widget goals">
                        <h2>{{ this.$t('dashboard.widget.target.title') }}</h2>
                        <span class="number">23</span>
                        <p>{{ this.$t('dashboard.widget.target.caseFile') }}</p>
                    </div>

                    <div class="widget response-time">
                        <h2>{{ this.$t('dashboard.widget.response-time.title') }}</h2>
                        <p><span class="number">5</span> {{ this.$t('dashboard.widget.response-time.days') }}</p>

                    </div>
                </div>
            </div>


            <!--Charts-->
            <div class="charts">

                <div class="chart">
                    <h2>Expedientes | Mes</h2>
                    <Line :style="myStyles" id="asdas" :options="chartOptionsLine" :data="chartDataLine" />
                </div>


                <div class="chart">
                    <h2>Expedientes | Categoría</h2>
                    <Bar :style="myStyles" id="my-chart-id" :options="chartOptions" :data="chartData" />
                </div>




            </div>
        </div>

    </div>
</template>



<style scoped lang="scss">
.loader {
    display: flex;
    justify-content: center;
}

.dashboard {
    width: 100%;

    .breadcrumb {
        margin-top: 36px;
    }

    h1 {
        text-align: left;
        margin: 0 0 24px 0;
        font-size: 30px;
    }

    .data-boxes {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(312px, 1fr));
        gap: 32px;
        margin-bottom: 32px;
        overflow: auto;
        max-height: 270px;

        @media(max-width: 500px) {
            grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
        }



        .data-box {
            margin-right: 0;
        }

        .data-box:last-child {
            margin-right: 0;
        }

        .widget {
            display: none;

            span.number {
                margin-right: 5px;
            }
        }
    }

    .table-data {
        width: 100%;
        display: inline-flex;
        gap: 32px;


        .file-table {
            width: 70%;


            h2 {
                text-align: left;
                font-size: 16px;
                font-family: 'interSemiBold', sans-serif;
                color: #B3BBBC;
            }

            .table {
                max-height: 425px;
            }


        }

        .widgets {
            width: 30%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: flex-start;
        }

        .widget.assigned {
            width: 100%;
            background: none;
            margin-top: 0;

        }

        .widget {
            background: #FFFFFF;
            width: 45%;
            min-height: 113px;
            margin-top: 32px;
            border-radius: 8px;

            h2 {
                font-size: 16px;
                font-family: 'interSemiBold', sans-serif;
                color: #B3BBBC;
                margin-bottom: 10px;
            }

            p {
                font-size: 18px;
                font-family: 'interSemiBold', sans-serif;
            }

            span.number {
                font-size: 36px;
                font-family: 'interSemiBold', sans-serif;


            }

        }

        .widget:nth-child(2) {
            margin-right: 32px;
        }


    }

    .charts {
        width: 100%;
        margin-top: 32px;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;




        .chart {

            margin-right: 38px;
            min-height: 212px;

            h2 {
                font-size: 16px;
                text-align: left;
                color: #B3BBBC;
                font-family: 'interSemiBold', sans-serif;
                margin-bottom: 20px;

            }

            width: 100%;
            max-width: 526px;
            min-height: 212px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 24px;
        }
    }


    .router-link {
        color: #55737A;
        font-family: 'InterMedium', sans-serif;
        font-size: 12px;
        margin-top: 18px;
        display: block;
        text-align: left;

    }
}


/*Media queries*/
@media(max-width: 1720px) {
    .dashboard {
        .table-data {
            flex-wrap: wrap;

            .file-table {
                width: 100%;

                table {
                    max-height: 250px;

                }
            }

            .widgets {
                width: 100%;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;
                margin-top: 0;

                .widget {
                    width: 33.33%;
                    margin: 0;
                    margin-right: 32px;
                }


                .widget:last-child {
                    margin-right: 0;
                }
            }

        }

        .charts .chart {
            width: 50%;

        }

        .charts .chart:last-child {
            margin-right: 0;
        }
    }

}



@media(max-width: 768px) {
    .dashboard {
        .breadcrumb {
            display: none;
        }

        h1 {
            padding: 0;
            font-size: 24px;
            margin: 0 0 43px 0;
        }

        .data-boxes {
            margin-bottom: 24px;
            margin-right: 0px;
            gap: 12px;

            .widget.assigned {
                padding: 0;
            }

            .widget {
                background: #FFFFFF;
                border-radius: 8px;
                flex-direction: inline-flex;
                align-items: center;
                flex-wrap: wrap;
                padding: 16px;

                h2 {
                    font-size: 14px;
                    text-align: left;
                    font-family: 'interSemiBold', sans-serif;
                    color: #B3BBBC;

                    width: 100%;

                }

                p {
                    font-size: 14px;
                    display: inline;
                    font-family: 'interSemiBold', sans-serif;
                    text-align: left;
                }

                span.number {
                    display: inline;
                    font-size: 24px;
                    font-family: 'interSemiBold', sans-serif;
                    margin-left: 5px;


                }

            }

            .widget {
                display: flex;

                .data-box {
                    width: 100%;
                }
            }

        }

        .wrapper {
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
        }

        .table-data {
            margin-top: 25px;


            .widgets {
                display: none;
            }

            .table {
                min-height: 600px;
            }
        }


        .charts {
            width: 100%;
            padding: 0;
            margin-top: 0;
            flex-wrap: wrap;

            .chart {
                width: 100%;
                margin-right: 0;
                max-width: none;
            }

            .chart:first-child {
                margin-bottom: 13px;
            }

        }

        .router-link {
            text-align: center;
        }
    }
}



@media(max-width:375px) {

    .dashboard {
        .data-boxes {


            .data-box,
            .widget {
                width: 100%;
                margin-right: 0;
            }
        }
    }
}
</style>