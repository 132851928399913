<template>
    <div @mousedown="(e) => existModal(e)" class="modal" ref="modal">
        <div class="box">
         <slot></slot>
        </div>
    </div>

</template>


<script>


export default {
    name: 'TraslationsModalLayaout',
    
    methods: {
        existModal(e) {
            if (e.target.closest('.box')) return
            this.$emit('toggleModal')
        },
        
    }
}

</script>


<style scoped lang="scss">

.modal {

position: absolute;
width: 100%;
height: 100%;
background-color: rgba(22, 63, 73, 0.4);
top: 0;
left: 0;
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;

flex-direction: column;
padding-block: 20px;


.box {
    overflow: auto;
    width: 90%;
    max-width: fit-content;
    background-color: #FFFFFF;
    padding: 24px;
    border-radius: 8px;
    text-align: left;
}
}

</style>
