<template>
  <div class="progress-bar">
    <div class="progress" :style="{ width: progress + '%', backgroundColor: colorBar }"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    progress: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 100,
    },
    colorBar: String
  },
};
</script>

<style scoped>
.progress-bar {
  width: 100%;
  background-color: #e7f0f2;
  border-radius: 4px;
  overflow: hidden;
}

.progress {
  height: 6px;
  background-color: #163f49;
  border-radius: 10px;

  transition: width 0.3s ease;
}
</style>
