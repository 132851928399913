<template>
    <div class="config-input">
        <input type="number" :placeholder="placeholder" :value="value">
        <span class="text">{{ text }}</span>
    </div>
</template>

<script>
export default {
    name: 'ConfigurationInputComponent',
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        
        },
        text: {
            type: String,
            default: ''
        
        },
    },
    data() {
        return {

        };
    },

    methods: {

    }
}
</script>

<style scoped lang="scss">
.config-input {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;

    input {
        width: 99px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid #B3BBBC;
        text-align: center;
        margin-right: 16px;
        font-size: 18px;
        font-family: 'interRegular', sans-serif;
        color: #0E0E0F;
    }

    input::placeholder {
        color: #B3BBBC;
    }

    span.text {
        color: #B3BBBC;
        font-family: 'interMedium', sans-serif;
        font-size: 16px;
    }
}


/*Media queries*/
@media(max-width:768px) {
    .config-input {

        input,
        span.text {
            font-size: 12px;
        }

        input {
            width: 63px;
            height: 32px;
        }
    }
}
</style>