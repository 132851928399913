

<template>
    <main>
        <h1>{{ this.$t('notFound.title') }}</h1>
        <router-link to="/">{{  this.$t('notFound.return') }}</router-link>
    </main>


</template>

<script>
export default {
    name: 'NotFound'}


</script>

<style scoped lang="scss">
h1{
    color: #fff;
    font-size: clamp(1.5em, 5vw, 2em);
}

a{
    color: #E7F0F2;
    text-decoration: underline;
    text-underline-offset: 5px;
    transition: text-decoration-color .3s ease-in-out;
}

a:hover{
    text-decoration-color: #94A7AA;
}

@media(max-width: 650px) {
    main{
        margin-block: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
  }

</style>