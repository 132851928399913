<template>
    <div v-if="isModalDeparment" @mousedown="(e) => existModal(e)" class="modal" ref="modal">
        <div class="box">

            <InfoMessage v-if="infoMessageData.active" :styleDiv="{ margin: 0 }" :isActive="infoMessageData.active"
                :text="infoMessageData.messageText" :icon="infoMessageData.messageIcon ? 'info' : 'error'"
                :color="!infoMessageData.messageColorDefault ? '#C74D4D' : ''" />

            <div class="title">
                <h2 v-if="!editDeparment.isEdit">{{ this.$t('company.create-editCompany.department.modal.titleCreate') }}</h2>
                <h2 v-else>{{ this.$t('company.create-editCompany.department.modal.titleEdit') }}</h2>
                <button type="button" class="desk cancel-btn" @click="toggleModalDepartment">{{ this.$t('general.buttons.cancel') }}</button>
            </div>


            <form @submit.prevent="actionBtn">
                <div class="inputs">
                    <div class="container-input">
                        <GeneralInput :vModel="name.value" :error="this.isBlankInput"
                            :errorMessage="this.$t('general.infoMessage.fillElement')" :labelName="this.$t('company.create-editCompany.department.modal.input')"
                            labelFor="Nombre departamento" @update-value="this.name.value = $event;"
                            class="input-text" />
                    </div>
                    <div class="container-select">
                        <GeneralSelect :styleGeneralDiv="{ width: '200px' }"
                            :styleCustomSelect="{ display: 'flex', height: '100%', }"
                            :styleSelect="{ height: '100%', maxHeight: '55px', minHeight: '40px' }" name="language-2"
                            id="language-2" :options="this.languageOptions" :error="this.isBlankSelect"
                            :optionSelected="this.language.value" @optionChanged="this.language.value = $event;"
                            :errorMessage="this.$t('general.infoMessage.fillElement')" :placeholder="this.$t('general.inputElements.language.title')"
                            :label="this.$t('general.inputElements.language.title')" class="body-select" />
                    </div>
                </div>
                <div class="buttons">
                    <button type="button" class="mobile cancel-btn" @click="toggleModalDepartment">{{ this.$t('general.buttons.cancel') }}</button>
                    <button :disabled="isDisabled" type="submit" class="action-btn select-action-btn"
                        :class="isDisabled && 'isDisabled'">
                        <VueSpinnerToBtn ref="spinnerBtn"> {{ editDeparment.isEdit ?
                            this.$t('company.create-editCompany.department.modal.buttonEdit')  : this.$t('company.create-editCompany.department.modal.buttonCreate') }}</VueSpinnerToBtn>
                    </button>
                </div>

            </form>
        </div>
    </div>

</template>

<script>
import GeneralInput from '@/components/forms/Input.vue';
import GeneralSelect from '@/components/forms/Select.vue';
import InfoMessage from './InfoMessage.vue';
import axios from 'axios';
import VueSpinnerToBtn from './VueSpinnerToBtn.vue';


export default {
    name: 'ModalDepartment',
    components: {
        GeneralInput,
        GeneralSelect,
        InfoMessage,
        VueSpinnerToBtn
    },
    data() {
        return {
            apiPrefix: process.env.VUE_APP_API_PREFIX,
            isDisabled: false,
            isModalDeparment: true,
            infoMessageData: {
                active: false,
                messageIcon: true,
                messageText: '',
                messageColorDefault: true,
            },
            name: {
                value: '',
                error: false
            },
            language: {
                value: '',
                error: false
            },
            isBlankInput: false,
            isBlankSelect: false

        }
    },
    props: {
        languageOptions: {
            type: Array
        },
        editDeparment: {
            type: Object
        },
        editMode: {
            type: Boolean,
            required: true
        },
        companyId: {
            type: String || null
        }
    },
    mounted() {

        if (this.editDeparment.isEdit) {
            this.name.value = this.editDeparment.name
            this.language.value = this.editDeparment.language.value

           
        
        }
 
    },
    watch: {
        name: {
            handler(newVal) {
                if (this.isBlankInput) { this.isBlankInput = false }

                if (!this.infoMessageData.active) return
                if (newVal.value !== '') {
                    this.defaultMessage()
                }

            },
            deep: true
        },
        language: {
            handler(newVal) {
                if (this.isBlankSelect) { this.isBlankSelect = false }

                if (!this.infoMessageData.active) return
                if (newVal.value !== '') {
                    this.defaultMessage()
                }

            },
            deep: true
        }

    },
    methods: {
        defaultMessage() {
            this.infoMessageData.active = false
            this.infoMessageData.messageIcon = true
            this.infoMessageData.messageText = ''
            this.infoMessageData.messageColorDefault = true

        },
        actionBtn() {
            this.isDisabled = true
            this.defaultMessage()
            let isBlank = false
            if (this.language.value === '') {
                isBlank = true
                this.isBlankSelect = true
            }
            if (this.name.value === '') {
                isBlank = true
                this.isBlankInput = true
            }
            if (isBlank) {
                this.isDisabled = false
                return
            }

            this.$refs.spinnerBtn.activeSpinner()


            this.editDeparment.isEdit ? this.editExistedDepartment() : this.addDepartment()
        },
        editExistedDepartment() {

            if (this.editMode) {
                axios.patch(`/departments/${this.editDeparment.deparmentId}`, {
                    name: this.name.value,
                    language: this.language.value
                })
                    .then(() => {
                        this.$emit('getDepartments')
                        this.infoMessageData.active = true
                        this.infoMessageData.messageText = this.$t('general.infoMessage.editCorrectly')
                    })
                    .catch((error) => {
                        this.infoMessageData.active = true
                        this.infoMessageData.messageText = this.$t('general.infoMessage.generalError')
                        this.infoMessageData.messageIcon = false
                        this.infoMessageData.messageColorDefault = false
                        console.log(error)
                    })
                    .finally(() => {
                        this.isDisabled = false
                        this.$refs.spinnerBtn.desactiveSpinner()
                    })

            } else {

                let parsedLanguage = this.languageOptions.find((language) => {
                    return language.value === this.language.value;
                });

                this.$store.dispatch('editDepartment', {
                    name: this.name.value,
                    language: parsedLanguage,
                    index: this.editDeparment.index
                });

                this.infoMessageData.active = true
                this.infoMessageData.messageText = this.$t('general.infoMessage.editCorrectly')
                this.isDisabled = false
                this.$refs.spinnerBtn.desactiveSpinner()
            }
        },
        toggleModalDepartment() {
            this.$emit('toggle')
            this.$emit('setEditDeparmentToFalse')
        },
        existModal(e) {
            if (e.target.closest('.box')) return
            this.toggleModalDepartment()
        },
        addDepartment() {
            let parsedLanguage = this.languageOptions.find((language) => {
                return language.value === this.language.value;
            });


            if (this.editMode) {

                axios.post(`/departments`, {
                    name: this.name.value,
                    company: `${this.apiPrefix}/companies/${this.companyId}`,
                    language: this.language.value
                })
                    .then(() => {
                        this.$emit('getDepartments')
                        this.infoMessageData.active = true
                        this.infoMessageData.messageText = this.$t('general.infoMessage.saveCorrectly')
                        this.name.value = ''
                        this.language.value = ''


                    })
                    .catch((error) => {
                        this.infoMessageData.active = true
                        this.infoMessageData.messageText = this.$t('general.infoMessage.generalError')
                        this.infoMessageData.messageIcon = false
                        this.infoMessageData.messageColorDefault = false
                        console.log(error)
                    })
                    .finally(() => {
                        this.isDisabled = false
                        this.$refs.spinnerBtn.desactiveSpinner()
                    })


            } else {
                this.$store.dispatch('addDepartment', {
                    name: this.name.value,
                    language: parsedLanguage

                });
                this.infoMessageData.active = true
                this.infoMessageData.messageText =  this.$t('general.infoMessage.saveCorrectly')
                this.name.value = ''
                this.language.value = ''
                this.isDisabled = false
                this.$refs.spinnerBtn.desactiveSpinner()
            }





        }

    }


}


</script>


<style scoped lang="scss">
button {
    cursor: pointer;
}

.mobile {
    display: none;
}

.desk {
    display: block
}

.container-input {
    width: 100%;
}

.container-select {
    display: flex;
}

.modal {

    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 63, 73, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: column;
    padding-block: 20px;


    .box {
        overflow: auto;
    }

    .box,
    form {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .box {
        width: 90%;
        background-color: #FFFFFF;
        padding: 24px;
        border-radius: 8px;
        text-align: left;
        max-width: 750px;

    }

    .inputs {
        display: flex;
        align-items: stretch;
        gap: 24px;
    }

    h2 {
        margin: 0;
    }

    .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }



    .cancel-btn {
        color: #163F49;
        font-size: 16px;
        font-family: 'InterMedium';
        border: 1px solid #163F49;
        background-color: transparent;
        border-radius: 4px;
        padding: 7px 14px;

    }

    .isDisabled {
        cursor: wait !important;
    }

    .action-btn {
        background-color: #163F49;
        color: #F9FBFC;
        border-radius: 4px;
        border: none;
        padding-inline: 20px;
        display: block;
        width: 100%;
        height: 48px;
        margin-left: auto;

        @media (max-width: 300px) {
            padding-inline: 10px;
        }




    }
}

@media (max-width: 570px) {

    .mobile {
        display: block;
    }

    .desk {
        display: none
    }

    .buttons {
        display: flex;
        gap: 19px;
    }

    .modal {
        .box {
            width: 100%;

            .inputs {
                flex-direction: column;
            }
        }
    }
}
</style>