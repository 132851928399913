export default {
    mounted() {
        document.addEventListener("mouseup", this.handleSelectedText);
    
    },
    beforeUnmount() {
        document.removeEventListener("mouseup", this.handleSelectedText);
    },
    methods: {
        prevenirEnter(event) {
            // Verifica si la tecla presionada es Enter
            if (event.key === 'Enter') {
              event.preventDefault();  // Prevenir la acción predeterminada de Enter
            }
          },
        async handlePaste(event) {
            event.preventDefault(); // Prevenir la acción por defecto del pegado
            
            // Obtener el texto plano del portapapeles
            const clipboardData = await navigator.clipboard.readText();
      
            // Insertar el texto plano en el div
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            range.deleteContents(); // Elimina el contenido actual en el rango seleccionado
            range.insertNode(document.createTextNode(clipboardData)); // Inserta el nuevo texto
            const editableDiv = this.$refs.textInput
            this.verification.value = editableDiv.toString()

          },
        updateValue() {
            const editableDiv = this.$refs.textInput
            this.verification.value = editableDiv.textContent
     


        },
    
        handleSelectedText() {
            if(!this.isVerificationMouseDown) return
            this.isVerificationMouseDown = false

            this.selectionSomething = false
            this.verification.linkButton = false;
            const selection = window.getSelection();
            //si no se seleccionada nada 
            if (selection.isCollapsed) return;
      
            this.selectionSomething  = true
            const selectedText = selection.toString();
            const range = selection.getRangeAt(0);
            //para abrir el menu de
            this.verification.linkButton = true;

            //
            const { startContainer, startOffset } = range;
            const textContainer = this.$refs.textInput;
            let textIndex = 0;

            // Función recursiva para calcular el índice del texto
            calculateIndex(textContainer);
            function calculateIndex(node) {
                if (node === startContainer) {
                    // Estamos en el nodo donde comienza la selección
                    textIndex += startOffset;
                    return true;
                }

                if (node.nodeType === Node.TEXT_NODE) {
                    // Si el nodo es un nodo de texto, suma su longitud
                    textIndex += node.textContent.length;
                } else if (node.nodeType === Node.ELEMENT_NODE) {
                    // Si es un nodo de elemento, recorrer sus hijos
                    for (let child of node.childNodes) {
                        if (calculateIndex(child)) return true;
                    }
                }

                return false;
            }


            //guardamos el inicio y final de los indices
            this.selectedIndex = {
                firstIndex: textIndex,
                lastIndex: textIndex + selectedText.length - 1
            }


            //necesitamos la siguiente función esto para guardar la referencia de  los link que ha sido seleccionados para luego convertir 
            //tanto los link como el texto en un único link
            this.linksReference = this.insertOnlyLinksInsideOfSelected(range)

       

        },
        insertOnlyLinksInsideOfSelected(range) {
            const links = [];
            // Crear un TreeWalker para recorrer los nodos de texto en el rango seleccionado
            const treeWalker = document.createTreeWalker(
                range.commonAncestorContainer,
                NodeFilter.SHOW_TEXT,
                {
                    acceptNode: (node) => {
                        // Acepta solo los nodos de texto que están dentro del rango seleccionado
                        return range.intersectsNode(node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
                    }
                }
            );

            let currentNode = treeWalker.nextNode();
            if (currentNode) {

                while (currentNode) {

                    // Chequea si el nodo actual es hijo directo de un link

                    if (currentNode.parentNode.tagName.toLowerCase() === 'a') {
                        links.push(currentNode);

                    }
                    currentNode = treeWalker.nextNode();
                }
            } else {
                //doble verificación para añadir la referencia de los links si los hay porque a veces el currentNode no los detecta si hay texto y link
                let exist = false
                if (range.startContainer.parentNode.tagName.toLowerCase() === 'a') {
                    links.push({ data: range.startContainer.parentNode.innerText });
                    exist = true

                }
                if (!exist && range.endContainer.parentNode.tagName.toLowerCase() === 'a') {
                    links.push({ data: range.endContainer.parentNode.innerText });

                }
            }
            return links

        },
        addLink() {
            if(!this.selectionSomething) {
                this.verification.errorText = 'Debe seleccionar lo que desea convertir en link'
                this.verification.error = true
                if(this.isMobile) {
                    this.scrollToInfoMessage()
                }
                setTimeout(() => {
                    this.verification.error = false
                }, 1500)
                return
            }
            this.verification.error = false
            if (this.linkHref === '') {
                this.verification.errorText = 'Debe haber un link escrito'
                this.verification.error = true
                if(this.isMobile) {
                    this.scrollToInfoMessage()
                }
                setTimeout(() => {
                    this.verification.error = false
                }, 1500)
                return
            }
            const editableDiv = this.$refs.textInput
            const estilosLink = {
                color: '#C74D4D',
                'text-decoration': 'underline'
            }

            //formatear los estilos para poder aplicarlos
            const estilosLinkStr = Object.entries(estilosLink)
                .map(([key, value]) => `${key}: ${value}`)
                .join('; ');

            //se pone la refencia en forma de texto de los links seleccionador para luego poder quitar su etiqueta y convertirlos en texto, para posteriormente crear un único link
            const textLinksReferenceArray = this.linksReference.map(link => link.data)

            //se añade a textLinksReferenceArray todo depurado, lo seleccionado es texto (independientemente de si es un link o no) y el resto sigue igual, si había texto sigue siendo texto y si había links siguen siendo links
            const textNodesArray = this.depuredTextInArray(textLinksReferenceArray, editableDiv, estilosLinkStr)

            //ahora hacemos que se junten todos los textos que estaban suelto, de forma que siempre tendremos una cadena de texto + link (si hubiera link)
            //o link + texto + link + texto, o link + link + texto, pero nunca dos texto separados en posición anterior o posterior del array
            const nuevoArray = this.joinSeparateText(textNodesArray)

            //modificamos el DOM con el nuevo link
            
            
            const newValue = this.insertLinkInSeletectedText(nuevoArray, estilosLinkStr);
           
            
            const sanitizedHTML = this.sanitizeHTMLOnlyAllowA(newValue);
        
            editableDiv.innerHTML = sanitizedHTML
            this.verification.value = sanitizedHTML.toString()
            this.linkHref = ''
            
        },
        depuredTextInArray(textLinksReferenceArray, editableDiv, estilosLinkStr) {
            const textNodesArray = []
            Array.from(editableDiv.childNodes).forEach(node => {
                if (node.nodeName === 'A') {
                    if (textLinksReferenceArray.includes(node.textContent)) {
                        // si el link está dentro de la selección cogemos solamente su texto
                        textNodesArray.push(node.textContent)
                    } else {
                        //si el link existe, pero no es de los seleccionados lo dejamos igual
                        textNodesArray.push(
                            {
                                cotenido: node.textContent,
                                link: `<a target = _blank  href="${node.getAttribute('href')}" style="${estilosLinkStr}">${node.textContent}</a>`
                            }
                        )
                    }

                } else {
                    // guardamos su texto en el array
                    textNodesArray.push(node.textContent)
                }
            }
            );
            return textNodesArray
        },
        joinSeparateText(textNodesArray) {
            const nuevoArray = []
            for (let i = 0; i < textNodesArray.length; i++) {
                if (typeof textNodesArray[i] === 'string') {
                    let combinedString = textNodesArray[i];
                    // Combina strings consecutivos
                    while (typeof textNodesArray[i + 1] === 'string') {
                        combinedString += textNodesArray[i + 1];
                        i++; // Incrementa el índice para saltar al siguiente elemento
                    }
                    nuevoArray.push(combinedString);
                } else {
                    // Si no es un string, simplemente agrega el objeto al nuevo array
                    nuevoArray.push(textNodesArray[i]);
                }
            }
            return nuevoArray
        }
        ,
        insertLinkInSeletectedText(nuevoArray, estilosLinkStr) {
            let startPos1 = this.selectedIndex.firstIndex;
            let endPos1 = this.selectedIndex.lastIndex;
            let loking = 0
            let notLooking = false

            //calculamos la posición y añadimos en la posición el nuevo link
            const nuevo = nuevoArray.map(element => {
                if (typeof element !== 'string') {
                    loking += element.cotenido.length
                    return element.link
                } else {

                    if (notLooking) { return element } else {
                        let prevLooking = loking + element.length
                        if (prevLooking >= startPos1) {

                            let placeToChange = startPos1 - loking
                            let endPlaceToChange = endPos1 - startPos1

                            const newWelement = element.slice(0, placeToChange) +
                                `<a href="${this.linkHref}" target = _blank  style="${estilosLinkStr}">` +
                                element.slice(placeToChange, (placeToChange + endPlaceToChange + 1)) +
                                '</a>' +
                                element.slice(placeToChange + endPlaceToChange + 1);
                            notLooking = true
                            return newWelement
                        } else {
                            loking = prevLooking
                            return element
                        }

                    }
                }
            }).join('')
            return nuevo
        },
        eliminateLink() {
            const editableDiv = this.$refs.textInput
            let newDivText = ''
            Array.from(editableDiv.childNodes).forEach(node => newDivText += node.textContent)
            editableDiv.textContent = newDivText
            this.verification.value = newDivText
        },
        sanitizeHTMLOnlyAllowA(html) {
            // Crear un contenedor temporal para contener el HTML
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = html;
   
            // Reemplazar todos los elementos que no sean <a> con su texto correspondiente
            const elements = tempDiv.querySelectorAll("*");
            elements.forEach((el) => {
                if (el.tagName.toLowerCase() !== "a") {
                    // Reemplazar elementos no permitidos con texto, escapando su contenido
                    const textNode = document.createTextNode(el.outerHTML);
                    el.replaceWith(textNode);
                } else {
                    // Sanitizar atributos del <a>
                    this.sanitizeAttributes(el);
                }
            });
          
            return tempDiv.innerHTML;
        }
        ,
        sanitizeAttributes(el) {
            // Mantener solo ciertos atributos seguros en <a>
            const allowedAttrs = ['target', 'title', 'style', 'href']; // Atributos permitidos
            Array.from(el.attributes).forEach(attr => {
                if (!allowedAttrs.includes(attr.name)) {
                    el.removeAttribute(attr.name);
                } else {
                    // Sanitizar valor del atributo href para evitar javascript:
                    if (attr.name === 'href' && attr.value.startsWith('javascript:')) {
                        el.removeAttribute(attr.name);
                    }
                }
            });
        }
    }
}