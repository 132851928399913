<template>

    <div @mousedown="(e) => existModal(e)" class="modal">

        <form @submit.prevent="handleButton" class="box">
            <div class="title">
                <h2>{{ title }}</h2>
                <button type="button" class="desk cancel-btn" @click="this.$emit('toggleModalActive')">{{
                    this.$t('general.buttons.cancel') }}</button>
            </div>
            <div v-if="isModalLoader" class="loader">
                <LoaderComponent />
            </div>

            <div ref="infoMessage"></div>
            <InfoMessage :styleDiv="{ margin: '0' }" v-if="infoMessageData?.isActive"
                :isActive="infoMessageData?.isActive" :color="infoMessageData?.isError ? '#C74D4D' : ''"
                :text="infoMessageData?.text" :icon="infoMessageData?.isError ? 'error' : 'info'" />
            <div v-show="!isModalLoader && !infoMessageData?.isError" class="flex-container">

                <div v-if="titleActive">
                    <input :style="inputTitle.isBlank && { borderColor: '#C74D4D' }" v-model.trim="inputTitle.value"
                        class="title-element" type="text"
                        :placeholder="this.$t('caseFile.detailsCaseFile.notes.create-edit.placeholderTittle')">
                    <p class="error-p" v-if="inputTitle.isBlank">{{ this.$t('general.infoMessage.fillElement') }}</p>
                </div>

                <div class="select-div" v-if="isSelectModal">
                    <div>
                        <SelectSearchBox :error="selectErrorData" :optionArray="selectData.opciones"
                            fileDataName="selectTask" v-model:selectedValue="selectDataInfo"
                            :placeholderText="this.$t('caseFile.detailsCaseFile.task.create-edit.placeholderSelectType')"
                            :styleErrorMessage="{ marginTop: '5px' }" />
                    </div>
                    <div class="none">
                        <SelectSearchBox v-if="isSelectInstructor" :error="selectErrorInstructor"
                            :optionArray="instructorArrayUpdate" fileDataName="selectTask"
                            v-model:selectedValue="selectDataInstructor"
                            :placeholderText="this.$t('general.inputElements.instructor.placeholder')"
                            :styleErrorMessage="{ marginTop: '5px' }" />
                    </div>
                </div>

                <TextAreaWithFiles :estilosTextArea="isSelectModal ? { paddingLeft: '6px' } : {}"
                    :notFile="isSelectModal" :placeholderText="placeholderText" :isBlank="isBlank"
                    v-model.trim="textAreaData" :eliminarArcivo="eliminarArcivo" :files="files" />



                <div v-if="!isSelectModal" class="button-container">
                    <div class="file">
                        <input class="fileTextHiden  pointer" ref="fileInput" type="file" id="fileInput" multiple
                            @change="handleFileUpload">
                        <label class="pointer" for="fileInput"><img src="@/assets/icons/clip.svg"> {{
                            this.$t('general.textareaButtons.addFile') }}</label>

                    </div>
                    <div class="flex">
                        <button type="button" class="mobile cancel-btn" @click="this.$emit('toggleModalActive')">{{
                            this.$t('general.buttons.cancel') }}</button>
                        <button :disabled="isDisable" :class="isDisable && 'spinnerBtn'" type="submit"
                            class="action-btn">
                            {{ btnTitle }}
                        </button>
                    </div>
                </div>
                <div v-show="isSelectModal" class="select-modal">
                    <button :disabled="isDisable" :class="isDisable && 'spinnerBtn'" type="submit"
                        class="action-btn select-action-btn">
                        <VueSpinnerToBtn ref="spinnerToBtn"> {{ btnTitle }}</VueSpinnerToBtn>
                    </button>
                </div>
            </div>
        </form>
    </div>

</template>

<script>

import SelectSearchBox from '../forms/SelectSearchBox.vue';
import InfoMessage from './InfoMessage.vue';
import LoaderComponent from './LoaderComponent.vue';
import TextAreaWithFiles from './TextAreaWithFiles.vue';
import VueSpinnerToBtn from './VueSpinnerToBtn.vue';


export default {
    name: 'ModalAddFile',
    data() {
        return {
            inputTitle: {
                value: '',
                isBlank: false
            },
            isDisable: this.isDisableBtn,
            files: [],
            textAreaData: '',
            isDocument: false,
            isBlank: false,
            count: 0,
            selectDataInfo: '',
            selectErrorData: false,
            selectDataInstructor: '',
            selectErrorInstructor: false,
            instructorArrayUpdate: this.selectData?.intructores?.length > 0 ? this.selectData.intructores.map(instructor => ({ name: instructor?.label, id: instructor?.value })) : []
        }
    },
    components: { TextAreaWithFiles, InfoMessage, SelectSearchBox, LoaderComponent, VueSpinnerToBtn },
    props: {
        isDisableBtn: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        btnTitle: {
            type: String,
            required: true
        },
        placeholderText: {
            type: String
        },
        elemntData: {
            type: Object
        },
        selectedEditData: {
            type: Object
        },
        titleActive: {
            type: Boolean,
            default: false
        },
        isEditActive: {
            type: Boolean,
            default: false
        },
        infoMessageData: {
            type: Object
        },
        cleanInforMessage: {
            type: Function
        },
        isSelectModal: {
            type: Boolean,
            default: false
        },
        selectData: {
            type: Object,
            required: false,
        },
        isModalLoader: {
            type: Boolean
        },
        isSelectInstructor: {
            type: Boolean,
            default: true
        },
        isNeedDocument: {
            type: Boolean,
            default: false
        },
        sendMessage: {
            type: Boolean,
            default: false
        },
        isInformant: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.cleanInforMessage()
    },
    watch: {
        isDisableBtn(newValue) {
            this.isDisable = newValue
        },
        'infoMessageData.isActive'(newValue) {
            if (newValue) {

                this.$nextTick(() => {
                    const element = this.$refs.infoMessage;
                    const modalBox = this.$el.querySelector('.box'); // contenedor con overflow

                    if (element && modalBox) {
                        modalBox.scrollTo({
                            top: element.offsetTop,
                            behavior: 'smooth'
                        });
                    }
                });
            }
        },
        elemntData(newValue) {
            this.textAreaData = newValue.description
            this.inputTitle.value = newValue.name
        },
        selectedEditData(newValue) {
            this.inputTitle.value = newValue.inputTitle
            this.selectDataInfo = newValue.option
            this.selectDataInstructor = newValue.instructor
            this.textAreaData = newValue.description
        },
        'inputTitle.value'(newV) {
            if (this.inputTitle.isBlank) {
                if (newV !== '') {
                    this.inputTitle.isBlank = false

                }
            }
            if (newV !== '') {
                this.deleteMessage()
            }
        },
        textAreaData(newV) {
            if (this.isBlank) {
                if (newV !== '') {
                    this.isBlank = false
                }
            }

            this.deleteMessage()
        },
        selectDataInfo(newV) {
            if (this.selectErrorData && newV !== '') {
                this.selectErrorData = false
            }

            this.deleteMessage()
        },
        selectDataInstructor(newV) {
            if (this.selectErrorInstructor && newV !== '') {
                this.selectErrorInstructor = false
            }

            this.deleteMessage()
        }
    },
    methods: {
        activeSpinner() {
            this.$refs.spinnerToBtn.activeSpinner()
        },
        desactiveSpinner() {
            this.$refs.spinnerToBtn.desactiveSpinner()
        },
        handleFileUpload(event) {
            const files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                this.files.push(files[i]);
            }

        },
        handleButton() {
            if (!this.isSelectModal) {
                let exist = false
                if (this.textAreaData === '') {
                    this.isBlank = true
                    exist = true
                }
                if (this.inputTitle.value === '' && !this.sendMessage) {
                    this.inputTitle.isBlank = true
                    exist = true
                }

                if (exist) return

                if (this.sendMessage) {
                    this.$emit('handleButton', { textArea: this.textAreaData, isDocumentAdded: this.isDocument })
                }
                else if (!this.titleActive) {
                    this.$emit('handleButton', this.textAreaData)
                } else {

                    this.$emit('handleButton', { textArea: this.textAreaData, inputTitle: this.inputTitle.value, isDocumentAdded: this.isDocument })
                }


            } else {
                let checkIsBlanck = false
                if (this.textAreaData === '') {
                    this.isBlank = true
                    checkIsBlanck = true
                }

                if (this.inputTitle.value === '') {
                    this.inputTitle.isBlank = true
                    checkIsBlanck = true
                }

                if (this.selectDataInfo === '') {
                    this.selectErrorData = true
                    checkIsBlanck = true
                }
                if (this.isSelectInstructor) {
                    if (this.selectDataInstructor === '') {
                        this.selectErrorInstructor = true
                        checkIsBlanck = true
                    }
                }



                if (checkIsBlanck) return
                this.$emit('handleButton', { description: this.textAreaData, inputTitle: this.inputTitle.value, state: this.selectDataInfo, instructor: this.selectDataInstructor })
            }

            this.count = 0


        },
        eliminarArcivo(file,) {
            this.files = this.files.filter(f => f !== file);
        },
        existModal(e) {

            if (e.target.closest('.box')) return

            //Estos dos son por si se implementa el SelectSearchBox para que no te saque al tocal el placeholder
            if (e.target.closest('.multiselect__placeholder') || e.target.closest('.multiselect__single')) return

            this.$emit('toggleModalActive')

        },
        incrementCount() {
            this.count++
        },
        setCount(number) {
            this.count = number
        },
        countToCero() {
            this.count = 0
        },
        cleanTextArea() {
            this.textAreaData = ''
        },
        cleanInputTitle() {
            this.inputTitle.value = ''
        },
        cleanIsDocument() {
            this.isDocument = false
        },
        cleanAll() {
            this.textAreaData = ''
            this.selectDataInfo = ''
            this.selectDataInstructor = ''
            this.inputTitle.value = ''
            this.isDocument = false
        },
        deleteMessage() {
            let contador = 1
            if (this.isSelectModal) { contador = 3 }

            if (this.infoMessageData?.isActive) {
                this.count >= contador ? this.cleanInforMessage() : this.count++
            }
        }


    },

}

</script>

<style scoped lang="scss">
.none {
    display: none;
}

button {
    cursor: pointer;
}

h2 {
    margin-top: 0;
}

.loader {
    margin-inline: auto;
}

.desk {
    display: block;
}

.mobile {
    display: none;
}

.check-box {
    min-width: 20px;
    min-height: 20px;
    border-radius: 4px;
    border: 1px solid var(--Gris--1-Dedavara, #B3BBBC);
    appearance: none;
    cursor: pointer;

    &:checked {
        background-color: #163F49;
    }
}


.modal {

    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 63, 73, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: column;
    padding-block: 20px;


    .box {
        overflow: auto;
        width: 90%;
        max-width: 541px;
        background-color: #FFFFFF;
        padding: 24px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    .flex-container {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }
}

.label-check {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-block: 5px;

    p {
        cursor: pointer;
        font-family: 'InterRegular', sans-serif;
    }
}

.error-p {
    color: #C74D4D;
    text-align: left;
    margin-top: 10px;
    font-family: "InterRegular", sans-serif;
    font-weight: 400;
}

.title-element {
    cursor: pointer;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 1.25px solid #B3BBBC;
    font-size: 16px;
    color: #0E0E0F;
    font-family: "InterRegular";
    line-height: 16.94px;
    padding-left: 22px;
    padding-right: 15px;

    &::placeholder {

        font-size: 16px;
    }


}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    h2 {
        color: #0E0E0F;
        font-size: 20px;
        font-family: 'InterMedium';
        margin: 0;
    }


}

.cancel-btn {
    color: #163F49;
    font-size: 16px;
    font-family: 'InterMedium';
    border: 1px solid #163F49;
    background-color: transparent;
    border-radius: 4px;
    padding: 7px 14px;
}



.button-container {
    display: flex;
    justify-content: space-between;

    gap: 10px;



    input,
    button {
        font-family: 'InterRegular';
        cursor: pointer;


    }

    .file {
        position: relative;
        border: none;
        background-color: #CBDEE2;
        border-radius: 8px;
        color: #163F49;
        line-height: 19.36px;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 8px;

        label {
            padding: 14px;
            cursor: pointer;
        }
    }


}

.action-btn {

    background-color: #163F49;
    color: #F9FBFC;
    border-radius: 4px;
    width: fit-content;
    border: none;
    height: 100%;
    padding-inline: 20px;

}

.select-action-btn {
    width: 100%;
    height: 48px;
}

.spinnerBtn {
    cursor: wait !important;
}

.file,
.file>label {
    display: flex;
    align-items: center;
}

.file>label {
    gap: 8px;
    color: #163F49;
    line-height: 19.36px;
    font-size: 16px;
    font-family: 'InterMedium';
    font-weight: 500;
}

.fileTextHiden {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    border: 0;
}

.fileTextHiden:focus+label {
    border: 2px solid #0E0E0F !important;
    /* Add an orange line on focus */
    border-radius: 8px;
}

.textarea-select {
    resize: none;
    width: 100%;
    height: 373px;
    border: 1.25px solid #B3BBBC;
    border-radius: 8px;
    line-height: 20.8px;
    color: #0E0E0F;
    font-size: 16px;
    font-family: 'InterRegular';
    padding: 18px 31px 18px 24px;
}

.select-modal {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.select-div {
    display: flex;
    gap: 14px;

    div {
        width: 100%;
    }
}

@media (max-width: 580px) {

    .select-div {
        display: flex;
        flex-direction: column;

        div {
            width: 100%;
        }
    }

    .modal {
        .box {
            width: 100%;
        }
    }

    .desk {
        display: none;
    }

    .mobile {
        display: block;
    }

    .button-container {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .flex {
            display: flex;
            justify-content: space-between;
            gap: 5px;


        }

        .action-btn {
            height: auto;
        }


        .file>label {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }

}


@media (max-width: 420px) {
    .button-container {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .flex {
            display: flex;
            flex-direction: column;
            gap: 14px;
        }

        .action-btn {
            min-height: 32px;
            width: 100%;
            padding-block: 5px;

        }
    }
}
</style>