
<template>
    <input :disabled="isDisabled" :class="{'disableElement': isDisabled}"   :style="checkStyle" type="checkbox"  v-model="isChecked" name="check-complaint"  @change="handleChange" :id="labelIdenty">
</template>


<script>
export default {
    name: 'CheckboxComponent',
    props: {
      modelValue: {
        type: Boolean,
        default: false
      },
      labelIdenty: String,
      checkStyle: Object,
      isDisabled: {
        type: Boolean,
        default: false
      },
      manualChange: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      modelValue(newValue){
        this.isChecked = newValue
      }
    },
    data(){
      return{
        isChecked: this.modelValue
      }
    },
  methods: {
    handleChange(event) {
      if(this.manualChange) {
        //porque por alguna razon en usuarios y formularios coge los valores, pero el event.target.checked es siempre false
        this.isChecked = !this.isChecked
      }
      this.$emit('update:modelValue', event.target.checked);
    },
    setCheckboxTrue(){
        this.isChecked = true
    },
    setCheckboxFalse(){
        this.isChecked = false
    }
  },
 

}
</script>
 
<style scoped lang="scss">

.disableElement{
  cursor: not-allowed !important;
}
    input[type="checkbox"] {
        min-width: 16px;
        min-height: 12px;
        border-radius: 4px;
        border: 1px solid var(--Gris--1-Dedavara, #B3BBBC);
        appearance: none;
        cursor: pointer;
    }

    input[type="checkbox"]:checked {
        background-color: #163F49;
    }

@media (max-width: 450px) {

}

</style>