<template>

    <CreateAndEditLayaout :name="name" :path="path" :breadcrumbs="breadcrumbs" />

</template>


<script>
import CreateAndEditLayaout from '@/components/general/CreateAndEditLayaout.vue'

export default {
    name: 'NewCategory',
    components: { CreateAndEditLayaout },
    data() {
        return {
            name: {
                button: this.$t('categories.create-edit.buttonCreate'),
                title: this.$t('categories.create-edit.titleCreate')
            },
            path: '/categories',
            breadcrumbs: [{ label: this.$t('categories.create-edit.breadcrumbs.first'), to: '/categorias' }, { label: this.$t('categories.create-edit.breadcrumbs.second-create'), to: '/categorias/crear-categoria' }],
        }
    }

}
</script>
