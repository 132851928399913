<template>
    <div class="template">
        <div class="title">
            <generalTitle :title="descriptionName.title" :breadcrumbs="breadcrumbs" @goBack="this.goBack()"
                :root="root" />
            <div v-if="searchOption" class="search">
                <InputSearch @search="this.handleSearch($event)" />
            </div>
            <div class="new-delete" v-if="isButtonsActive">
                <div class="new-add" v-if="!isUnlocked">
                    <router-link class="new-add-button" :to="dbUrl.pathToCreate">{{ descriptionName.buttonAddName
                        }}</router-link>
                </div>
                <button class="delete-elements" :style="deleteStyle" v-if="selectedElements.length > 0"
                    @click="toggleModalMultiple">{{
                        this.$t('general.buttons.delete') }}</button>
            </div>
        </div>

    

        <div class="table">
            <div class="loader-div">
                <Loader v-if="isLoading" />
            </div>

            <TableComponent v-if="!isLoading" :elementArray="elementsCopy" @elementSelected="addSelectedElement"
                :tableColumns="tableColumns" :isElementCreated="isElementCreated" :toggleModalSimple="toggleModalSimple"
                @changeorder="changeorder" @addAllElements="addAllElements" :editPath="dbUrl.pathToEdit"
                :tableInfoMessage="tableInfoMessage" ref="tableComponent"  />
            <div class="pagination">
                <PaginationComponent :orderAttr="orderAttr" @data-fetched="handleDataFetched" ref="paginationComponent"
                    :searchValue="categorySearch" :path="dbUrl.path" :order="order" :getPath="getPath"
                   />
            </div>
        </div>
    </div>
    <DeleteModalTable v-if="isModalActive" ref="DeleteModalTable" :selectedElements="selectedElements" :option="option"
        :lastSingularId="lastSingularId" @toggleModal="toggleModal"
        @eliminateSelectedCategories="eliminateSelectedCategories" @cleanSelectedCategories="cleanSelectedCategories"
        @trigger-fetch="handleTriggerFetch" :path="dbUrl.path" :deleteModalText="deleteModalText"
        @checkboxToFalse="checkboxToFalse" 
        :lastSingularName="selectedFullNameForDelete" />
    <!--TODO cambiar nombres de categorias por algo general y aplicar este layaout en categorias-->

</template>

<script>
import TableComponent from '@/components/categories/TableComponent.vue';
import InputSearch from '../forms/InputSearch.vue';
import generalTitle from './generalTitle.vue';
import DeleteModalTable from "@/components/categories/DeleteModalTable.vue"
import PaginationComponent from '../general/PaginationComponent.vue';
import Loader from './LoaderComponent.vue';


export default {
    name: 'TableMenuLayaout',
    components: {
        TableComponent,
        InputSearch,
        DeleteModalTable,
        PaginationComponent,
        generalTitle,
        Loader,
    },
    props: {
        deleteStyle: Object,
        tableOptions: {
            type: Object,
            required: true
        },
        descriptionName: {
            type: Object,
            required: true
        },
        dbUrl: {
            type: Object,
            required: true
        },
        searchOption: {
            type: Boolean,
            required: false,
            default: true
        },
        breadcrumbs: {
            type: Array,
            required: true
        },
        goBack: {
            type: Function,
            required: false
        },
        root: {
            type: Boolean,
            required: false,
            default: true
        },
        getPath: {
            type: String,
            required: false
        },
        tableInfoMessage: {
            type: Object,
            required: true
        },
        deleteModalText: {
            type: Object,
            required: true
        },
        isButtonsActive: {
            type: Boolean,
            default: true
        }

    },

    data() {
        return {
            isLoading: true,
            error: false,
            order: 'DESC',
            lastSingularId: '',
            isModalActive: false,
            isElementCreated: '',
            categorySearch: '',
            selectedElements: [],
            tableColumns: { ...this.tableOptions },
            categories: [
            ],
            elementsCopy: [],
            orderAttr: 'id',
            isAdmind: false,
            selectedFullNameForDelete: '',
            isUnlocked: false
        }
    },
    mounted() {
        const userRol = this.$store.getters.getRoles
        if (userRol.includes('ROLE_COMPANY') || userRol.includes('ROLE_COMPANY_ADMIN')) {
            this.isAdmind = true
        }
        if (userRol.includes('ROLE_UNLOCKED')) {
            this.isUnlocked =  true
            this.isLoading = false
        }
    },
    watch: {
        tableOptions(newValue){
            this.tableColumns = newValue
        },
        categories() {
            if (this.error === true) {
                this.isElementCreated = 'error'
                this.error = false
            }
            else if (this.categories.length > 0) {
                this.isElementCreated = 'yes'
            } else if (this.categories.length === 0 && this.categorySearch === '') {
                this.isElementCreated = 'no'
            }
            else {
                this.isElementCreated = "notFound"
            }
            this.elementsCopy = this.categories


        },
    },
    methods: {
        toggleModal() {
            this.isModalActive = !this.isModalActive
        }
        ,
        toggleModalMultiple() {
            this.option = 'multiple'
            this.toggleModal()
        },
        toggleModalSimple(e) {
            console.log(e.id)
            this.option = 'simple'
            this.lastSingularId = e.id
            this.selectedFullNameForDelete = e.name
            this.toggleModal()
        },
        eliminateSelectedCategories() {
            this.selectedElements = []
        },
        cleanSelectedCategories(e) {
            this.selectedElements = this.selectedElements.filter(category => category !== e)

        }
        ,
        addSelectedElement(category) {
            if (this.selectedElements.includes(category)) {
                const index = this.selectedElements.indexOf(category);
                if (index !== -1) {
                    this.selectedElements.splice(index, 1);
                }
            } else {
                this.selectedElements.push(category);
            }

        },
        addAllElements(categoriesArrayId) {
            this.selectedElements = categoriesArrayId
        },
        // metodos relacionados cona la paginación y los valores de busquedad que necesita la paginación
        handleSearch(search) {
            if (typeof search === 'string') {
                this.categorySearch = search;
            }
        },
        handleDataFetched(data) {
            if (data === 'error') {
                this.error = true;
                this.categories = [];  // Se actualiza después de this.error
            } else {
                this.categories = data;
            }
            this.isLoading = false
        },
        handleTriggerFetch() {
            this.$refs.paginationComponent.getData();
        },
        changeorder(elementCode) {
            let indexColumTh = null
            this.tableColumns.columnTitle = this.tableColumns.columnTitle.map((columTh, index) => {
                if (columTh.elementCode === elementCode) {
                    indexColumTh = index
                    return { ...columTh, isUp: !columTh.isUp }
                } else {
                    return { ...columTh, isUp: false }
                }
            })

            //para filtrar el orden según el boton seleccionado
            if (indexColumTh !== null) {
                this.orderAttr = this.tableColumns.mainRowName[indexColumTh]

            }


            this.order = this.order === 'ASC' ? 'DESC' : 'ASC';

        },
        checkboxToFalse() {
            this.$refs.tableComponent.checkBoxToFalse()
        }


    },

}

</script>

<style scoped lang="scss">
.loader-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.pagination {
    margin-top: 32px;
    margin-bottom: 12px;
    padding-inline: 12px 33px;

    @media (max-width: 768px) {
        margin-top: 24px;
        margin-bottom: 36px;
        padding-inline: 12px;
    }

}

button {
    cursor: pointer;
}



.template {


    .title {
        width: calc(100% - 10px);
        margin-bottom: 24px;

        .delete-elements {
            width: calc(100% - 48px);
            margin: 24px 24px 0 0px;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            text-align: center;
            background-color: #C74D4D;
            color: #F9FBFC;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: none;
            font-family: "Inter", sans-serif;
        }

        h3 {
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            text-align: left;
            color: #A2ABAD;
            display: none;
            font-family: "Inter", sans-serif;
        }

        h1 {
            font-size: 20px;
            font-weight: 600;
            line-height: 24.2px;
            text-align: left;
            color: #163F49;
            margin-left: 24px;
            font-family: "Inter", sans-serif;
        }

        .search {
            width: calc(100% - 48px);
            margin: 26px 24px 0 0px;

            @media (max-width: 449px) {
                width: 100%;
            }
        }

        .new-add,
        .delete-elements {
            max-width: 408px;

            @media (max-width: 449px) {
                width: 100%;
                margin-right: 0;
            }
        }

        .new-add {
            order: 1;
            width: calc(100% - 48px);
            margin: 24px 24px 0 0px;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            text-align: center;
            background-color: #163F49;
            color: #F9FBFC;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            font-family: "Inter", sans-serif;

            @media (max-width: 449px) {
                width: 100%;
                margin-right: 0;
            }


            .new-add-button {
                width: 100%;
            }
        }
    }

    .table {
        min-height: 200px;
        width: calc(100% - 10px);
        padding: 12px 18px 12px 24px;
        background: #FFFFFF;
        border-radius: 0 10px 10px 10px;

        @media (max-width: 576px) {
            padding-right: 0;
        }

    }


}

@media (min-width: 361px) {
    .template {
        .title {
            width: calc(100% - 15px);
        }
    }

}

@media (min-width: 450px) {
    .template {
        .table{
            min-height: 400px;
        }
        .title {
           
            .new-delete {
                gap: 16px;
                display: flex;
                justify-content: space-between;
            }
        }
    }

}

@media (min-width: 1200px) {
    .template {

        .title {

            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: auto auto auto;
            margin-bottom: 48px;

            h1,
            h3,
            .search,
            .new-add {
                margin: 0;
            }

            h3 {
                display: block;
                grid-column: span 2;
                height: auto;
            }

            h1 {
                margin-top: 25px;
                font-size: 30px;
                font-weight: 600;
                line-height: 36.31px;
                text-align: left;
            }

            .new-add {
                width: 238px;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                margin-left: auto;
                margin-top: auto;
            }

            .delete-elements {
                width: 238px;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                margin-left: auto;
                margin-top: auto;
                margin-right: 0;
            }

            .search {
                margin-top: 24px;
                width: 100%;
                grid-column: span 2;
                grid-row-start: 3;
            }

            .new-add,
            .delete-elements {
                width: 238px;
            }

            .new-delete {
                flex-direction: row-reverse;
                justify-content: flex-start;

                button {
                    margin: 0;

                }

                .delete-elements {
                    margin-top: auto;
                }

                .new-add {
                    margin-left: 24px;
                }


            }
        }

        .table {
            margin-top: 0;
        }

    }

}
</style>