import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import './styles/main.scss';  
import './axios/index.js'

createApp(App)
.use(store)
.use(router)
.use(i18n)
.mount('#app');
