<template>
    <div class="box">
        <div class="title-div">
            <h1>{{ title }}</h1>
        </div>
       
        <p>{{ description }} <span ref="showMore">Mostrar más</span></p>
        <div class="btn">
            <button>Descargar plantilla</button>
        </div>
   
        <div class="dropdown"> 
        <Dropdown :estilos="{left: '-80px'}" :links="[
            { text: 'Editar', data: { action: 'toggleModalEdit' } },
            { text: 'Eliminar', data: { action: 'toggleModalDelete' }, estilos: { color: '#C74D4D' } },
        ]" />
        </div>
    </div>
</template>


<script>
import Dropdown from '../general/Dropdown.vue';

export default {
    name: 'DocBoxComponent',
    components: { Dropdown },
    props: {
        id: Number,
        title: String,
        description: String,
    },

}
</script>

<style scoped lang="scss">
.box {
    background-color: #FFFFFF;
    padding: 16px;
    position: relative;

   

    .title-div{
        width: 100%;
        border-bottom: 1px solid #E0EBEE;
    }

    h1 {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.36px;
        text-align: left;
        color: #0E0E0F;
        font-family: "Inter", sans-serif;
        padding-bottom: 12px;
       
        max-width: 80%;
    }

    p {
        margin-top: 12px;
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        text-align: left;

        span {
            font-size: 12px;
            font-weight: 600;
            line-height: 15.6px;
            text-align: left;
            color: #163F49;
            display: none;
        }
    }

    button {
        margin-top: 12px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid var(--Verde-Dedavara, #163F49);
        color: #163F49;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        text-align: center;
        font-family: "Inter", sans-serif;
        background-color: #FFFFFF;
        width: 100%;
        cursor: pointer;
        min-width: 146.1px;
    }

    .dropdown{
            position: absolute;
            top: 5px;
            right: 10px;
          
        }


}

@media(min-width: 1200px) {
    .box {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr max-content;
        grid-template-rows: 1fr;
        padding: 0;
        padding: 19px 36px 19px 24px;
        border-radius: 9.96px;

        h1 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
   
           
            font-size: 20px;
            font-weight: 500;
            line-height: 24.2px;
            max-width: 100%;
        }

        .title-div{
            border: none;
            border-right: 1px solid #E0EBEE;
            padding: 0 24px 0 0;
        }

        p {
            margin: 0 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
        }

        button {
            width: calc(100% - 32px);
            display: flex;
            justify-content: center;
            align-items: center;
            place-self: center;
            justify-self: left;
            margin-top: 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            align-self: center;
        }

        .dropdown, .btn{
            position: static;
        align-self: center;
    }
    .dropdown{
        margin-bottom: 5px;
        margin-left: 5px;
    }
          

       
    }

}
</style>