<template>
  <!--Buscador-->
  <div v-if="search" class="table-search">
    <Inputsearch @search="this.handleSearch($event)" />
  </div>

  <!--Desktop-->

  <div class="relative" ref="widgetNotes">

    <div ref="tableScroll" class="table">

      <table>
        <thead>
          <tr>
            <th v-for="(column, key) in columnConfig" :key="key">
              <span>

                <span>{{ column?.title }}</span>


                <button v-if="tableFilters" @click="orderChange(column.elementKey)" :class="{
                  'btn-order': true,
                  'is-up': column.isUp,
                  'is-down': !column.isUp
                }">
                  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_376_1705)">
                      <path
                        d="M6.39336 7.39421L4.16167 9.40315V0.468284C4.16167 0.210728 3.92758 0 3.64146 0C3.35535 0 3.12125 0.210728 3.12125 0.468284V9.40315L0.88956 7.39421C0.686679 7.21158 0.358948 7.21158 0.156067 7.39421C-0.046814 7.57684 -0.046814 7.87186 0.156067 8.05449L3.27732 10.8642C3.32413 10.9063 3.38136 10.9438 3.44898 10.9672C3.51141 10.9906 3.57904 11.0047 3.64666 11.0047C3.71429 11.0047 3.78192 10.9906 3.84434 10.9672C3.90677 10.9438 3.96399 10.911 4.01601 10.8642L7.13726 8.05449C7.34014 7.87186 7.34014 7.57684 7.13726 7.39421C6.93438 7.21158 6.60665 7.21158 6.39856 7.39421H6.39336Z" />
                      <path
                        d="M9.88492 4.91211H7.28388C6.99777 4.91211 6.76367 5.12284 6.76367 5.38039C6.76367 5.63795 6.99777 5.84868 7.28388 5.84868H9.88492C10.171 5.84868 10.4051 5.63795 10.4051 5.38039C10.4051 5.12284 10.171 4.91211 9.88492 4.91211Z" />
                      <path
                        d="M11.4455 2.57568H7.28388C6.99777 2.57568 6.76367 2.78641 6.76367 3.04397C6.76367 3.30152 6.99777 3.51225 7.28388 3.51225H11.4455C11.7317 3.51225 11.9658 3.30152 11.9658 3.04397C11.9658 2.78641 11.7317 2.57568 11.4455 2.57568Z" />
                      <path
                        d="M12.4808 0.234375H7.28388C6.99777 0.234375 6.76367 0.445103 6.76367 0.702659C6.76367 0.960216 6.99777 1.17094 7.28388 1.17094H12.4808C12.7669 1.17094 13.001 0.960216 13.001 0.702659C13.001 0.445103 12.7669 0.234375 12.4808 0.234375Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0_376_1705">
                        <rect width="13" height="11" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </span>


            </th>
            <th v-if="reviewButton == true"></th>
            <th v-if="contextMenu == true"></th>
          </tr>
        </thead>


        <tbody v-if="caseFiles.length > 0">
          <template v-for="(caseFile, index) in caseFiles" :key="index">
            <!-- Fila de datos -->
            <tr>
              <!-- Celdas de la fila -->
              <td v-for="(column, key) in columnConfig" :key="key" :class="getClassForColumn(key, caseFile)"
                :title="caseFile[column.elementKey]">
                {{ caseFile[column.elementKey] }}
              </td>

              <!-- Botón de revisión si es necesario -->
              <td v-if="reviewButton == true">
                <router-link :to="`/expedientes/${caseFile.id}`" class="revision">{{ this.$t('general.buttons.review')
                  }}</router-link>
              </td>

              <!-- Menú contextual si es necesario -->
              <td v-if="contextMenu == true">
                <span @click="(e) => calculateDistance(e, 'desk')" class="ellipses-td">
                  <Dropdown :estilos="{ top: `${noteDropdownPositionY}px`, left: `${noteDropdownPositionX}px` }"
                    @clickedAction="toggleModalSimple({id: caseFile.id, code: caseFile.caseCode})" :links="dropdownData(caseFile.id)"
                    :ref="'dropdownDesk' + index" :isEditPosition="true" />
                </span>
              </td>
            </tr>

            <!-- Fila de la barra de progreso -->
            <tr class="relative-tr">

              <td class="row">
                <span>
                  <div class="progress-bar-container">
                    <div class="progress-bar-background"></div>
                    <div class="progress-bar" :style="{

                      width: getProgressBarWidth(caseFile.state.stateOrder, caseFile?.totalNumber),
                      backgroundColor: caseFile.state.color,
                    }"></div>
                  </div>
                </span>
              </td>





              <td v-for="(column, key) in columnArray" :key="key + 'progress-bar'"
                :class="getClassForColumn(key, caseFile)">
              </td>



              <td v-if="reviewButton == true"></td>

              <!-- Menú contextual si es necesario -->
              <td v-if="contextMenu == true"></td>
            </tr>
          </template>
        </tbody>
      </table>


      <InfoMessage
        v-if="caseFiles.length === 0 && windowSize > 768 && !isElements && !isUnlocked && searchValue === '' && !isSearchValueActive"
        :text="noElementsMessage" :isActive="true" icon="info" />



      <InfoMessage v-if="caseFiles.length === 0 && windowSize > 768 && searchValue !== '' && isSearchValueActive"
        :text="noSearchMessage" :isActive="true" icon="info" />

    </div>

    <!--Responsive-->


    <InfoMessage
      v-if="caseFiles.length === 0 && windowSize <= 768 && !isElements && !isUnlocked && searchValue === '' && !isSearchValueActive"
      :text="noElementsMessage" :isActive="true" icon="info" />

    <InfoMessage v-if="caseFiles.length === 0 && windowSize <= 768 && searchValue !== '' && isSearchValueActive"
      :text="noSearchMessage" :isActive="true" icon="info" />

    <div v-for="(caseFile, index) in caseFiles" :key="index" class="file-table-responsive">

      <div class="file-table">
        <div @click="(e) => toggleCase(index, e)" class="casefile-menu" v-if="contextMenu == true">
          <div @click="(e) => calculateDistance(e)" class="ellipses-td">
            <Dropdown :estilos="{ top: `10px`, right: `20px` }"  @clickedAction="toggleModalSimple({id: caseFile.id, code: caseFile.caseCode})"
              :links="dropdownData(caseFile.id)" :ref="'dropdownNote' + index" />
          </div>
        </div>


        <h3 v-if="!isInformant" @click="(e) => toggleCase(index, e)">
          {{ caseFile.subject }}
          <img :src="isCaseExpanded(index)
            ? require('../../assets/icons/angle-up-collapse.svg')
            : require('../../assets/icons/angle-down.svg')
            " />
        </h3>
        <h3 v-else class="smaller">{{ caseFile.subject }}</h3>

        <!-- informacion de los expedientes en MOVILES Uno es desplegable para administradores y el v-else es sin desplegable para informante -->
        <div class="collapse" v-if="expandedCases.includes(index) && !isInformant">
          <div :class="{ 'inline-data': dataArray.length > 1 }" :key="'columnMovile1' + index"
            v-for="(dataArray, index) in tableColumnsMovile">
            <div :key="'columnMovile1' + singularData.id" v-for="singularData in dataArray">
              <p :class="singularData.class"><span>{{ singularData.title }}</span>{{ caseFile[singularData.element] }}
              </p>
            </div>
          </div>
          <div class="revision-wrapper" v-if="reviewButton === true">
            <router-link class="revision" :to="`/expedientes/${caseFile.id}`">{{ this.$t('general.buttons.review')
              }}</router-link>
          </div>
        </div>
        <div v-if="isInformant">
          <div :class="{ 'inline-data': dataArray.length > 1 }" :key="'columnMovile1' + index"
            v-for="(dataArray, index) in tableColumnsMovile">
            <div :key="'columnMovile1' + singularData.id" v-for="singularData in dataArray">
              <p :class="singularData.class"><span>{{ singularData.title }}</span>{{ caseFile[singularData.element] }}
              </p>
            </div>
          </div>
          <div class="revision-wrapper" v-if="reviewButton === true">
            <router-link class="revision" :to="`/expedientes/${caseFile.id}`">{{ this.$t('general.buttons.review')
              }}</router-link>
          </div>

        </div>

        <div class="progress-bar-container">
          <div class="progress-bar-background"></div>
          <div class="progress-bar" :style="{
            width: getProgressBarWidth(caseFile.state.stateOrder, caseFile?.totalNumber),
            backgroundColor: caseFile.state.color,
          }"></div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Dropdown from "@/components/general/Dropdown.vue";
import Inputsearch from "@/components/forms/InputSearch.vue";
import InfoMessage from "../general/InfoMessage.vue";
import { getProgressBarValue } from "@/components/utils.js"



export default {
  name: "FileTableComponent",
  data() {
    return {
      expandedCases: [],
      noteDropdownPositionX: 0,
      noteDropdownPositionY: 0,
      deleteId: '',

      isDropdownOpen: false,
      isDeskDropdownOpen: false,
      columnArray: [],
      windowSize: 0

    };
  },

  components: {
    Dropdown,
    Inputsearch,
    InfoMessage,
  },
  watch: {
    columnConfig() {
      this.columnArray = this.columnConfigArray
    },


  },
  mounted() {

    this.windowSize = window.innerWidth
    this.columnArray = this.columnConfigArray()
    //ponemos el del body porque en la versión movil no hay tabla como tal que
    document.body.addEventListener('scroll', this.handleScroll);
    this.$refs.tableScroll.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    document.body.removeEventListener('scroll', this.handleScroll);
    this.$refs.tableScroll.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    columnConfigArray() {

      const keysQuantity = Object.keys(this.columnConfig).length
      return new Array(keysQuantity - 2);
    },
    handleScroll() {
      //ocultar los dropdwon de notas cuando se haga scroll ya que debido al overflow

      //Para que solo se ejecute quitar los dropdown cuando este abierto uno y se haga scroll 


      if (!this.isDropdownOpen && !this.isDeskDropdownOpen) return


      if (this.isDropdownOpen) {
        this.caseFiles.forEach((_, index) => {
          const refName = 'dropdownNote' + index
          this.$refs[refName][0].closeDropdown()
        });
        this.isDropdownOpen = false
      } else {
        this.caseFiles.forEach((_, index) => {
          const refName = 'dropdownDesk' + index
          this.$refs[refName][0].closeDropdown()
        });
        this.isDeskDropdownOpen = false
      }


    },
    handleSearch(search) {
      if (typeof search === 'string') {
        this.$emit('changeSearch', search)
      }
    },

    calculateDistance(e, valor) {


      if (e.target.closest('.dropdown-menu') !== null) return

      //cogemos la posicion x e y del padre 
      const widgetNotes = this.$refs.widgetNotes;
      const dropdown = e.target

      const widgetRect = widgetNotes.getBoundingClientRect();
      const dropdownRect = dropdown.getBoundingClientRect();

      // buscamos la diferencia de ambos valores para saber cuanto top y left darle al elemento position absolute
      this.noteDropdownPositionX = dropdownRect.left - widgetRect.left
      this.noteDropdownPositionY = dropdownRect.top - widgetRect.top

      this.noteDropdownPositionX -= 140
      this.noteDropdownPositionY += 10


      valor === 'desk' ? this.isDeskDropdownOpen = true : this.isDropdownOpen = true



    },
    toggleCase(index, e) {
      if (e.target.closest(".ellipses-td")) return
      if (this.expandedCases.includes(index)) {
        this.expandedCases = this.expandedCases.filter((i) => i !== index);
      } else {
        this.expandedCases.push(index);
      }
    },
    toggleModal() {
      this.$emit('toggleModalDelete')
    },
    toggleModalSimple(data) {
      this.$emit('toggleModalDelete', data)
    },

    isCaseExpanded(index) {
      return this.expandedCases.includes(index);
    },

    getStatusClass(estado) {
      if (estado === "En curso") {
        return "in-progress";
      } else if (estado === "Cerrado") {
        return "closed";
      } else {
        return "new";
      }
    },

    getProgressBarWidth(order, totalNumber) {
      if (this.isMoreThanOneDifferentCompany) {
        //es un rol como el de informante que el numero maximo de estados cambia porque hay diferentes empresas en una misma tabla
        const valor = getProgressBarValue(order, totalNumber)
        return `${valor}%`

      } else {
        //es un rol como administrador que por tabla solo hay 1 empresa
        const valor = getProgressBarValue(order, this.totalState)
        return `${valor}%`
      }

    },

    orderChange(title) {
      this.$emit('changeorder', title)
    },

    getPrioridadClass(prioridad) {

      if (prioridad === this.$t('general.inputElements.priority.state.hight')) {
        return "hight";
      } else if (prioridad === this.$t('general.inputElements.priority.state.medium')) {
        return "middle";
      } else {
        return "low";
      }
    },

    getClassForColumn(column, caseFile) {
      if (column === "Estado") {
        return this.getStatusClass(caseFile.state);
      }
      if (column === "subject") {
        return "asunto";
      }
      if (column === "category") {
        return "categoria";
      }

      if (column === "priority") {
        var subClass = this.getPrioridadClass(caseFile.priority);
        return "prioridad" + " " + subClass;
      }

      if (column === "finalComment") {
        return "comentario";
      }

      return "";
    },
    handleTriggerFetchEmit() {
      this.$emit('handleTriggerFetch')
    }
  },

  emits: ['handleTriggerFetch', 'toggleModalDelete', 'changeorder', 'changeSearch', 'changeTable', 'activeLoading'],
  props: {
    columnConfig: {
      type: Object,
      required: true,
    },
    caseFiles: {
      type: Array,
      required: true,
    },
    tabTitle: Object,
    searchValue: String,
    reviewButton: {
      type: Boolean,
      default: false,
    },

    noDeletedMessage: String,
    isElements: Boolean,
    contextMenu: {
      type: Boolean,
      default: false,
    },
    isSearchValueActive: Boolean,

    tableFilters: {
      type: Boolean,
      default: false,
    },
    dropdownData: {
      type: Function
    },
    deleteModalInfo: {
      type: Object,
      requied: true
    },
    isUnlocked: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: true
    },
    tableColumnsMovile: Array,
    isInformant: {
      type: Boolean,
      default: false
    },
    isMoreThanOneDifferentCompany: {
      type: Boolean,
      default: false
    },
    noElementsMessage: String,
    totalState: Number,
    noSearchMessage: String
  },
};
</script>

<style scoped lang="scss">
.is-up {
  svg {
    transform: rotate(-180deg);
    transition: all .5s linear;
  }
}

.is-down {
  svg {
    transform: rotate(0deg);
    transition: all .5s linear;
  }
}

.btn-order {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  transition: background-color, .4s linear;

  svg {
    fill: #B3BBBC;
  }

  img {
    padding: 5px 3px 4px 4px;

  }

  &:hover {
    background-color: #163F49;

    svg {
      fill: white;
    }
  }


}

th>span {
  display: flex;
  align-items: center;

  span {
    width: fit-content;

  }
}


h2 {
  color: #B3BBBC;
  font-size: 16px;
  font-family: 'InterSemiBold', sans-serif;
  margin-block: 0 24px !important;
}

.relative-tr {
  position: relative;
  background-color: transparent;
  transform: translateY(-16px);


  td {
    padding: 0 !important;
    position: absolute;
  }
}

.row {
  width: 100%;
  height: 6px;
  position: absolute;
  display: block;

}


.progress-bar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  pointer-events: none;

}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 6px;
  width: 50%;
  /* Altura de la barra de progreso */
  background-color: #163f49;
  border-radius: 0 4px 4px 4px;
}

.progress-bar.new {
  background: #163f49;
}

.progress-bar.in-progress {
  background: #94a7aa;
}

.progress-bar.closed {
  background: #d9e7ea;
}

.progress-bar-background {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 6px;
  background-color: #e7f0f2;
  border-radius: 0 0 4px 4px;
}

.no-results-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  font-family: "InterMedium", sans-serif;
  color: #163f49;
}

.responsive-filters {
  .priority-select {
    width: 100%;
    display: inline-flex;
    align-items: center;
    font-family: "InterMedium", sans-serif;
    font-size: 14px;
    color: #163f49;
  }

  .priority-checkboxs {
    margin-top: 14px;
    display: inline-flex;

    .custom-checkbox {
      margin-right: 15px;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #e0ebee;
    margin: 14px 0 24px 0;
  }
}

.table-search {
  margin-bottom: 48px;

  @media (max-width: 564px) {
    .input-search {
      max-width: 100% !important;
    }

  }
}

.file-table {
  position: relative;
}

.relative {
  position: relative;
}

.table {
  width: 100%;
  overflow-x: auto;
  height: 62vh;
}

.left {
  text-align: left;
  margin-top: 24px !important;
  margin-bottom: 14px !important;
}

.revision {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #163f49;
  width: 99px;
  height: 32px;
  background: none;
  border-radius: 4px;
  font-size: 16px;
  font-family: "InterMedium", sans-serif;
  cursor: pointer;
  color: #163f49;
}

button.context-menu {
  background: none;
  border: none;
  cursor: pointer;
}

.file-table-responsive {
  display: none;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 16px;

  tr {
    width: 100%;
    margin: 16px 0;
    background: #fff;

  }

  thead {
    position: sticky;
    top: -4px;
    z-index: 80;
  }

  thead tr {
    top: 0;
    z-index: 1;
    background: #f4f8f9;
  }

  thead tr th {
    border-bottom: 1px solid #b3bbbc;
  }

  th,
  td {
    text-align: left;
    font-size: 12px;
    border-radius: 4px 4px 0 0;
  }

  th {
    padding: 16px 16px 20px 16px;
    font-family: "InterSemiBold", sans-serif;
    color: #b3bbbc;


    button {
      width: 20px;
      height: 20px;
      margin-left: 2px;
      border: none;
      background: none;
      border-radius: 4px;
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      svg {
        fill: #b3bbbc;
        width: 13px;
      }
    }

    button:hover {
      background: #163f49;

      svg {
        fill: #e7f0f2;
      }
    }
  }

  td {
    padding: 20px 16px;
    font-family: "InterMedium", sans-serif;
    border-bottom: 1px solid #e7f0f2;
    color: #0e0e0f;
    height: 64px;

  }

  td.asunto,
  td.categoria,
  td.comentario {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }



  td.prioridad.hight {
    color: #163f49;
  }

  td.prioridad.middle {
    color: #94a7aa;
  }

  td.prioridad.low {
    color: #cbdee2;
  }

  span.status {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }

  span.status.new {
    background: #163f49;
  }

  span.status.in-progress {
    background: #94a7aa;
  }

  span.status.closed {
    background: #d9e7ea;
  }
}

/*Media queries*/
@media (max-width: 1400px) {
  .file-table table {

    td.asunto,
    td.categoria {
      max-width: 150px;
    }
  }
}

@media (max-width: 768px) {
  
  .table {
    display: none;
  }

  .left {
    display: none;
  }

  .file-table-responsive {
    display: flex;
    //position: relative;
    margin-bottom: 30px;

    .file-table {
      width: 100%;
      background: #ffffff;
      border-radius: 8px;
      padding: 24px 16px 8px 16px;


      .casefile-menu {
        display: flex;
        justify-content: flex-end;
      }

      h3 {
        width: 100%;
        font-family: "InterSemiBold", sans-serif;
        font-weight: 500;
        font-size: 18px;
        text-align: left;
        overflow: hidden;
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 5px 0 24px 0;
        gap: 8px;
      }

      .smaller {
        font-size: 16px;
      }

      .collapse {
        padding-bottom: 24px;
      }

      p {
        width: 100%;
        margin-bottom: 12px;

        span {
          display: block;
          font-family: "InterMedium", sans-serif;
          font-size: 12px;
          color: #b3bbbc;

          margin-bottom: 8px;
        }

        text-align: left;

        font-family: "InterRegular",
        sans-serif,
        sans-serif;
        font-size: 14px;
        color: #0e0e0f;
      }

      .inline-data {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 0 7px;
        justify-content: space-between;
        margin-top: 12px;

        p.category {
          max-width: 400px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          /* Limita a dos líneas */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          white-space: normal;
          padding-right: 25px;
        }
      }

      .revision-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 25px 0 16px 0;

        .revision {
          width: 196px;
        }
      }

      .progress-bar-container {
        z-index: 0;

        .progress-bar-background,
        .progress-bar {
          height: 8px;
        }
      }
    }
  }
}
</style>
