<template>
    <div @mousedown="(e) => existModal(e)" class="modal" ref="modal">
        <form @submit.prevent="editarValues" class="box">
            <h2 class="relative">{{ this.$t('complainForm.create-edit.editModal.title') }}</h2>

            <InfoMessage v-if="isBlank && type !== 'Verficación'" :styleDiv="{ marginBottom: '0' }" :text="message"
                :isActive="true" icon="error" color="#C74D4D" />
            <InfoMessage :styleDiv="{ marginBottom: '0' }" v-if="type === 'Verficación' && verification.error"
                :text="verification.errorText" :isActive="verification.error" icon="error" color="#C74D4D" />

            <div class="data">
                <input :title="editName" v-if="type !== 'Selector' && type !== 'Verficación'" type="text"
                    v-model.trim="editName">
                <!-- div de verificacion -->
                <div v-if="type === 'Verficación'" class="verification-label">
                    <div class="verification-description">
                        <div class="button-link-container">
                            <div class="flex-around">
                                <button type="button" @click="eliminateLink" :title="this.$t('complainForm.create-edit.addElements.verification.deleteLinks')"><img
                                        src="@/assets/icons/linkOff.svg" :alt="this.$t('complainForm.create-edit.addElements.verification.deleteLinks')"></button>
                                <button type="button" :title="this.$t('complainForm.create-edit.addElements.verification.addLink')" @click="addLink"><img
                                        src="@/assets/icons/link.svg" :alt="this.$t('complainForm.create-edit.addElements.verification.addLink')"></button>
                            </div>
                            <input type="text" v-model="linkHref" :placeholder="this.$t('complainForm.create-edit.addElements.verification.placeholderLink')">
                        </div>
                    </div>
                    <div @click="this.$refs.textInput.focus()"
                        :style="isFocusable ? { border: '2px solid #0E0E0F' } : null" class="verification">
                        <div @keydown="prevenirEnter" :class="!verification.value && 'placeholderClass'"
                            @focus="isFocusable = true" @blur="isFocusable = false" @paste="handlePaste"
                            contenteditable="true" @input="updateValue" @mousedown="isVerificationMouseDown = true"
                            ref="textInput">
                        </div>
                    </div>
                </div>

                <!-- select -->
                <div v-if="type === 'Selector'" class="flex">
                    <label class="flex">
                        {{ this.$t('complainForm.create-edit.addElements.select.title') }}
                        <input :title="editName" type="text" v-model.trim="editName" class="input-name-select"
                            :placeholder="this.$t('complainForm.create-edit.addElements.generalPlaceholder')">
                    </label>
                    <label>
                        {{ this.$t('complainForm.create-edit.addElements.select.options.title') }}
                        <div class="options-select">
                            <OptionsSelect :optionArray="editOptionArray" :isEdit="true" ref="optionsSelect"
                                @optionsChanged="updateOptions" />
                        </div>
                    </label>
                </div>
            </div>
            <div class="check">
                <Checkbox v-model="editIsRequired" labelIdenty="check" />
                <label for="check">{{ this.$t('complainForm.create-edit.addElements.required') }}</label>

            </div>
            <div class="btns">
                <button class="cancel-btn" @click="this.$emit('toggleModalEdit')" type="button">Cancelar</button>
                <button class="action-btn" type="submit">{{  this.$t('complainForm.create-edit.editModal.button') }}</button>
            </div>

        </form>
    </div>

</template>


<script>
import InfoMessage from '../general/InfoMessage.vue';
import Checkbox from './Checkbox.vue';
import OptionsSelect from './OptionsSelect.vue';
import verificationMixing from './verificationMixing';


export default {
    name: 'FormEditModal',
    data() {
        return {
            isFocusable: false,
            editName: this.name,
            editIsRequired: this.isRequired,
            editOptionArray: this.type !== 'Selector' ? '' : this.options.map(option => ({ value: option })),
            isBlank: false,
            message: '',
            verification: {
                value: '',
                error: false,
                errorText: ''
            },
            linkHref: '',
            isVerificationMouseDown: false,
        }
    },
    mixins: [verificationMixing],
    mounted() {
        document.body.style.overflow = 'hidden';

        if (this.type === 'Verficación') {
            this.$refs.textInput.innerHTML = this.editName
            this.verification.value = this.editName

        }
    },
    beforeUnmount() {
        document.body.style.overflow = 'auto';
    },
    props: {
        name: String,
        type: String,
        options: Array,
        isRequired: Boolean,
        id: String
    },
    watch: {
        editName(newValue) {
            if (this.isBlank && newValue !== '') {
                this.isBlank = false
            }
        },
        editOptionArray: {
            handler() {
                this.isBlank = false;
            },
            deep: true,
        },
    },
    components: { Checkbox, OptionsSelect, InfoMessage },
    methods: {
        existModal(e) {
            if (e.target.closest('.box') || e.target.closest('.button-link-container') || e.target.closest('.flex-around')) return
            this.$emit('toggleModalEdit')
        },
        editarValues() {
            let blank = false
            if (this.type === 'Verficación') {
                this.editName = this.$refs.textInput.innerHTML.toString()
                if (this.$refs.textInput.textContent.trim() === '') {
                    blank = true
                }
            } else {
                if (this.editName.trim() === '') {
                    blank = true
                }
            }

            if (blank) {
                this.message =  this.$t('complainForm.create-edit.editModal.editError')
                this.isBlank = true
                if (this.type === 'Verficación') {
                    this.verification.error = true
                    this.verification.errorText =  this.$t('complainForm.create-edit.editModal.editError')
                    setTimeout(() => {
                        this.verification.error = false
                    }, 1500)
                }
                return
            }

            if (this.type !== 'Selector') {
                this.$emit('editFormElement', {
                    name: this.editName,
                    type: this.type,
                    isRequired: this.editIsRequired,
                    id: this.id
                })
            } else {



                const opt = this.editOptionArray.map(option => option.value).filter(option => option !== '');
                if (opt.length === 0) {
                    //si todas las opciones están en blanco
                    this.message =  this.$t('complainForm.create-edit.addElements.select.options.error')
                    this.isBlank = true
                    return
                }

                this.$emit('editFormElement', {
                    name: this.editName,
                    type: this.type,
                    isRequired: this.editIsRequired,
                    id: this.id,
                    options: opt
                })
            }

        },
        updateOptions(options) {
            this.editOptionArray = options;
        },
    },
}


</script>

<style scoped lang="scss">
//verificacion

.relative {
    position: relative;
}

.button-link-container {
    position: absolute;
    top: 10px;
    right: 24px;
    background: white;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    width: 150px;
    border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
    border-radius: 3px;
    padding: 1px;


    .flex-around {
        display: flex;
        justify-content: space-around;
    }

    button {
        background: transparent;
        border: none;
        cursor: pointer;

        img {
            height: 20px;
            width: 20px;
        }
    }

    input {
        border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
        color: #0E0E0F;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        padding: 2px;
        font-size: 10px;
        min-width: 150px;
        width: auto;
        min-width: auto;
        border-radius: 0;
        height: 25px;

    }

    input::placeholder {
        text-align: left;
        color: #B3BBBC;
    }
}



//
.btns {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    button {
        cursor: pointer;
    }

    .cancel-btn {
        color: #163F49;
        font-size: 16px;
        font-family: 'InterMedium';
        border: 1px solid #163F49;
        background-color: transparent;
        border-radius: 4px;
        padding: 7px 14px;
    }

    .action-btn {
        background-color: #163F49;
        color: #F9FBFC;
        border-radius: 4px;
        width: fit-content;
        border: none;
        padding-inline: 20px;

    }
}

.data,
.flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal {

    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 63, 73, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: column;
    padding-block: 20px;


    .box {
        overflow: auto;
        max-height: 85vh;
        width: 90%;
        max-width: fit-content;
        background-color: #FFFFFF;
        padding: 24px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;

        h2 {
            margin: 0;
        }

    }


}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('@/assets/icons/angle-down.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center;
}

.check {
    display: flex;
    gap: 5px;

    label {
        cursor: pointer;
        margin-top: 2px;
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        text-align: left;
        color: #707C7E;
        margin-left: 8px;
        text-wrap: nowrap;
        font-family: "Inter", sans-serif;
    }



    input {
        width: auto;
        height: auto;
    }
}



input {
    width: 100%;
    min-width: 500px;
    height: 42px;
    border-radius: 7.03px;
    border: 1.1px solid var(--Gris--1-Dedavara, #B3BBBC);
    padding-left: 14px;
    font-family: "Inter", sans-serif;
    white-space: pre-wrap;

}

.placeholderClass::before {
    content: 'Texto';
    pointer-events: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #B3BBBC;
}

.verification {
    display: grid;
    width: 500px;
    border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
    border-radius: 8px;

    &>div {

        font-size: 14px;
        text-align: left;
        font-weight: 400;
        color: #0E0E0F;
        font-family: "Inter", sans-serif;

        padding: 17px 14px;
        width: 95%;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;

        &:focus {
            outline: none;
        }
    }
}

select {
    min-width: 500px;
    width: 100%;
    height: 42px;
    border-radius: 7.03px;
    border: 1.1px solid var(--Gris--1-Dedavara, #B3BBBC);
    padding-left: 14px;
    font-family: "Inter", sans-serif;
}

@media (max-width: 580px) {



    .modal {
        .box {
            max-width: 95%;

            h2 {
                margin-bottom: 50px;
            }
        }
    }

    .verification {
        width: auto;
    }

    .button-link-container {
        top: 60px;
    }

    input,
    select,
    .verification {
        min-width: 100%;
    }
}
</style>