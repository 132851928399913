<template>
  <div class="textarea-file">
    <div class="title-and-textarea">
      <h2>{{ title }}</h2>

      <textarea :disabled="noUpdate" v-model="textareaContent"></textarea>
    </div>

    <div class="files-download">
      <div v-if="!noFile">
        <FileComponent  :estilos="{ margin: '0' }" v-for="file in files" :key="file.name" :file="file"
        @eliminar-archivo="eliminarArchivo" :remove="false" />
      </div>
     

      <button>{{ DownloadButtonText }}</button>
    </div>
  </div>
</template>

<script>
import FileComponent from "@/components/complaint-process/FileComponent.vue";

export default {
  name: "TextAreaFile",
  props: {
    title: {
      type: String,
      required: true,
    },
    textContent: {
      type: String,
      required: true,
    },
    DownloadButtonText: {
      type: String,
      default: "Descargar",
    },
    noFile: {
      type: Boolean,
      required: false,
      default: false
    },
    noUpdate: {
      type: Boolean,
      default: false
    }

  },
  components: {
    FileComponent,
  },
  data() {
    return {
      textareaContent: this.textContent,
      files: [
        // Ejemplo de archivos
        { name: "15 Archivos ", size: 12332324 },
      ],
    };
  },
  methods: {
    eliminarArchivo(fileToDelete) {
      this.files = this.files.filter((file) => file.name !== fileToDelete.name);
    },
  },
  watch: {
    textContent(newValue){
      this.textareaContent = newValue
    }
  }
};
</script>

<style scoped lang="scss">
.title-and-textarea {
  height: 100%;
  display: flex;
  flex-direction: column;

}

.textarea-file {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 18px 24px 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  


  h2 {
    margin: 0px 0 24px 0;
    text-align: left;
    font-size: 14px;
    color: #b3bbbc;
    font-family: "InterMedium", sans-serif;
    font-weight: 500;
  }

  textarea {
    padding: 16px;
    padding-left: 0;
    width: 100%;
    height: 100%;
    border: none;
    overflow-y: auto;
    padding-right: 10px;
    font-size: 14px;
    color: #0e0e0f;
    font-family: "InterRegular", sans-serif;
    margin-bottom: 18px;
    resize: none;
    line-height: 18.2px;
    background-color: transparent;
    border-radius: 8px;
  }

  .files-download {
    width: 100%;
    margin-top: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;



    button {
      height: 48px;
      border: none;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #CBDEE2;
      color: #163F49;
      font-size: 16px;
      font-family: 'InterMedium', sans-serif;
      font-weight: 500;
      padding: 0 25px;
      margin-left: auto;
    }

    @media(max-width: 500px) {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      

      button {
        width: 100%;
        font-size: 14px;
        height: 42px;
      }
    }



  }
}
</style>
