<template>
    <div class="dropdown" ref="dropdown">
      <button @click="toggleDropdown" class="dropdown-toggle">
        <img src="@/assets/icons/gear.svg" alt="Menu" :style="{width}" />
        <img src="@/assets/icons/angle-down.svg" alt="Menu" :style="{width}" />
      </button>
      <div v-if="isOpen" class="dropdown-menu" :style="{right, left}">
        <ul>
          <li v-for="link in links" :key="link.href">
            <div v-if="link.action === 'link'">
              <router-link :to="link.href">{{ link.text }}</router-link>
            </div>
            <div v-else-if="link.action === 'modal'">
              <button @click="openModal(link.modalProps, link.slotName)">{{ link.text }}</button>
            </div>
            <hr />
          </li>
        </ul>
      </div>
  
      <!-- Modal component -->
      <GeneralModal 
        v-if="modalVisible"
        :modalTitle="modalProps.modalTitle"
        :submitTitle="modalProps.submitTitle"
        :isVisible="modalVisible"
        @close="closeModal"
      >
        <template v-slot:default>
          <component :is="currentSlotComponent"/>
        </template>
      </GeneralModal>
    </div>
  </template>
  
  <script>
  import GeneralModal from "@/components/modal/Modal.vue";
  
  export default {
    name: "SelectConfigDropdown",
    components: {
      GeneralModal
    },
    data() {
      return {
        isOpen: false,
        modalVisible: false,
        modalProps: {},
        currentSlotComponent: null
      };
    },
    props: {
      links: {
        type: Array,
        required: true,
      },
      width: {
        type: String,
        default: "auto",
      },
      right: {
        type: String,
        default: "0",
      },
      left: {
        type: String,
        default: "auto",
      },
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      closeDropdown() {
        this.isOpen = false;
      },
      handleClickOutside(event) {
        if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
          this.closeDropdown();
        }
      },
      openModal(modalProps, slotName) {
        this.modalProps = modalProps;
        this.modalVisible = true;
        this.currentSlotComponent = this.$slots[slotName];
      },
      closeModal() {
        this.modalVisible = false;
        this.currentSlotComponent = null;
      }
    },
    watch: {
      $route() {
        this.closeDropdown();
      },
    },
    mounted() {
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener("click", this.handleClickOutside);
    },
  };
  </script>
  
  <style scoped lang="scss">
  /* Styles remain unchanged */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #163F49;
    width: 57px;
    height: 32px;
    padding: 8px;
    border-radius: 4px;
    margin-right: 16px;
  }
  
  .dropdown-menu {
    width: 130px;
    position: absolute;
    border-radius: 8px;
    top: 0;
    right: 0;
    background: #f9fbfc;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 6px 0px #163f494d;
    padding: 12px;
    border-radius: 8px;
    z-index: 900;
    border: none;
  
    ul {
      list-style: none;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
  
      li:last-child hr {
        display: none;
      }
  
      li:last-child a {
        margin-bottom: 0;
      }
  
      li {
        cursor: pointer;
  
        hr {
          border: none;
          border-top: 1px solid #e0ebee;
        }
  
        a {
          display: block;
          color: #a2abad;
          font-size: 14px;
          margin: 8px 0;
          font-family: "InterRegular", sans-serif;
          text-align: left;
        }
  
        button {
          display: block;
          background: none;
          border: none;
          color: #a2abad;
          font-size: 14px;
          margin: 8px 0;
          font-family: "InterRegular", sans-serif;
          text-align: left;
          cursor: pointer;
        }
  
        button:hover,
        a:hover {
          color: #707c7e;
        }
      }
    }
  }
  </style>
  