<template>
    <div class="box">
        <div class="inputs">
            <input v-model="variable" type="text" placeholder="variable">
            <select>
                <option v-for="option in options" :key="option">{{ option }}</option>
            </select>
            <svg @click="addButton" class="add" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_332_1283)"><path d="M11.9982 0.00390625C5.38207 0.00390625 0 5.38598 0 12.0021C0 18.6183 5.38207 24.0004 11.9982 24.0004C18.6144 24.0004 23.9965 18.6183 23.9965 12.0021C23.9965 5.38598 18.6114 0.00390625 11.9982 0.00390625ZM11.9982 22.7451C6.0734 22.7451 1.25531 17.927 1.25531 12.0021C1.25531 6.07731 6.0734 1.25619 11.9982 1.25619C17.9231 1.25619 22.7412 6.07731 22.7412 11.9991C22.7412 17.9209 17.9231 22.742 11.9982 22.742V22.7451Z" fill="#F9FBFC"/><path d="M19.5814 11.375H4.41875C4.07304 11.375 3.79102 11.657 3.79102 12.0027C3.79102 12.3484 4.07304 12.6305 4.41875 12.6305H19.5814C19.9271 12.6305 20.2092 12.3484 20.2092 12.0027C20.2092 11.657 19.9271 11.375 19.5814 11.375Z" fill="#F9FBFC"/><path d="M12.627 19.5853L12.627 4.42266C12.627 4.07695 12.3449 3.79492 11.9992 3.79492C11.6535 3.79492 11.3715 4.07695 11.3715 4.42266L11.3715 19.5853C11.3715 19.9311 11.6535 20.2131 11.9992 20.2131C12.3449 20.2131 12.627 19.9311 12.627 19.5853Z" fill="#F9FBFC"/></g><defs><clipPath id="clip0_332_1283"><rect width="23.9965" height="23.9965" fill="white" transform="translate(0 0.00390625)"/></clipPath></defs></svg>
            <svg @click="quitButton" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_317_799)"><path d="M13.9998 -0.00195312C6.27885 -0.00195312 -0.00195312 6.27885 -0.00195312 13.9998C-0.00195312 21.7208 6.27885 28.0016 13.9998 28.0016C21.7208 28.0016 28.0016 21.7208 28.0016 13.9998C28.0016 6.27885 21.7172 -0.00195312 13.9998 -0.00195312ZM13.9998 26.5366C7.08562 26.5366 1.46298 20.914 1.46298 13.9998C1.46298 7.08562 7.08562 1.45944 13.9998 1.45944C20.914 1.45944 26.5366 7.08562 26.5366 13.9963C26.5366 20.9069 20.914 26.5331 13.9998 26.5331V26.5366Z" fill="#163F49"/><path d="M22.8491 13.2695H5.15443C4.751 13.2695 4.42188 13.5987 4.42188 14.0021C4.42188 14.4055 4.751 14.7346 5.15443 14.7346H22.8491C23.2525 14.7346 23.5816 14.4055 23.5816 14.0021C23.5816 13.5987 23.2525 13.2695 22.8491 13.2695Z" fill="#163F49"/></g><defs><clipPath id="clip0_317_799"><rect width="28.0035" height="28.0035" fill="white" transform="translate(-0.00195312 -0.00195312)"/></clipPath></defs></svg>
        </div>
        <div class="variable">
            <p>$({{ variable || 'variable' }})</p>
            <button @click="copyText">Copiar</button>
        </div>
    </div>
</template>


<script>
export default {
    name: 'FieldWordComponent',
    props: {
        id: Number,
    },
    data() {
        return {
            variable: "",
            options: [
                "Texto",
                "Número decimal",
                "Número entero",
                "Cantidad de dinero",
                "Fecha"
            ]

        }
    },

    methods: {
        copyText() {
            navigator.clipboard.writeText(`$(${this.variable})`).then(() => {
            }).catch(err => {
                console.error('Error al copiar: ', err);
            });
        },
        addButton() {
            this.$emit('add');
        },
        quitButton() {
            this.$emit('quit', this.id);
        }
    },


}
</script>

<style scoped lang="scss">
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('@/assets/icons/angle-down.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center;
}
.box {
    background-color: #FFFFFF;
    padding: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    .inputs {
        display: flex;
        flex-direction: column;
        input, select {
            border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
            color: #0E0E0F;
            font-family: "Inter", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            height: 42px;
            border-radius: 8px;
            padding-left: 18px;
            margin-left: 44px;
        }

        select {
            margin-top: 14px;
        }

        svg {
            width: 28px;
            height: 28px;
            position: absolute;
            cursor: pointer;
            top: 22px;            
            path {
                fill: #163F49;
            }
        }

        .add {
            top: 68px;
        }
    }

    .variable {
        display: flex;
        align-items: center;
        height: 48px;
        border: 1px solid var(--Gris--1-Dedavara, #B3BBBC);
        background-color: #CBDEE2;
        margin-top: 14px;
        border-radius: 8px;
        p {
            flex: 1;
        }

        button {
            flex: 0 0 31.22%;
            height: 100%;
            border: none;
            background-color: #55737A;
            color: #F9FBFC;
            font-family: "Inter", sans-serif;
            font-size: 14px;
            cursor: pointer;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}

@media (min-width: 1200px) {
    .box {
        .inputs {
            flex-direction: row;
            margin-left: 76px;
            margin-right: 8px;
            svg {
                top: 37px;
                left: 24px;
            }

            .add {
                top: 90px;
            }

            input, select {
                margin: 0;
                width: auto;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                height: 48px;
            }

            input {
                flex: 60%;
            }

            select {
                flex: 40%;
                margin-left: 18px;
            }
        }

        .variable {
            margin: 18px 8px 0 76px;
            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
            }

            button {
                font-size: 18px;
                font-weight: 500;
                line-height: 21.78px;
            }
        }
    }
}

</style>