<template>
    <p>© {{ currentYear }} {{ this.$t('general.footer.copyright') }} <a target="_blank"
            href="https://davara.com/">Davara&Davara</a></p>

</template>

<script>


export default {
    name: 'CopyrightTextComponent',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        }
    }
}
</script>

<style scoped lang="scss">
p {

    color: #B3BBBC;
    font-size: 10px;
    font-family: 'interRegular', sans-serif;

    a {
        color: #B3BBBC;
        font-family: 'interMedium', sans-serif;
    }
}
</style>