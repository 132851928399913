<template>

    <div @mousedown="(e) => existModal(e)" class="modal">

        <form @submit.prevent="handleButton" class="box">
            <div class="title">
                <h2>{{ title }}</h2>
            </div>
            <InfoMessage :isActive="isActive" color="#C74D4D"
            :text="text" icon="error" />
            <!-- TO DO: Mensaje y borde de rojo si no hay input-->
            <label class="date" for="resolutionDate">{{ this.$t('caseFile.detailsCaseFile.headerButtons.closeCaseModal.newFinalDate') }}</label>
            <input v-model="inputDateInfo" type="date" name="resolutionDate" id="resolutionDate">
            <label for="resolutionDetails">{{ this.$t('caseFile.detailsCaseFile.headerButtons.closeCaseModal.reason') }}</label>
            <!-- TO DO: Mensaje y borde de rojo si no hay motivo escrito-->
            <textarea :class="textareaData.isBlank && 'error'" v-model.trim="textareaData.value" name="resolutionDetails" id="resolutionDetails"></textarea>
            <div class="button-container">
                    <button type="button" class="cancel-btn" @click="this.$emit('toggleModalActive')">{{ this.$t('general.buttons.cancel') }}</button>
                    <button type="submit" class="action-btn">{{ btnTitle }}</button>
            </div>

        </form>
    </div>



</template>

<script>
import InfoMessage from './InfoMessage.vue';


export default {
    name: 'ModalExtendDeadline',
    data(){
        return {textareaData: {value: '', isBlank: false}, inputDateInfo: '2023-12-29', text: '', isActive: false}
    },
    components: {InfoMessage},
    props: {
        title: {
            type: String,
            required: true
        },
        btnTitle: {
            type: String,
            required: true
        },
        placeholderText: {
            type: String
        }
    },
    watch: {
        'textareaData.value'(newValue){
            if (!this.textareaData.isBlank) return
            if(newValue !== '') {
                this.textareaData.isBlank = false
                this.isActive = false
            }
        }
    },
    methods: {
        existModal(e) {
            if (e.target.closest('.box')) return
            this.$emit('toggleModalActive')

        },
        handleButton(){
            //TO DO: no permitir que la fecha esté en blanco o sea inferior al día de hoy poner mensajes personalizados

            if(this.textareaData.value === '') {
                this.textareaData.isBlank = true
                this.isActive = true
                this.text = this.$t('general.infoMessage.needFillRequiredElements')
            }
        }
    },

}

</script>

<style scoped lang="scss">
button,
label {
    cursor: pointer;
    font-family: 'InterMedium';
    color: #707C7E;
}

.error {
    border-color: #C74D4D !important;
}

label {
    font-size: 14px;
    font-family: 'InterRegular';
   
}

.date{
    display: block;
    margin-top: 24px;
}

input {
    display: block;
    padding: 18px 24px;
    color: #0E0E0F;
    width: 100%;
    border-radius: 8px;
    border: 1.25px solid #163F49;
    margin-bottom: 20px;
    margin-top: 8px;
}


textarea {
    height: 236px;
    width: 100%;
    display: block;
    border: 1.25px solid #B3BBBC;
    border-radius: 8px;
    line-height: 20.8px;
    padding: 10px 15px;
    color: #0E0E0F;
    font-size: 16px;
    font-family: 'InterRegular';
    line-height: 20.8px;
    border-radius: 8px;
    resize: none;
    margin-top: 8px;
    margin-bottom: 32px;
}


.modal {

    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 63, 73, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: column;
    padding-block: 20px;


    .box {
        overflow: auto;
        width: 90%;
        max-width: 541px;
        background-color: #FFFFFF;
        padding: 24px;
        border-radius: 8px;
        text-align: left;
    }
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    h2 {
        color: #0E0E0F;
        font-size: 20px;
        font-family: 'InterMedium';
        margin: 0;
        line-height: 24.2px;
    }

}

.cancel-btn {
    color: #163F49;
    font-size: 16px;
    font-family: 'InterMedium';
    border: 1px solid #163F49;
    background-color: transparent;
    border-radius: 4px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 15px;

    button{
        padding: 7px 25px;
    }

    .action-btn {
        background-color: #163F49;
        color: #F9FBFC;
        border-radius: 4px;
        width: fit-content;
        border: none;
        height: auto;
    }
}


@media (max-width: 580px) {

    textarea {
        font-size: 14px;
        line-height: 18.2px;
    }
}




@media (max-width: 580px) {

    .modal {
        .box {
            width: 100%;
        }
    }
}

@media (max-width: 375px) {
    .button-container {
        flex-direction: column;

        button{
            width: 100% !important;
        }
    }

}

</style>