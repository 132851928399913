<template>

    <CreateAndEditLayaout :name="name" :path="path" :isEdit="true" :breadcrumbs="breadcrumbs" />

</template>


<script>
import CreateAndEditLayaout from '@/components/general/CreateAndEditLayaout.vue'
import { useRoute } from 'vue-router';
import { mapMutations } from 'vuex';

export default {
    name: 'EditCategory',
    components: { CreateAndEditLayaout },
    data() {
        return {
            name: {
                button: this.$t('categories.create-edit.buttonEdit'),
                title: this.$t('categories.create-edit.titleEdit')
            },
            path: '/categories',
            breadcrumbs: [{ label: this.$t('categories.create-edit.breadcrumbs.first'), to: '/categorias' }, { label: this.$t('categories.create-edit.breadcrumbs.second-edit'), to: `` }],
        }
    },
    methods: {
        ...mapMutations(['toggleUserCompanyDisable']),
    },
    mounted() {
        this.toggleUserCompanyDisable()
        const router = useRoute();
        const { id } = router.params;
        this.identifier = id
        this.breadcrumbs[1].to = `/categorias/editar-categoria/${id}`
    },
    beforeUnmount() {
        this.toggleUserCompanyDisable()
    },

}
</script>
