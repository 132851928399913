import axios from 'axios';
import i18n from '@/i18n';

const translationsModule = {
    state:()=>({
        translations: [],
        interfacesVisted: [],
        selectedLanguage:(localStorage.getItem('selectedLanguage') !== null && localStorage.getItem('selectedLanguage') !== undefined) ? localStorage.getItem('selectedLanguage') : 'es',
    }),
    mutations: {
        setTranslations(state, translations) {
            Object.keys(translations).forEach(lang => {
                let currentTranslations = i18n.global.getLocaleMessage(lang);
                currentTranslations[Object.keys(translations[lang])[0]]=translations[lang][Object.keys(translations[lang])[0]];
                i18n.global.setLocaleMessage(lang, currentTranslations);
            });
            state.translations = translations;
        },
        setInterfaceVisited(state, interfaceName) {
            state.interfacesVisted.push(interfaceName);
        },
        setSelectedLanguage(state, language) {
            state.selectedLanguage = language;
            i18n.global.locale = language;
            localStorage.setItem('selectedLanguage',language);
        }
    },
    actions: {  
        async fetchTranslations({commit},{view}) {
            const response = await axios.get(`/route/${view}/translations`,{
                withCredentials: false,
            });
            commit('setTranslations', response.data);
        },
        async setInterfaceVisited({commit}, interfaceName) {
            commit('setInterfaceVisited', interfaceName);
        },
        async setSelectedLanguage({commit}, language) {
            commit('setSelectedLanguage', language);
        }
    },
    getters: {
        getTranslations(state){return state.translations},
        isThisInterfaceVisited: (state) => (interfaceName) => {
            return state.interfacesVisted.includes(interfaceName);
        },
        getSelectedLanguage(state) {
            return state.selectedLanguage;
        }
    }

}
export default translationsModule;