<template>
    <div class="template">
        <div class="title">
            <img src="@/assets/icons/vector.svg" alt="vector" @click="goBack">
            <h1>{{ this.$t('notification.title') }}</h1>
        </div>

        <div v-show="isError" ref="errorMessage" :style="isError && { marginBottom: '20px' }"></div>
        <InfoMessage v-if="isError" :text="this.$t('general.infoMessage.generalError')" :isActive="true" icon="error"
            color="#C74D4D" :styleDiv="{ marginBottom: '0' }" />


        <div class="notificaciones-box">
            <div class="markNotification">
                <button @click="changePlaces" v-if="isSelectedNotifications">{{ tabInRead ? this.$t('notification.readButton') :
                    this.$t('notification.notReadButton') }} 
                    <svg :aria-label="this.$t('notification.araiLabelEye')" class="svg-eye" width="27" height="16" viewBox="0 0 27 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g id="Group">
                            <path id="Vector" fill-rule="evenodd" clip-rule="evenodd"
                                d="M13.0703 1.49452e-08C7.67427 0.000408697 2.69819 2.97639 0.0561837 7.77654C0.0193454 7.84349 1.52602e-05 7.91871 9.03164e-09 7.99519C-1.52421e-05 8.07167 0.0192849 8.1469 0.0560964 8.21386C2.69564 13.0177 7.67209 15.9976 13.0701 16C13.07 16 13.0702 16 13.0701 16C18.4663 15.9978 23.4419 13.0198 26.0822 8.21859C26.119 8.15165 26.1383 8.07645 26.1383 8C26.1383 7.92355 26.119 7.84835 26.0822 7.78141C23.4419 2.98018 18.4665 0.00227427 13.0703 1.49452e-08C13.0704 -4.98172e-09 13.0702 -4.98172e-09 13.0703 1.49452e-08ZM13.0701 0.90459C18.0527 0.906642 22.6488 3.61194 25.1635 7.99999C22.6489 12.3879 18.053 15.0932 13.0705 15.0954C8.08617 15.0932 3.48889 12.3861 0.97482 7.99558C3.49102 3.60859 8.08762 0.905057 13.0701 0.90459ZM13.0692 3.46784C10.6163 3.46784 8.62603 5.50659 8.62603 7.99999C8.62603 10.4934 10.6163 12.5322 13.0692 12.5322C15.5221 12.5322 17.5123 10.4934 17.5123 7.99999C17.5123 5.50659 15.5221 3.46784 13.0692 3.46784ZM13.0692 4.37244C15.0264 4.37244 16.6113 5.98688 16.6113 7.99999C16.6113 10.0131 15.0264 11.6276 13.0692 11.6276C11.112 11.6276 9.52709 10.0131 9.52709 7.99999C9.52709 5.98688 11.112 4.37244 13.0692 4.37244Z"
                                fill="#163F49" />
                        </g>
                    </svg></button>
            </div>
            <TableTap @actionsButton="changeFilter" :titleFirst="this.$t('notification.readTable')" :titleSecond="this.$t('notification.notReadTable')" />
           
            <TableNotification v-if="tabInRead" ref="table1" :isRead="true"
                @setSelectedNotifications="setSelectedNotifications" @scrollToErrorMessage="scrollToErrorMessage"
                @showError="showError" />
            <TableNotification v-if="!tabInRead" ref="table2" :isRead="false"
                @setSelectedNotifications="setSelectedNotifications" @scrollToErrorMessage="scrollToErrorMessage"
                @showError="showError" />
        </div>
    </div>
</template>

<script>

import InfoMessage from '../general/InfoMessage.vue';
import TableNotification from '../notificaciones/TableNotification.vue';
import TableTap from '../general/TableTap.vue';

export default {
    name: 'NotificationsComponent',
    components: {
        InfoMessage,
        TableNotification,
        TableTap
    },
    props: {},
    data() {
        return {
            tabInRead: false,
            isError: false,
            isSelectedNotifications: false
        };
    },
    methods: {
        changePlaces() {
            if (this.tabInRead) {
                this.$refs.table1.changeReadNotRead()
            } else {
                this.$refs.table2.changeReadNotRead()
            }
        },
        scrollToErrorMessage() {
            this.isError = true
            const element = this.$refs.errorMessage;
            this.$nextTick(() => {
                // Aseguramos que el DOM se haya actualizado
                element.scrollIntoView({ behavior: "smooth" });
            });
            setTimeout(() => {
                this.isError = false
            }, 2000)
        },
        showError() {
            this.isError = true
        },
        goBack() {
            window.history.back();
        },
        setSelectedNotifications(value) {
            this.isSelectedNotifications = value
        },
        changeFilter(isRead) {
            this.tabInRead = !isRead;
        },


    },

};
</script>

<style scoped lang="scss">
* {
    font-family: "InterRegular";
}

.template {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 425px) {
        width: calc(100% - 30px);
    }

    @media (max-width: 361px) {
        width: calc(100% - 20px);
    }

    .title {
        display: flex;
        margin: 0;

        img {
            width: 8px;
            height: 15px;
            margin-top: 16px;
            margin-right: 26px;
            cursor: pointer;
        }

        h1 {
            width: 233px;
            height: 19px;
            font-size: 20px;
            font-weight: 600;
            line-height: 24.2px;
            text-align: left;
            color: #163F49;
        }
    }

    .notificaciones-box {

        .markNotification {
            margin-top: 10px;
            margin-bottom: 15px;
            display: flex;
            justify-content: end;
            


            button {
                display: flex;
                align-items: center;
                gap: 5px;
                width: fit-content;
                height: 32px;
                border-radius: 4px;
                border: 1px solid var(--Verde-Ultra-Oscuro-Dedavara, #0D2930);
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                text-align: center;
                color: #163F49;
                background: none;
                cursor: pointer;
                transition: all .2s ease;

                &:hover {
                    background: #163f49;
                    color: white;
                    border: transparent;

                    .svg-eye g path{
                        fill: #fff;

                    }
                }

            }
        }

    }


}

@media (min-width: 769px) {
    .template {
        .title {


            img {
                width: 10px;
                height: 20px;
                margin-top: 30px;
            }

            h1 {
                width: 263px;
                height: 33px;
                font-size: 30px;
                font-weight: 600;
                line-height: 36.31px;
                text-align: left;
                color: #163F49;
            }
        }

        .notificaciones-box {
            .markNotification {
                margin-top: 0;
            }


            .notificaciones {
                border-top: 1px solid var(--Gris--1-Dedavara, #B3BBBC);
            }
        }
    }
}

@media (min-width: 1200px) {
    .template {


        .title {


            img {
                display: none;
            }

            h1 {
                width: 263px;
                height: 33px;
                font-size: 30px;
                font-weight: 600;
                line-height: 36.31px;
                text-align: left;
                color: #163F49;
            }
        }

        .notificaciones-box {
            margin-left: 0;
            margin-top: 52px;

            

        }
    }
}
</style>