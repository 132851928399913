

<template>
<NewUser />

</template>


<script>

import NewUser from '../panel/NewUser.vue';
export default {
    name: 'EditProfile',
    data(){
        return {userInfo: {}}
    },
    components: {
        NewUser
    },
}


</script>

