<template>

    <LayaoutTableDinamic :tableInfoMessage="tableInfoMessage" :deleteModalText="deleteModalText"
        :buttonTitle="buttonTitle" :title="this.$t('users.showUsers.title')" :breadcrumbs="this.breadcrumbsList" path="/users"
      :changeTypeName="true"  :tableColumnsData="tableColumnsData" :pendingRegistration="true" 
      :isVisualice="true" :secondaryButtonData="secondaryButtonData" :secondaryButton="true" editPath="/users/edit-user" 
 :joinNameSurname="true" />

</template>


<script>
import LayaoutTableDinamic from '../users/LayaoutTableDinamic.vue';



export default {
    name: 'UsersComponent',
    components: {
        LayaoutTableDinamic
    },

    data() {
        return {
            breadcrumbsList: [
                { label: this.$t('users.showUsers.breadcrumbs'), to: '/users' },
            ],
            secondaryButtonData: {
                link: '/users/manage-instructors',
                title:  this.$t('users.showUsers.buttons.manageInstructors')
            },
            buttonTitle: {
                create: this.$t('users.showUsers.buttons.newUser'),
                linkToCreate: '/users/create-user/usuario',
                delete: this.$t('general.buttons.delete')
            },
            deleteModalText: {
                title: this.$t('users.showUsers.deleteModal.title'),
                namePluralBeforeNumber: this.$t('general.deleteModal.beforeTheNumber.users'),
                namePlural:  this.$t('general.deleteModal.afterTheNumber.users'),
                textSingular: this.$t('users.showUsers.deleteModal.textSingular'),
                errorSingular: this.$t('users.showUsers.deleteModal.errorSingular'),
                errorPlural: this.$t('users.showUsers.deleteModal.errorPlural')
            },
            tableInfoMessage: {
                notCreate: this.$t('users.showUsers.noUsers'),
                notSearch: this.$t('general.search.noUser'),
                notDeleted: this.$t('users.showUsers.noDeletedUsers'),
            },
            tableColumnsData: {
                desk: {
                    columnTitle: [{elementCode: 'name', title: this.$t('general.tableColumTitles.name.title'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.name.title'), isUp: false }, 
                    {elementCode: 'email',  title: this.$t('general.tableColumTitles.email'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.email'), isUp: false }, 
                    {elementCode: 'userType',  title: this.$t('general.tableColumTitles.rol'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.rol'), isUp: false }],
                    //Tiene que hacer match con los datos
                    mainRowName: ['name', 'email', 'userType'],
                    titleDetailsRowName: [],
                    //Tiene que hacer match con los datos
                    detailsRowName: [],
                },
                mobile: {
                    columnTitle: [{ title: this.$t('general.tableColumTitles.name.title'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.name.title') },],
                    //Tiene que hacer match con los datos
                    mainRowName: ['name'],
                    titleDetailsRowName: [this.$t('general.tableColumTitles.email'), this.$t('general.tableColumTitles.rol')],
                    //Tiene que hacer match con los datos
                    detailsRowName: ['email', 'userType'],
                }
            }
        }
    },
}
</script>
