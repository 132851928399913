
<template>
    <slot v-if="!isActive"></slot>
    <VueSpinner v-else size="24" color="#FFFFFF"></VueSpinner>
</template>

<script>
import { VueSpinner } from 'vue3-spinners';

export default {
    name: 'VueSpinnerToBtn',
    components: {VueSpinner},
    data(){
        return{isActive: false}
    },
    methods: {
        activeSpinner(){
            this.isActive = true
        },
        desactiveSpinner(){
            this.isActive = false
        }
    },
}


</script>



