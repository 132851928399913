<template>
  <div class="template">
    <div class="title">
      <generalTitle title="Nuevo Modelo" :breadcrumbs="breadcrumbs" 
                :root="false" @goBack="goBack()" />
    </div>

    <div class="text-boxes">
      <div class="text">
        <h2>Cree los campos de su modelo</h2>
        <p>
          Pulse el botón de + para crear un nuevo campo. Una vez definido el nombre y tipo
          del campo se genera una variable precedida por “$”. Cópielas en los lugares de
          su documento de Word en los que quiera dicha variable. Una vez copiadas las
          variables en su documento de Word guárdelo. A continuación defina el nombre del
          modelo y la descripción y suba el archivo de Word. Finalmente guarde el modelo
          para su posterior uso.
        </p>
      </div>
      <div class="boxes">
        <VueDraggable
          v-model="fields"
          class="flex flex-col gap-2 p-4 w-300px bg-gray-500/5 rounded"
          target=".sort-target"
          :scroll="true"
        >
          <transition-group type="transition" tag="div" name="fade" class="sort-target">
            <FieldWord
              v-for="field in fields"
              :key="field.id"
              class="box"
              :field="field"
              @add="addField"
              @quit="quitField(field.id)"
            />
          </transition-group>
        </VueDraggable>
      </div>
    </div>

    <div class="data-doc">
      <label class="name">
        <p>Nombre del Modelo</p>
        <input type="text" placeholder="Nuevo Modelo" />
      </label>
      <label class="description">
        <p>Descripción</p>
        <textarea></textarea>
      </label>
      <div class="buttons">
        <label class="up-doc">
          <img src="@/assets/icons/clip.svg" />
          Suba su archivo .doc
          <input type="file" accept="application/msword" @change="handleFileUpload" />
        </label>
        <div class="doc" v-if="isDocUploaded">
          <svg
            width="29"
            height="36"
            viewBox="0 0 29 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2812_250)">
              <path
                d="M16.6324 0H4.73856C2.12288 0 0 2.07301 0 4.62725V31.3728C0 33.927 2.12288 36 4.73856 36H24.2614C26.8771 36 29 33.927 29 31.3728V12.0308C29 11.9105 28.9479 11.7902 28.8578 11.7069L16.9641 0.138817C16.8788 0.0508997 16.7556 0 16.6324 0ZM28.0523 31.3728C28.0523 33.418 26.3559 35.0746 24.2614 35.0746H4.73856C2.64412 35.0746 0.947712 33.418 0.947712 31.3728V4.62725C0.947712 2.58201 2.64412 0.92545 4.73856 0.92545H16.2533V7.35733C16.2533 10.1707 18.5846 12.4473 21.4657 12.4473H28.0523V31.3728ZM17.201 1.66581L27.2941 11.5219H21.4657C19.1106 11.5219 17.201 9.65707 17.201 7.35733V1.66581Z"
                fill="#55737A"
              />
            </g>
            <defs>
              <clipPath id="clip0_2812_250">
                <rect width="29" height="36" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p>{{ docName }}</p>
          <svg
            class="quit"
            @click="handleFileRemoval"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_317_799)">
              <path
                d="M13.9998 -0.00195312C6.27885 -0.00195312 -0.00195312 6.27885 -0.00195312 13.9998C-0.00195312 21.7208 6.27885 28.0016 13.9998 28.0016C21.7208 28.0016 28.0016 21.7208 28.0016 13.9998C28.0016 6.27885 21.7172 -0.00195312 13.9998 -0.00195312ZM13.9998 26.5366C7.08562 26.5366 1.46298 20.914 1.46298 13.9998C1.46298 7.08562 7.08562 1.45944 13.9998 1.45944C20.914 1.45944 26.5366 7.08562 26.5366 13.9963C26.5366 20.9069 20.914 26.5331 13.9998 26.5331V26.5366Z"
                fill="#163F49"
              />
              <path
                d="M22.8491 13.2695H5.15443C4.751 13.2695 4.42188 13.5987 4.42188 14.0021C4.42188 14.4055 4.751 14.7346 5.15443 14.7346H22.8491C23.2525 14.7346 23.5816 14.4055 23.5816 14.0021C23.5816 13.5987 23.2525 13.2695 22.8491 13.2695Z"
                fill="#163F49"
              />
            </g>
            <defs>
              <clipPath id="clip0_317_799">
                <rect
                  width="28.0035"
                  height="28.0035"
                  fill="white"
                  transform="translate(-0.00195312 -0.00195312)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <button class="save-model">Guardar Modelo</button>
      </div>
    </div>
  </div>
</template>

<script>
import FieldWord from "@/components/doc-models/FieldWord.vue";
import { VueDraggable } from "vue-draggable-plus";
import generalTitle from "../general/generalTitle.vue";

export default {
  name: "NewDocModelComponent",
  components: {
    FieldWord,
    VueDraggable,
    generalTitle
  },
  data() {
    return {
      isDocUploaded: false,
      docName: "",
      breadcrumbs: [{ label: 'Modelos documentales', to: '/doc-models' }, { label: 'Nuevo Modelo', to: '/doc-models/new-docmodel' }], 
      fields: [
        { id: 1, value: "" },
        { id: 2, value: "" },
        { id: 3, value: "" },
      ],
    };
  },
  methods: {
    goBack() {
      window.history.back();
    },
    addField() {
      const newId = this.fields.length ? this.fields[this.fields.length - 1].id + 1 : 1;
      this.fields.push({ id: newId, value: "" });
    },
    quitField(id) {
      this.fields = this.fields.filter((field) => field.id !== id);
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.docName = file.name;
        this.isDocUploaded = true;
      }
    },
    handleFileRemoval() {
      this.isDocUploaded = false;
      this.docName = "";
    },
  },
};
</script>

<style scoped lang="scss">
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

.fade-leave-active {
  position: absolute;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("@/assets/icons/angle-down.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) center;
}

.template {

  @media (max-width: 300px) {
    width: calc(100% - 10px);
  }
  .title {
    display: flex;

    h3 {
      display: none;
      font-family: "Inter", sans-serif;
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;
      text-align: left;
      color: #163f49;
      margin: 0;
      font-family: "Inter", sans-serif;
    }

    img {
      width: 8px;
      height: 15px;
      margin-left: 26px;
      margin-right: 26px;
      margin-top: 6px;
      cursor: pointer;
    }
  }

  .text-boxes {
    margin: 0;
    margin-top: 32px;

    .text {
      h2 {
        margin: 0;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.36px;
        text-align: left;
        color: #163f49;
      }

      p {
        margin-top: 12px;
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        text-align: left;
        color: #163f49;
      }
    }

    .boxes {
      margin-top: 24px;

      .box {
        margin-top: 14px;
        border-radius: 8px;
      }
    }
  }

  .data-doc {
    margin-top: 24px;
    background-color: #ffffff;

    label {
      display: flex;
      flex-direction: column;
      margin: 24px 24px 0 24px;

      p {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        text-align: left;
        color: #707c7e;
      }

      input,
      textarea {
        border: 1.25px solid var(--Gris--1-Dedavara, #b3bbbc);
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: left;
        color: #0e0e0f;
        padding-left: 18px;
        margin-top: 8px;
        border-radius: 8px;
      }

      input {
        height: 42px;
      }

      textarea {
        height: 128px;
        resize: none;
        padding-top: 10px;
        padding-right: 18px;
        padding-bottom: 18px;
        scroll-padding: 18px;
      }
      

      

      input::placeholder {
        color: #b3bbbc;
      }
    }

    .name {
      margin-top: 0;

      p {
        margin-top: 33px;
      }
    }

    .up-doc {
      border: none;
      background-color: #cbdee2;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 14.5px 0;
      border-radius: 6px;
      color: #163f49;
      cursor: pointer;

      img {
        width: 18px;
        height: 20px;
        margin-right: 12px;
      }

      input {
        display: none;
      }
    }

    .doc {
      border: none;
      background-color: #cbdee2;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 14.5px 0;
      border-radius: 6px;
      color: #163f49;
      width: calc(100% - 48px);
      margin-left: 24px;
      margin-top: 18px;

      P {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        width: 18px;
        height: 20px;
        margin-right: 12px;
        margin-left: 12px;

        path {
          fill: #163f49;
        }
      }

      .quit {
        margin-right: 12px;
        margin-left: auto;
        cursor: pointer;
      }
    }

    .save-model {
      margin: 18px 0 33px 0;
      height: 48px;
      color: #f9fbfc;
      background-color: #163f49;
      width: calc(100% - 48px);
      border-radius: 6px;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      cursor: pointer;
      border: none;
    }
  }
}

@media (min-width: 1200px) {
  .template {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    align-items: start;

    .title {
      flex-direction: column;
      margin: 0;
      grid-column: span 2;

      h3 {
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: left;
        color: #a2abad;

        .go-to-companies {
          color: #a2abad;
        }

        span {
          font-size: 12px;
          font-weight: 500;
          line-height: 14.52px;
          text-align: left;
          color: #365961;
        }
      }

      img {
        display: none;
      }

      h1 {
        margin: 25px 0 0 0;
        font-size: 30px;
        font-weight: 600;
        line-height: 36.31px;
        text-align: left;
        color: #163f49;
      }
    }

    .text-boxes {
      margin: 48px 0 0 0;

      .text {
        h2 {
          font-size: 20px;
          font-weight: 600;
          line-height: 24.2px;
        }

        p {
          margin-top: 22px;
          font-size: 12px;
          font-weight: 400;
          line-height: 14.52px;
        }
      }

      .boxes {
        // max-height: 600px;
        overflow-y: auto;
      }
    }

    .data-doc {
      margin: 50px 0 0 24px;
      border-radius: 8px;
      height: auto;

      label {
        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 16.94px;
        }

        input,
        textarea {
          margin-right: 14px;
        }
      }

      .name {
        margin-top: 0;

        p {
          margin-top: 32px;
        }

        input {
          height: 48px;
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: calc(100% - 58px);
        margin: 32px 0 28px 24px;

        .up-doc {
          width: 100%;
          margin: 0;
          margin-right: 24px;
        }

        .save-model {
          margin: 18px 0 0 0;

          width: 100%;
        }

        .doc {
          margin: 18px 0 0 0;
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 1500px) {
  .template {
    .data-doc {
      .buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        gap: 24px;

        .up-doc {
          max-width: 217px;
        }

        .save-model {
          max-width: 215px;
          margin: 0;
          grid-column-start: 2;
          grid-row-start: 1;
          justify-self: end;
        }

        .doc {
          margin-top: 0;
          grid-column: span 2;
        }
      }
    }
  }
}


</style>
