<template>

    <div class="input-div" v-if="type === 'Input'">
        <label :for="identy">{{ label }} <span v-if="showOption && !isRequired" class="optional">{{ this.$t('general.optional') }}</span></label>
        <input :disabled="isDisabled" :class="{'disableElement': isDisabled}" :style="isError && { borderColor: '#C74D4D' }" :name="identy" :id="identy" type="text" v-model="value">
        <p class="error-message"  v-if="isError">{{ this.$t('general.infoMessage.fillElement') }}</p>
    </div>

    <div :style="textareDivStyle" class="textarea-div" v-if="type === 'Textarea'">
        <label :for="identy">{{ label }} <span v-if="showOption && !isRequired" class="optional">{{ this.$t('general.optional') }}</span></label>
        <textarea :disabled="isDisabled"  :style="textareStyle" :class="[{'error': isError},{'disableElement': isDisabled} ]" :name="identy" :id="identy" type="text"
            v-model="value"></textarea>
        <p class="error-message" v-if="isError">{{  this.$t('general.infoMessage.fillElement') }}</p>
    </div>

    <div class="selector-div" v-if="type === 'Selector'">
        <p class="label">{{ label }} <span v-if="showOption && !isRequired" class="optional">{{ this.$t('general.optional') }}</span></p>
        <SelectSearchBox v-model:selectedValue="this.selectedValue"
        :isDisabled="isDisabled"
            :optionArray="this.options.map(element => ({ name: element }))" :labelIndentify="label" :fileDataName="label"
            :error="isError" @cleanFileDataError="this.isError = false" :placeholderText="this.$t('general.inputElements.generalSelectPlaceholder')" />
    </div>

    <div v-if="type === 'Verficación'">
        <div class="checkbox-div">
            <Checkbox :isDisabled="isDisabled" :checkStyle="isError ? { borderColor: '#C74D4D' } : {}" v-model="check" :labelIdenty="identy" />
            <span :style="{ textAlign: 'left' }"><label class="html-element" :for="identy" v-html="label"></label> <span class="optional"
                    v-if="showOption && !isRequired">{{ this.$t('general.optional') }}</span></span>
        </div>
        <p class="error-message" v-if="isError">{{ this.$t('general.infoMessage.checkboxGeneralError') }}</p>
    </div>

</template>


<script>
import SelectSearchBox from '../forms/SelectSearchBox.vue';
import Checkbox from '../formulario_denuncia/Checkbox.vue';



export default {
    name: 'DinamicInput',
    props: {
        type: String,
        label: String,
        dataValue: {
            type: [String, Boolean]
        },
        isRequired: Boolean,
        options: Array,
        identy: String,
        textareDivStyle: Object,
        textareStyle: Object,
        showOption: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        isActiveEffect: {
            type: Boolean,
            default: false
        }
    },
    emits: ['activeEffect'],
    components: { SelectSearchBox, Checkbox },
    data() {
        return {
            value: this.dataValue ? this.dataValue : '',
            isError: false,
            selectedValue: this.dataValue ? { name: this.dataValue } : '',
            check: this.dataValue ? this.dataValue : false
        }
    },
    watch: {
        value(newValue) {
            if(this.isActiveEffect) {
                this.$emit('activeEffect')
            }
            if (!this.isError) return
            if (newValue !== '') {
                this.isError = false
            }
        },
        check(newValue) {
            if(this.isActiveEffect) {
                this.$emit('activeEffect')
            }
            if (this.isError) {
                if (newValue) {
                    this.isError = false
                }
            }
        }

    },
    methods: {
        checkError() {
            //para los input obligatorios
            if (this.isRequired) {
                if (this.type === 'Selector') {
                    if (this.selectedValue === '') {
                        this.isError = true
                        return 'errorValue'
                    }
                    return this.selectedValue
                }
                else if (this.type === 'Verficación') {
                    if (this.check === false) {
                        this.isError = true
                        return 'errorValue'
                    }
                    return this.check
                }
                else {
                    if (this.value === '') {
                        this.isError = true
                        return 'errorValue'
                    }
                    return this.value
                }


            }
            //para los opcionales
            else {
                if (this.type === 'Selector') {
                    return this.selectedValue
                }
                else if (this.type === 'Verficación') {
                    return this.check
                } else {
                    return this.value
                }
            }
        }
    },


}

</script>

<style>
.html-element{
    color: #A2ABAD !important;
    font-family: 'InterRegular', sans-serif !important;
    font-size: 14px !important;

    a {
        color: #55737A!important;
        font-family: 'InterMedium', sans-serif !important;
        font-size: 14px !important;
        text-decoration: none !important;
    }
}
</style>

<style scoped lang="scss">

.error-message{
    margin-top: 6px;
}

.checkbox-div {
    display: flex;
    gap: 10px;
    align-items: flex-start;

    input {
        padding: 0;
        height: 20px;
        width: 20px;

    }

    label {
        margin-bottom: 0;
        text-align: left;
    }

}



.optional {
    text-align: left;
    color: #A2ABAD;
    font-family: 'InterRegular', sans-serif;
    font-size: 14px;

}

.disableElement{
    cursor: not-allowed !important;
    background: transparent;
}

label,
.label {
    text-align: left;
    margin-bottom: 8px;
    font-size: 14px;
    color: #707C7E;
    font-family: "InterMedium", sans-serif;
    margin-top: 0;
}

input {
    min-height: 50.04px;
}

input,
textarea {
    border: 1.25px solid #B3BBBC;
    border-radius: 8px;
    font-family: 'InterRegular', sans-serif;
    box-sizing: border-box;
    color: #0E0E0F;
    cursor: pointer;
    padding-inline: 14px;

    &::placeholder {
        color: #B3BBBC;
        font-family: 'InterRegular', sans-serif;
        font-size: 14px;

    }
}

p {
    text-align: left;
    color: #C74D4D;
    margin-top: 12px;
    font-size: 14px;
    font-family: "InterRegular";
}

.error {
    border-color: #C74D4D !important;
}

.input-div,
.textarea-div,
.selector-div {
    display: flex;
    flex-direction: column;
}

textarea {
    resize: none;
    min-height: 80.04px;
    padding-top: 14px;
}
</style>