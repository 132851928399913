<template>
    
    <TableMenuLayaout :tableOptions="tableOptions" :descriptionName="descriptionName" :dbUrl="dbUrl"
        :breadcrumbs="breadcrumbs" :isButtonsActive="isButtonsActive"   :tableInfoMessage="tableInfoMessage" :deleteModalText="deleteModalText" :deleteStyle="{marginRight: 0}"  />
        
</template>

<script>
import TableMenuLayaout from "@/components/general/TableMenuLayaout.vue"

export default {
    name: 'CategoriesComponent',
    components: { TableMenuLayaout},
    data() {
        return {
            isButtonsActive: true,
            tableOptions: {
                chekbox: true,
                arrow: true,
                columnTitle: [{elementCode: 'name', title: this.$t('general.tableColumTitles.category'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.category'),  isUp: false  }],
                options: true,
                //Tiene que hacer match con los datos
                mainRowName: ['name'],
                titleDetailsRowName: [this.$t('general.tableColumTitles.description.title')],
                //Tiene que hacer match con los datos
                detailsRowName: ['description']
            },
            descriptionName: {
                title: this.$t('categories.showCategories.title'),
                buttonAddName: this.$t('categories.showCategories.button')
            },
            dbUrl: {
                path: "/categories",
                pathToCreate: "categorias/crear-categoria",
                pathToEdit: "categorias/editar-categoria"
            },
            breadcrumbs: [{ label: this.$t('categories.showCategories.breadcrumbs'), to: '/categorias' }],
            tableInfoMessage: {
                notCreate: this.$t('categories.showCategories.noCategories'),
                notSearch: this.$t('general.search.noCategory'),
                notDeleted: this.$t('categories.showCategories.noDeletedCategories'),
            },
            deleteModalText: {
                title:  this.$t('categories.showCategories.deleteModal.title'),
                namePluralBeforeNumber: this.$t('general.deleteModal.beforeTheNumber.categories'),
                namePlural: this.$t('general.deleteModal.afterTheNumber.categories'),
                textSingular: this.$t('categories.showCategories.deleteModal.textSingular'),
                errorSingular: this.$t('categories.showCategories.deleteModal.errorSingular'),
                errorPlural: this.$t('categories.showCategories.deleteModal.errorPlural')
            }
        }
    },
    mounted() {
     if(  this.$store.getters.getRoles.includes('ROLE_INSTRUCTOR'))  {
        this.tableOptions = {...this.tableOptions, chekbox: false, options: false}
            this.isButtonsActive = false
        }
    },
    
}

</script>