import axios from 'axios';

const user = {
    state: {
        user: (localStorage.getItem('user') !== null && localStorage.getItem('user') !== undefined) ? JSON.parse(localStorage.getItem('user')) : [],
        userCompany: (localStorage.getItem('company') !== null && localStorage.getItem('company') !== undefined) ? localStorage.getItem('company') : '',
        userCompanyDisable: false,
        isMenuDeskActive: true,
        companyChangeToggle: false,
        anonymusCaseFileId: (localStorage.getItem('setAnonymusCaseFileId') !== null && localStorage.getItem('setAnonymusCaseFileId') !== undefined) ? JSON.parse(localStorage.getItem('setAnonymusCaseFileId')) : '',
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
        setUserName(state, payload) {
            state.user.name = payload
        },
        setUserCompany(state, payload) {
            state.userCompany = payload;
        },
        toggleUserCompanyDisable(state) {
            state.userCompanyDisable = !state.userCompanyDisable
        },
        setUserCompanyDisable(state, payload) {
            state.userCompanyDisable = payload
        },
        toggleIsMenuDeskActive(state) {
            state.isMenuDeskActive = !state.isMenuDeskActive
        },
        setMfaConfigured(state) {
            state.user.mfaConfigured = true
        },
        setEnableDisable(state, payload) {
            state.user.mfaEnabled = payload
        },
        setCompanyChangeToggle(state) {
            state.companyChangeToggle = !state.companyChangeToggle
        },
        setAnonymusCaseFileId(state, payload) {
            state.anonymusCaseFileId = payload
        }

    },
    actions: {
        getUser({ commit, getters }) {
            if (getters.getRoles.includes('ROLE_ANONYMOUS')) {
                return
            }
            axios.get('/user/config')
                .then((response) => {
                    commit('setUser', response.data);
                    if (response.data.companies !== undefined) {
                        commit('setUserCompany', response.data.companies[0]);
                    }
                    localStorage.setItem('user', JSON.stringify(response.data));
                })
                .catch(() => {
                    commit('setUser', []);
                    localStorage.removeItem('user');
                })
        },
        setUserCompanyAction({ commit }, payload) {
            commit('setUserCompany', payload);
            localStorage.setItem('company', payload);
        }
    },
    getters: {
        getUser: state => {
            return state.user;
        },
        getUserCompany: state => {
            return state.userCompany;
        },
        getUserCompanyDisable: state => {
            return state.userCompanyDisable
        },
        getIsMenuDeskActive: state => {
            return state.isMenuDeskActive
        },
        getCompanyChangeToggle: state => {
            return state.companyChangeToggle
        },
        getAnonymusCaseFileId: state => {
            return state.anonymusCaseFileId
        }
    }
};

export default user;